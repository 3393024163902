/**
 * @fileoverview Defines a Organization view model used to
 * holds the additional company information required by the Delegated Admin.
 */

/*global $ */
/*global define */
define(
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  'pageLayout/organization',

  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  [
    'knockout', 'ccConstants', 'ccRestClient', 'viewModels/dynamicPropertyMetaContainer',  'pageLayout/parent-organisation'
  ],

  //-------------------------------------------------------------------
  // MODULE DEFINITION
  //-------------------------------------------------------------------
  function (ko, CCConstants, ccRestClient, DynamicPropertyMetaContainer, ParentOrganisation) {

    "use strict";

    /**
     * Creates a organization view model.
     * <p>
     * The Organization View Model holds the additional company information
     * required by the Delegated Admin
     *
     * @public
     * @class Represents an Organization.
     * @name Organization
     * @property {observable<string>} id organization Id
     * @property {observable<string>} isActive organization active
     * @property {observable<string>} relativeRoles roles available in the organization
     * @property {observable<string>} approvalRequired approvalRequired flag of the organization
     * @property {observable<string>} orderPriceLimit approval price limit of the organization
     * @property {observable<boolean>} delegateApprovalManagement flag whether delegated admin has provision to change order approval settings of an organization
     * @property {observable<boolean>} isApprovalWebhookEnabled flag check if use external approval webhook property is enabled or not.
     */
    //TODO: should we add another parameter organizationId to this constructor so that this viewModel 
    //can also call the getCurrentOrganization endpoint?
    //This would be needed when we have multiple instances of this viewModel in future scope.
    function Organization(organizationData, pAdapter) {
      var self = this;
      var ignoreProperties = self.listOfIgnoreProperties();
      ko.mapping.fromJS(organizationData, ignoreProperties, self);
      self.blockSize = 20;
      self.sortProperty = "email:asc";
      self.adapter = pAdapter;
      self.id = ko.observable('');
      self.active = ko.observable(false); //TODO: needs to be updated if changed from admin
      self.relativeRoles = ko.observable([]);
      self.approvalRequired = ko.observable(false); //TODO: needs to be updated when changed
      self.orderPriceLimit = ko.observable('');
      self.delegateApprovalManagement = ko.observable(false);
      self.numberOfActiveApprovers = ko.observable();
      self.isApprovalWebhookEnabled = ko.observable(false);
      self.parentOrganization = new ParentOrganisation();
      self.dynamicProperties = ko.observableArray();
      
      //This property is cache to hold dynamic property meta information all the item-descriptor.
      self.dynamicPropertyMetaInfo = DynamicPropertyMetaContainer.getInstance();
      
      if(organizationData) {
        self.id(organizationData.id);
        self.active(organizationData.active);
        self.relativeRoles(organizationData.relativeRoles);
        self.approvalRequired(organizationData.approvalRequired);
        self.orderPriceLimit(organizationData.orderPriceLimit);
        self.delegateApprovalManagement(organizationData.delegateApprovalManagement);
        self.isApprovalWebhookEnabled(organizationData.useExternalApprovalWebhook);
        ko.mapping.fromJS(organizationData.parentOrganization, {}, self.parentOrganization);
        if (ccRestClient.profileType == CCConstants.PROFILE_TYPE_AGENT) {
          // All users can get the approver count with the below method which is needed for agent application.
          self.getApproverList();
        } else {
          // Only admin user gets the approver count with the below method.
          self.updatenumberOfActiveApprovers();
        }
      }
      return self;
    };
    
  /**
   * Calls updateOrganization endpoint
   * for updating the require Approval flag and order purchase limit
   */
  Organization.prototype.updatenumberOfActiveApprovers = function() {
    var self = this;
    var approverRepositoryId;
    var url, inputData;
    inputData = {};
    inputData[CCConstants.OFFSET] = 0;
    inputData[CCConstants.LIMIT] = self.blockSize ;
    inputData[CCConstants.SORTS] = self.sortProperty;
    var rolesLength=self.relativeRoles().length
    for(var i = 0; i < rolesLength; i++) {
      if(self.relativeRoles()[i]["function"] === "approver"){
        approverRepositoryId = self.relativeRoles()[i]["repositoryId"];
      }
    }
    inputData["q"] = "roles.id eq \"" +approverRepositoryId +"\" AND active eq \"1\"";
    self.adapter.loadJSON(CCConstants.ENDPOINT_LIST_CONTACTS_BY_ORGANIZATION,null,inputData,
    // success callback
    function(data) {
     self.numberOfActiveApprovers(data.totalResults);
    },
    //TODO: Complete error callback function
    // error callback
    function(data) {});
  };
  
  /**
   * Populate organization view model
   * @private
   * @function
   * @name OrganizationViewModel#populateOrganizationViewModel
   * @param {Object[]} data List organization properties
   */
  Organization.prototype.populateOrganizationViewModel = function(organizationData) {
    var self = this;
    var ignoreProperties = self.listOfIgnoreProperties();
    ko.mapping.fromJS(organizationData, ignoreProperties, self);
    if(organizationData) {
      self.id(organizationData.id);
      self.active(organizationData.active);
      self.relativeRoles(organizationData.relativeRoles);
      self.approvalRequired(organizationData.approvalRequired);
      self.orderPriceLimit(organizationData.orderPriceLimit);
      self.delegateApprovalManagement(organizationData.delegateApprovalManagement);
      self.isApprovalWebhookEnabled(organizationData.useExternalApprovalWebhook);
      if (ccRestClient.profileType == CCConstants.PROFILE_TYPE_AGENT) {
        self.getApproverList();
      } else {
        self.updatenumberOfActiveApprovers();
      }
    }
    self.parentOrganization = new ParentOrganisation();
    ko.mapping.fromJS(organizationData.parentOrganization, {}, self.parentOrganization);
  };

  /**
   * This method populates organization dynamic property meta data into
   * dynamic property meta container view model.
   *
   * @private
   * @function
   * @name OrganizationViewModel#populateDynamicPropertyMetaData
   */
  Organization.prototype.populateDynamicPropertiesMetaData = function() {
    var self = this;
    var organizationPramas = {};
    organizationPramas[CCConstants.PARENT] = CCConstants.ENDPOINT_ORGANIZATION_PARAM;
    if (self.dynamicPropertyMetaInfo && self.dynamicPropertyMetaInfo.dynamicPropertyMetaCache && 
        !self.dynamicPropertyMetaInfo.dynamicPropertyMetaCache.hasOwnProperty(CCConstants.ENDPOINT_ORGANIZATION_PARAM)) {

      ccRestClient.request(CCConstants.ENDPOINT_GET_ITEM_TYPE, organizationPramas,
        //success callback
        function(dynamicPropData){
          self.dynamicPropertyMetaInfo.intializeDynamicProperties(dynamicPropData.specifications, CCConstants.ENDPOINT_ORGANIZATION_PARAM);
        },
        //error callback
        function(dynamicPropData) {
        },
        CCConstants.ENDPOINT_ORGANIZATION_PARAM);
      }
  }

  /**
   * Returns list of organization properties that should be ignored during 
   * fromJS call.
   * @private
   * @function
   * @name OrganizationViewModel#listOfIgnoreProperties
   */
  Organization.prototype.listOfIgnoreProperties = function() {
    var ignoreProperties = {
      'ignore': ["id", "active", "relativeRoles", "approvalRequired", "orderPriceLimit",
                 "delegateApprovalManagement", "numberOfActiveApprovers", "isApprovalWebhookEnabled", "parentOrganization"]
    }
    return ignoreProperties;
  }

  /**
   * Gets list of Active Approver in an organization
   */
  Organization.prototype.getApproverList = function(){
    var self =this;
    var approverId = "";
    for (var i = 0; i < self.relativeRoles().length; i++){
      if (self.relativeRoles()[i].function == "approver") {
        approverId = self.relativeRoles()[i].repositoryId;
      }
    }
    var queryParams = {};
    queryParams.q = '(profileType eq \"'
    + CCConstants.B2B_PROFILE_TYPE +'\"';
    queryParams.q += ' AND (roles co \"' + approverId + '\") AND active eq \"1\")';
    queryParams.queryFormat = 'SCIM';

    var url = CCConstants.ENDPOINT_SEARCH_CUSTOMERS;
    ccRestClient.request(url, queryParams,
      //success callback
      function(data) {
        self.numberOfActiveApprovers(data.items.length);
      },
      //error callback
      function(data) {
      });
  }

  /**
   * Get the accounts which were initiated by self registration request.
   * @function
   * @name OrganizationViewModel#loadOrganizationsWithSelfRegistration
   * @param {function} pSuccessCallback success callback method
   * @param {function} pErrorCallback failure callback method
   */
  Organization.prototype.loadOrganizationsWithSelfRegistration = function(pSuccessCallback, pErrorCallback) {
    var queryParams = {};
    queryParams.q ="source eq \"selfRegistration\"";
    queryParams.useAdvancedQParser = true;
    ccRestClient.request(CCConstants.ENDPOINT_B2B_ADMINISTRATION_LIST_ORGANIZATIONS, queryParams,
        pSuccessCallback,
        pErrorCallback);
  }

  /**
   * Get the Organization Request Id for the
   * Organization, if the Organization is initiated by Registration request.
   * @function
   * @name OrganizationViewModel#getRegistrationRequest
   * @param {function} pSuccessCallback success callback method
   * @param {function} pErrorCallback failure callback method
   */
  Organization.prototype.getRegistrationRequest = function(pSuccessCallback, pErrorCallback) {
    var self = this;
    var queryParams = {};
    queryParams.q = CCConstants.ENDPOINT_ORGANIZATION_TYPE_PARAM + " eq \""+ self.id() + "\"";
    queryParams.fields = "id";
    ccRestClient.request(CCConstants.ENDPOINT_LIST_ORGANIZATION_REQUESTS, queryParams, pSuccessCallback,
           pErrorCallback);
  }

  /**
   * Get the organization notes by making a call to get Organization request for the organization request Id.
   * @function
   * @name OrganizationViewModel#getRegistrationRequestNotes
   * @param {String} pOrgRequestId organization request id
   * @param {function} pSuccessCallback success callback method
   * @param {function} pErrorCallback failure callback method
   */
  Organization.prototype.getRegistrationRequestNotes = function(pOrgRequestId, pSuccessCallback, pErrorCallback) {
    var queryParams = {};
    queryParams.fields = "approverComments,requesterComments,siteId,relatedOrganizationName";
    ccRestClient.request(CCConstants.ENDPOINT_GET_ORGANIZATION_REQUEST, queryParams, pSuccessCallback, 
           pErrorCallback, pOrgRequestId);
  }

  /**
   * Update the organization
   * @function
   * @name OrganizationViewModel#updateOrganization
   * @param {Object} pData data to be updated for the organization
   * @param {function} pSuccessCallback success callback method
   * @param {function} pErrorCallback failure callback method
   */
  Organization.prototype.updateOrganization = function(pData, pSuccessCallback, pErrorCallback) {
    var self = this;
    ccRestClient.request(CCConstants.ENDPOINT_UPDATE_ORGANIZATION, pData, pSuccessCallback,
           pErrorCallback, self.id());
  }

  /**
   * Updates dynamic property values for the selected organization
   * @function
   * @name OrganizationViewModel#updateDynamicProperties
   * @param {string} pOpDynamicProperty mode for dynamic property (view/update)
   */
  Organization.prototype.updateDynamicProperties = function(pOpDynamicProperty) {
    var self = this;
    var dynamicPropertyMetaCache = self.dynamicPropertyMetaInfo.dynamicPropertyMetaCache[CCConstants.ENDPOINT_ORGANIZATION_TYPE_PARAM];
    self.initializeDynamicProperties(dynamicPropertyMetaCache, pOpDynamicProperty);
  }

  /**
   * Initializes the dynamic properties.
   * Dynamic properties with null values should not be displayed in read only state.
   * @function
   * @name OrganizationViewModel#initializeDynamicProperties
   * @param {Object} pMetaData dynamic properties meta data
   * @param {string} pOpDynamicProperty mode for dynamic property (view/update)
   */
  Organization.prototype.initializeDynamicProperties = function(pMetaData, pOpDynamicProperty) {
    var self = this;
    self.dynamicProperties.removeAll();
    var dynPropArray = [];
    for (var i = 0; i < pMetaData.length; i++) {
      if (self.hasOwnProperty(pMetaData[i].id())) {
        var dynPropValue = self[pMetaData[i].id()]();
        // Special case for checkboxes
        if (pMetaData[i].type() === 'checkbox' && dynPropValue !== true) {
          dynPropValue = false;
        }
        //type check for 'boolean' is added as dynamic property of type checkbox should be shown even if it is not checked.
        if (pOpDynamicProperty === "update" || (dynPropValue !== undefined && dynPropValue !== null) || typeof dynPropValue === "boolean") {
          if (pMetaData[i].type() === "enum") {
            for (var j = 0; j < dynPropValue.length; j++) {
              value = dynPropValue[j];
              pMetaData[i].values.push(value);
            }
            dynPropArray.push(pMetaData[i]);
          } else {
            pMetaData[i].value(dynPropValue);
            dynPropArray.push(pMetaData[i]);
          }
        }
      }
    }
    self.dynamicProperties(self.dynamicProperties().concat(dynPropArray));
  }

  /**
   * Check if the dynamic properties are valid
   * @function
   * @name OrganizationViewModel#isOrganizationDynamicPropertiesValid
   * @returns true if all dynamic properties are valid, otherwise false.
   */
  Organization.prototype.isOrganizationDynamicPropertiesValid = function() {
    var self = this;
    for (var i = 0; i < self.dynamicProperties().length; i++) {
      var dynProp = self.dynamicProperties()[i];
      if (!dynProp.isValid()) {
        return false;
      }
    }
    return true;
  };

  /**
   * This resets the isModified attribute of dynamic properties
   * @private
   * @function
   * @name OrganizationViewModel#resetDynamicPropertiesValueIsModified
   */
  Organization.prototype.resetDynamicPropertiesValueIsModified = function() {
    var self = this;
    for (var i = 0; i < self.dynamicProperties().length; i++) {
      if (self.dynamicProperties()[i].value.isModified) {
        self.dynamicProperties()[i].value.isModified(false);
      }
    }
  };

  return Organization;
  }
);

