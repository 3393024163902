//----------------------------------------
/**
 * Module that supports lazy loading of
 * images.
 */
define(
  //-------------------------------------------------------------------
// PACKAGE NAME
//-------------------------------------------------------------------
  'ccLazyImages',

  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  [
    'knockout', 'pubsub', 'ccConstants'
  ],

  //-------------------------------------------------------------------
  // MODULE DEFINITION
  //-------------------------------------------------------------------

  function(ko, PubSub, CCConstants) {

    'use strict';

    /**
     * Constructor for CCLazyImages object
     *
     *
     * @private
     * @static
     * @class
     * @name  CCLazyImages
     * @class Handles loading of lazy images
     */
    function CCLazyImages() {
      var self = this;

      // The delay before checking for lazily loaded images that now need to be loaded
      var delayForEventCheck = 200;

      // The lazy image intersection observer if supported
      var lazyImageObserver;

      // The set of lazy images to track
      var lazyImagesToTrack = new Array();

      // Allow one pass at a time for events
      var lazyLoadActive = false;

      // Mutation observer
      var mutationObserver;

      // Timer for loading of out of focus images after an initial delay
      var delayedImageChecker;

      // Allow one pass at a time for delayed loading
      var delayedLoadActive = false;

      /**
       * The minimum interval within which the document will be checked for
       * lazy images to load.
       */
      self.THROTTLE_TIMEOUT = 100;

      /**
       * Throttles a function and delays its execution, so it's only called at most
       * once within a given time period.
       * @param {Function} fn The function to throttle.
       * @param {number} timeout The amount of time that must pass before the
       *     function can be called again.
       * @return {Function} The throttled function.
       */
      self.throttle = function(fn, timeout) {
        var timer = null;
        return function () {
          if (!timer) {
            timer = setTimeout(function() {
              fn();
              timer = null;
            }, timeout);
          }
        };
      }


      /**
       * This method is called to observe a lazy image
       * @private
       * @function CCLazyImages#observeLazyImage
       * @param {Element} pImage the image to observe
       * @param {number} pDelay the delay in seconds to apply before loading lazy images
       */
      self.observeLazyImage = function(pImage, pDelay) {
        //console.debug("Observing image with lazy src " + pImage.dataset.src);

        // Indicate that this image is being lazily loaded
        pImage.dataset.lazyLoading = true;

        // Check if this is the first image to be observed
        var isFirstImage = (lazyImagesToTrack.length === 0);

        // Add this image to the list of images being tracked for lazy loading
        if (lazyImagesToTrack.indexOf(pImage) == -1) {
          lazyImagesToTrack.push(pImage);
        }

        // If using intersection observer, then start observing this image
        if (lazyImageObserver) {
          lazyImageObserver.observe(pImage);
        }

        // Otherwise track image using event listeners and mutation observers
        else {

          // Enable tracking of scroll and resize events
          if (isFirstImage) {
            //console.debug("Enabling event listeners");
            document.addEventListener("scroll", self.checkForLazyImagesToLoad);
            window.addEventListener("resize", self.checkForLazyImagesToLoad);
            window.addEventListener("orientationchange", self.checkForLazyImagesToLoad);

            // Start observing
            if (mutationObserver) {
              mutationObserver.observe(document, {
                attributes: true,
                childList: true,
                characterData: true,
                subtree: true
              });
            }
          }
        }

        // If loading out of focus images after a delay, start timer to handle this
        if (isFirstImage && pDelay > 0) {
          delayedImageChecker = setInterval(self.loadOutOfFocusImages, pDelay*1000);
        }
      };

      /**
       * This method is called to stop observing a lazy image
       * @private
       * @function CCLazyImages#unobserveLazyImage
       * @param {Element} pImage the image to stop observing
       */
      self.unobserveLazyImage = function(pImage) {
        //console.debug("Unobserving image with lazy src " + pImage.dataset.src);

        // Indicate that the image is no longer being lazily loaded
        pImage.dataset.lazyLoading = false;

        // Remove from list of images being tracked
        lazyImagesToTrack = lazyImagesToTrack.filter(function(pTrackedImage) {
          return pTrackedImage != pImage;
        });

        // If using intersection observer, then stop observing this image
        if (lazyImageObserver) {
          lazyImageObserver.unobserve(pImage);
        }

        // Otherwise cancel event listeners if there are no more images to load
        else {
          // Cancel event listeners if there are no images left to be observed
          if (lazyImagesToTrack.length === 0) {
            //console.debug("Disabling event listeners");
            document.removeEventListener("scroll", self.checkForLazyImagesToLoad);
            window.removeEventListener("resize", self.checkForLazyImagesToLoad);
            window.removeEventListener("orientationchange", self.checkForLazyImagesToLoad);

            // Stop the mutation observer
            if (mutationObserver) {
              mutationObserver.disconnect();
            }
          }
        }

        // If there are no more images to load, cancel the timer that checks for images to load
        // after a delay
        if (lazyImagesToTrack.length == 0) {
          clearInterval(delayedImageChecker);
        }

      };

      /**
       * @function Sets up styling for the image to be lazily loaded
       * @param {Element} pImage pImage the lazy image whose clases to reset
       * @param {Object} pValues Binding values which may include styling directives.
       */
      self. setupLazyLoadStyling = function(pImage, pValues) {
        var lazyLoadingImageClass, lazyLoadedImageClass, lazyLoadingParentClass;

        // Class name for the image element while the lazy image has yet to be loaded
        lazyLoadingImageClass = ko.utils.unwrapObservable(pValues.lazyLoadingImageClass);
        if (!lazyLoadingImageClass) {
          lazyLoadingImageClass = 'ccLazyLoad';
        }
        pImage.classList.add(lazyLoadingImageClass);
        pImage.dataset.lazyLoadingImageClass = lazyLoadingImageClass;

        // Class name for the image element when the lazy image has been loaded
        lazyLoadedImageClass = ko.utils.unwrapObservable(pValues.lazyLoadedImageClass);
        if (!lazyLoadedImageClass) {
          lazyLoadedImageClass = 'ccLazyLoaded';
        }
        pImage.dataset.lazyLoadedImageClass = lazyLoadedImageClass;

        // Class name for the image element's parent. Use an animated background by default
        lazyLoadingParentClass = ko.utils.unwrapObservable(pValues.lazyLoadingParentClass);
        if (!lazyLoadingParentClass) {
          lazyLoadingParentClass = 'ccLazyLoad-background';
        }
        pImage.parentElement.classList.add(lazyLoadingParentClass);
        pImage.dataset.lazyLoadingParentClass = lazyLoadingParentClass;
      }



      /**
       * @function Resets styling for the lazily loaded image
       * @param {Element} pImage  pImage the lazy image whose clases to reset
       */
      self.resetLazyLoadStyling = function(pImage) {

        // Remove 'loading' class from parent
        if (pImage.dataset.lazyLoadingParentClass) {
          pImage.parentElement.classList.remove(pImage.dataset.lazyLoadingParentClass);
        }
        // Remove 'loading' class for image
        if (pImage.dataset.lazyLoadingImageClass) {
          pImage.classList.remove(pImage.dataset.lazyLoadingImageClass);
        }

        // Add 'loaded' class for image
        if (pImage.dataset.lazyLoadedImageClass) {
          pImage.classList.add(pImage.dataset.lazyLoadedImageClass);
        }
      };

      /**
       * This method is called to load a lazy image
       * @function CCLazyImages#loadLazyImage
       * @param {Element} pImage the image to monitor
       */
      self.loadLazyImage = function(pImage) {

        // Error handling for the lazily loaded image
        var defaultErrSrc = pImage.dataset.defaultErrorSrc;
        if (!defaultErrSrc) {
          defaultErrSrc = CCConstants.SITE_DEFAULT_NO_IMAGE_URL;
        }
        var errorSrc = pImage.dataset.errorSrc;
        var errSrc = errorSrc;
        if (!errSrc) {
          errSrc = defaultErrSrc;
        }
        var errorAlt = pImage.dataset.errorAlt;
        var onerror = pImage.dataset.onerror;

        // If the lazily loaded source loads, reset the image's loading/loaded classes
        pImage.onload = function () {
          self.resetLazyLoadStyling(pImage);
        };

        // If lazily loaded source fails to load, display an error image instead
        pImage.onerror = function () {

          // Reset the loading/loaded classes for the image
          self.resetLazyLoadStyling(pImage);

          // Disable dynamic imaging
          if (pImage.srcset) {
            pImage.removeAttribute("srcset");
          }
          if (pImage.sizes) {
            pImage.removeAttribute("sizes");
          }

          // Try the first error image
          var errorImage = new Image();

          // On successful load of the error image, display it in place of the product image
          errorImage.onload = function () {
            // If the image fails to load, displays the error image
            pImage.src = errSrc;

            //run the binding's onerror event.
            if (onerror) {
              onerror(pImage);
            }
            // clear out the onerror handler to prevent an infinite loop in Firefox and IE browsers
            // if the errorSrc or default site error image is not found
            pImage.onerror = "";
          };

          // If the error image fails to load, for any reason, fall back to the default error image
          errorImage.onerror = function () {

            var defaultErrorImage = new Image();

            // Default error image loaded
            defaultErrorImage.onload = function () {
              pImage.src = defaultErrorImage.src;


              //run the binding's onerror event.
              if (onerror) {
                onerror(pImage);
              }
              // clear out the onerror handler to prevent an infinite loop in Firefox and IE browsers
              // if the errorSrc or default site error image is not found
              pImage.onerror = "";
            };

            // Fallback 2.
            // If the default error image fails, for any reason, as a final fallback, show /img/no-image.jpg
            defaultErrorImage.onerror = function () {
              pImage.src = CCConstants.SITE_DEFAULT_NO_IMAGE_URL;

              //run the binding's onerror event.
              if (onerror) {
                onerror(pImage);
              }
              // clear out the onerror handler to prevent an infinite loop in Firefox and IE browsers
              // if the errorSrc or default site error image is not found
              pImage.onerror = "";
            }

            defaultErrorImage.src = defaultErrSrc;
          }

          errorImage.src = errSrc;

          if (errorAlt) {
            pImage.alt = errorAlt;
          }
        }; // end of pImage.onerror function

        // Set up alt and title for the lazily loaded image
        if (pImage.dataset.alt) {
          pImage.alt = pImage.dataset.alt;
        }
        if (pImage.dataset.title) {
          pImage.title = pImage.dataset.title;
        }

        pImage.dataset.lazyLoading = false;

        // Load the lazily loaded image
        //console.debug("Loading lazy image " + pImage.dataset.src);
        if (pImage.dataset.srcset) {
          pImage.srcset = pImage.dataset.srcset;
        }
        if (pImage.dataset.sizes) {
          pImage.sizes = pImage.dataset.sizes;
        }
        if (pImage.dataset.src) {
          pImage.src = pImage.dataset.src;
        }
      };

      /**
       * This method is called to check for images that need to be loaded
       * on a scroll, resize or orientation change event
       * @private
       * @function CCLazyImages#checkForLazyImagesToLoad
       */
      self.checkForLazyImagesToLoad = function() {

        if (lazyLoadActive === false) {
          lazyLoadActive = true;
          setTimeout(function() {

            //console.debug("# images to check is " + lazyImagesToTrack.length );
            if (lazyImagesToTrack.length > 0) {
              lazyImagesToTrack.forEach(function(pLazyImage) {
                //self.reportImageRect(pLazyImage.dataset.src, pLazyImage.getBoundingClientRect());
                if (self.isImageVisible(pLazyImage, pLazyImage.dataset.src)) {

                  // Load the image
                  self.loadLazyImage(pLazyImage);
                  self.unobserveLazyImage(pLazyImage);
                }
                else if (!document.body.contains(pLazyImage)) {
                  self.unobserveLazyImage(pLazyImage);
                }
              });
            }

            // Reset the gatekeeper
            lazyLoadActive = false;

          }, delayForEventCheck);
        }
      };

      /**
       * Checks to see if the image represented by the IntersectionObserverEntry is visible
       * @param {IntersectionObserverEntry} pEntry the intersection entry
       */
      self.checkForIntersection = function(pEntry) {
        //self.reportImageRect(pEntry.target.dataset.src, pEntry.boundingClientRect);
        if (pEntry.isIntersecting || pEntry.intersectionRatio > 0) {

          // Load the lazy image and stop observing it.
          var lazyImage = pEntry.target;
          self.loadLazyImage(lazyImage);
          self.unobserveLazyImage(lazyImage);
        }
      }

      /**
       * Reports the client rect for the image
       * @param {String} pSrc the image source
       * @param {String} pSrc the image source
       * @param {ClientRect} pRect the client rect for the image
       */
      self.reportImageRect = function(pSrc, pRect) {
        var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        console.debug("Window height=" + windowHeight +
          " image: top=" + pRect.top + " bottom=" + pRect.bottom + " height=" + pRect.height +
          " for image " + pSrc);
      }

      /**
       * Checks if the image is visible
       * @param {Element} pImage the image to check
       * @param {String} pSrc the source of the image
       */
      self.isImageVisible = function(pImage, pSrc) {

        var imgRect = pImage.getBoundingClientRect();
        var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        //self.reportImageRect(pSrc, imgRect);
        if (imgRect.top <= window.innerHeight && imgRect.bottom > 0) {
          //console.debug("Image " + pSrc + " is visible");
          return true;
        }

        return false;
      }

      /**
       * Checks for out of focus images to load after a delay
       */
      self.loadOutOfFocusImages = function() {

        // The time needed to load out-of-focus images may be longed than the delayed load interval,
        // so only allow one delayed load at a time.
        if (delayedLoadActive === false) {
          delayedLoadActive = true;
          setTimeout(function () {

            if (lazyImagesToTrack.length > 0) {
              //console.debug("Checking " + lazyImagesToTrack.length + " lazy images after initial delay");
              lazyImagesToTrack.forEach(function (pLazyImage) {

                // Only load if this is still in the DOM and hasn't already been loaded
                if (document.body.contains(pLazyImage) && pLazyImage.src !== pLazyImage.dataset.src) {

                  // Load and stop observing this image
                  self.unobserveLazyImage(pLazyImage);
                  self.loadLazyImage(pLazyImage);
                }

                // If image is no longer in the DOM, stop observing it
                else if (!document.body.contains(pLazyImage)) {
                  self.unobserveLazyImage(pLazyImage);
                }
              });
            }

            // Unlock the gate
            delayedLoadActive = false;

          }, delayForEventCheck);
        }
      }

      // Detect which mechanism will be used to manage loading of lazy images
      // Use intersection observer if supported

      // There is an issue with Edge that affects lazy loading images in a popup stack (e.g.
      // product quick view in a collection layout) where the intersection observer doesn't observe
      // the popup coming into view so the lazy image is never loaded. With browsers such as Chrome
      // the intersection observer fires 3 times, the third time being when the popup comes into
      // view. With Edge, the observer only fires 2 times, both before the popup appears.
      // Therefore don't useIntersectionObserver with Edge for now.
      if ("IntersectionObserver" in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
        'isIntersecting' in window.IntersectionObserverEntry.prototype &&
        navigator.userAgent.indexOf("Edge") === -1)  {

        //console.debug("using IntersectionObserver to track lazy load images");
        lazyImageObserver = new IntersectionObserver(function(pEntries, pObserver) {
          // If any observed images are now in viewport, load the image
          pEntries.forEach(function(pEntry) {
            self.checkForIntersection(pEntry);
          });
        });
      }
      // Otherwise track scroll and resize events
      else {
        lazyLoadActive = false;
        //console.debug("using scroll/resize events to track lazy load images");

        // Throttle checks for lazy images to load
        this.checkForLazyImagesToLoad = this.throttle(
          this.checkForLazyImagesToLoad, this.THROTTLE_TIMEOUT);

        // Set up the mutation observer
        if ('MutationObserver' in window) {
          mutationObserver = new MutationObserver(self.checkForLazyImagesToLoad);
        }
      }
    }

    return new CCLazyImages();

  }
);
