/***
 * This view model can be used to hold the payments data and contains methods to invoke the add payments API.
 * This is a singleton object and can be accessed by using the getInstance method 
 **/
define(
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  'viewModels/paymentsViewModel',
  
  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  ['knockout', 'pubsub', 'ccConstants', 'ccRestClient', 'CCi18n', 'pageLayout/site', 'viewModels/cash', 'viewModels/creditCard',
   'viewModels/giftCard', 'viewModels/invoice', 'viewModels/paypal', 'viewModels/payu', 'viewModels/loyalty', 'viewModels/storeCredit',
   'navigation', 'pageLayout/order', 'ccStoreConfiguration', 'viewModels/inStorePayment', 'notifier', 'viewModels/pointsPayment'],
  
  //-------------------------------------------------------------------
  // MODULE DEFINITION
  //-------------------------------------------------------------------
  function (ko, pubsub, CCConstants, ccRestClient, CCi18n, site, Cash, CreditCard,
		  GiftCard, Invoice, Paypal, PayU, Loyalty, StoreCredit,
		  navigation, order, StoreConfiguration, InStorePayment, notifier, PointsPayment) {
    'use strict';
    var CART_EVENT_GIFTCARD_REAPPLY = 8;
    var PAYMENTS_VIEW_MODEL_ID = "PaymentsViewModel";
    function PaymentsViewModel() {
      if(PaymentsViewModel.singleInstance) {
        throw new Error("Cannot instantiate more than one PaymentsViewModel, use getInstance(data)");  
      }
      
      var self = this;
      
      // Place holder to store the Authorized payment groups
      self.completedPayments = ko.observableArray([]);
      // Place holder to store the payment groups that are yet to be processed
      self.pendingPayments = ko.observableArray([]);
      // Place holder to store the failed payment groups
      self.failedPayments = ko.observableArray([]);
      // The Balance amount due
      self.paymentDue = ko.observable();
      self.loyaltyPaymentDue = ko.observable();
      self.minimumMonetaryAmountDue = ko.observable(0);

      // Place holder to store the Authorized payments in case of historical orders
      self.historicalCompletedPayments = ko.observableArray([]);
      self.historicalCompletedLoyaltyPayments = ko.observableArray([]);
      
      // Cash Enabled Countries List
      self.cashEnabledCountries = [];
      // Scheduled Order Enabled Gateways
      self.scheduleOrderEnabledGateways = [];
      // Approval Enabled Gateways
      self.approvalEnabledGateways = [];
      // Is Generic Card Gateway
      self.isCardGatewayGeneric = false;
      self.paypalPaymentGroupId = ko.observable();
      // Is loyaltyPoints payment type is enabled
      self.isLoyaltyEnabled = ko.observable(false);
      // reference to loyalty view model
      //self.loyaltyViewModel = ko.observable(null);
      //Is card payment enabled
      self.isCardPaymentDisabled = ko.observable(false);
      //Is paypal payment disabled
      self.isPaypalDisabled      = ko.observable(false);
      self.isPaypalVerified   = ko.observable(false);
      // flag to display error msg when insufficient points
      //self.userHasInsufficientPoints = ko.observable(false);

      //Is store credit payment type is enabled
      self.isStoreCreditEnabled = ko.observable(false);

      //Is inStore payment enabled
      self.isInStorePaymentEnabled = ko.observable(false);

      //Is cash payment enabled
      self.isCashPaymentEnabled = ko.observable(false);

    //Is payu payment enabled
      self.isPayuLPaymentEnabled = ko.observable(false);
      //Is saved card allowed
      self.isCVVRequiredForSavedCards = true;
      self.allowSavingCards = false;

      // Scheduled Order enable variables
      self.isCardEnabledForScheduledOrder = ko.observable(false);
      self.isGiftCardEnabledForScheduledOrder = ko.observable(false);
      self.isInvoiceEnabledForScheduledOrder = ko.observable(false);
      self.isCashEnabledForScheduledOrder = ko.observable(false);
      self.isPayULEnabledForScheduledOrder = ko.observable(false);
      self.isPaypalEnabledForScheduledOrder = ko.observable(false);
      self.isLoyaltyEnabledForScheduledOrder = ko.observable(false);
      self.isStoreCreditEnabledForScheduledOrder = ko.observable(false);
      self.isInStorePaymentEnabledForScheduledOrder = ko.observable(false);
      
      // Order Approval enable Variables
      self.isCardEnabledForApproval = ko.observable(false);
      self.isGiftCardEnabledForApproval = ko.observable(false);
      self.isInvoiceEnabledForApproval = ko.observable(false);
      self.isCashEnabledForApproval = ko.observable(false);
      self.isPayULEnabledForApproval = ko.observable(false);
      self.isPaypalEnabledForApproval = ko.observable(false);
      self.isLoyaltyEnabledForApproval = ko.observable(false);
      self.isStoreCreditEnabledForApproval = ko.observable(false);
      self.isInStorePaymentEnabledForApproval = ko.observable(false);

      // Variables introduced to hold amount that is authorised in pending payments and order amendment(for agent)
      self.totalAmountAuthorized = ko.observable(0);
      self.totalAmountAuthorizedOnVoid = ko.observable(0);
      self.partialPaymentOption = "";
      self.toBeVoidedPayments = ko.observableArray([]);
      self.amountRemaining = "";
      self.primaryCurrencyAmountRemaining = "";
      // in multi currency scenario, the loyalty payment authorized is saved here
      self.primaryCurrencyTotalAmountAuthorized = ko.observable(0);
      self.isBalanceDueAPICallInProgress = ko.observable(false);


      // Exclude fields map contains fields that are should be skipped 
      // while mapping the response payment group to the request payment data
      self.excludeFieldsMap = ['amount', 'cardNumber', 'cardType', 'giftCardNumber', 'seqNum', 'customPaymentProperties'];
      
      /**
       * Method to set cashEnabledCountries from siteViewModel
       */
      self.setCashEnabledCountries = function() {
        for(var key in site.getInstance().extensionSiteSettings) {
          var settingsObject = site.getInstance().extensionSiteSettings[key];
          if (settingsObject[CCConstants.PAYMENT_METHOD_TYPES] &&
            settingsObject[CCConstants.PAYMENT_METHOD_TYPES].split(",").indexOf(CCConstants.CASH_PAYMENT_TYPE) != -1 ) {
            if (settingsObject[CCConstants.SELECTED_COUNTRIES]) {
              self.cashEnabledCountries = settingsObject[CCConstants.SELECTED_COUNTRIES];
            }
          }
        }
      };
      
      /**
       * Method to set the payment related meta-data
       * */
      self.populatePaymentData = function(data, pUserData) {
        self.cardTypeList = ko.observableArray(data.cards);
        self.gateways = data.gateways;
        self.payULatamCountryList = data.payULatamCountryList;
        self.paymentMethods = ko.observableArray();
        
        //If it's a b2b user initialize with account specific payment methods
        if (pUserData().isB2BUser()) {
          var useAllPayment = pUserData().currentOrganization().derivedUseAllPaymentMethodsFromSite;
          if (useAllPayment == undefined || !useAllPayment) {
            var enabledTypesLength = data.enabledTypes.length;
            var organizationPaymentMethods =
              pUserData().currentOrganization().derivedPaymentMethods;
            var organizationEnabledTypes = [];
            for (var i=0; i<enabledTypesLength; i++) {
              for (var j=0; j < organizationPaymentMethods.length; j++) {
                if (data.enabledTypes[i] == organizationPaymentMethods[j]) {
                  organizationEnabledTypes.push(data.enabledTypes[i]);
                }
              }
            }
            self.enabledTypes = organizationEnabledTypes;       	  
          } else {
            self.enabledTypes = data.enabledTypes;           
          }
        } else {
          self.enabledTypes = data.enabledTypes;
        }

        if (self.enabledTypes &&
            self.enabledTypes.indexOf(CCConstants.CARD_PAYMENT_TYPE) < 0) {
          self.isCardPaymentDisabled(true);
        }

        if (self.enabledTypes &&
            self.enabledTypes.indexOf(CCConstants.PAYPAL_PAYMENT_TYPE) < 0) {
          self.isPaypalDisabled(true);
        } else {
          self.isPaypalDisabled(false);	
        }

        //Is store credit payment type enabled
        if (self.enabledTypes &&
            self.enabledTypes.indexOf(CCConstants.STORE_CREDIT_PAYMENT_TYPE) != -1) {
          self.isStoreCreditEnabled(true);
        } else {
          self.isStoreCreditEnabled(false);
        }

        if (self.enabledTypes &&
            self.enabledTypes.indexOf(CCConstants.IN_STORE_PAYMENT_TYPE) != -1) {
          self.isInStorePaymentEnabled(true);
        }

        if (self.enabledTypes &&
            self.enabledTypes.indexOf(CCConstants.PAYULATAM_CHECKOUT_TYPE) != -1) {
          self.isPayuLPaymentEnabled(true);
        }

        if (self.enabledTypes &&
            self.enabledTypes.indexOf(CCConstants.CASH_PAYMENT_TYPE) != -1) {
          self.isCashPaymentEnabled(true);
        } else {
          self.isCashPaymentEnabled(false);
        }
        
        // Set Cash enabled countries List
        self.setCashEnabledCountries();
        self.scheduleOrderEnabledGateways = data.scheduledOrderEnabledGateways;
        self.approvalEnabledGateways = data.approvalEnabledGateways;
        self.isCardGatewayGeneric = data.isCardGatewayGeneric;
        self.isCVVRequiredForSavedCards = data.isCVVRequiredForSavedCards;
        StoreConfiguration.getInstance().isCVVRequiredForSavedCards(null != data.isCVVRequiredForSavedCards && data.isCVVRequiredForSavedCards == true? true: false);
        self.allowSavingCards = data.allowSavingCards;
        StoreConfiguration.getInstance().allowSavingCards(null != data.allowSavingCards && data.allowSavingCards == true? true: false);

        for (var i=0; i<data.scheduledOrderEnabledGateways.length; i++) {
          if (data.scheduledOrderEnabledGateways[i] === CCConstants.CARD_PAYMENT_TYPE) {
            self.isCardEnabledForScheduledOrder(true);
          }

          if (data.scheduledOrderEnabledGateways[i] === CCConstants.GIFT_CARD_PAYMENT_TYPE) {
            self.isGiftCardEnabledForScheduledOrder(true);
          }

          if (data.scheduledOrderEnabledGateways[i] === CCConstants.INVOICE_PAYMENT_TYPE) {
            self.isInvoiceEnabledForScheduledOrder(true);
          }

          if (data.scheduledOrderEnabledGateways[i] === CCConstants.CASH_PAYMENT_TYPE) {
            self.isCashEnabledForScheduledOrder(true);
          }

          if (data.scheduledOrderEnabledGateways[i] === CCConstants.PAYULATAM_CHECKOUT_TYPE) {
            self.isPayULEnabledForScheduledOrder(true);
          }
          
          if (data.scheduledOrderEnabledGateways[i] === CCConstants.PAYPAL_PAYMENT_TYPE) {
            self.isPaypalEnabledForScheduledOrder(true);
          }
          
          if (data.scheduledOrderEnabledGateways[i] === CCConstants.LOYALTY_POINTS_PAYMENT_TYPE) {
            self.isLoyaltyEnabledForScheduledOrder(true);
          }

          if (data.scheduledOrderEnabledGateways[i] === CCConstants.STORE_CREDIT_PAYMENT_TYPE) {
            self.isStoreCreditEnabledForScheduledOrder(true);
          }

          if (data.scheduledOrderEnabledGateways[i] === CCConstants.IN_STORE_PAYMENT_TYPE) {
            self.isInStorePaymentEnabledForScheduledOrder(true);
          }
        }
        for (var i=0; i<data.approvalEnabledGateways.length; i++) {
          if (data.approvalEnabledGateways[i] === CCConstants.CARD_PAYMENT_TYPE) {
            self.isCardEnabledForApproval(true);
          }

          if (data.approvalEnabledGateways[i] === CCConstants.GIFT_CARD_PAYMENT_TYPE) {
            self.isGiftCardEnabledForApproval(true);
          }

          if (data.approvalEnabledGateways[i] === CCConstants.INVOICE_PAYMENT_TYPE) {
            self.isInvoiceEnabledForApproval(true);
          }

          if (data.approvalEnabledGateways[i] === CCConstants.CASH_PAYMENT_TYPE) {
            self.isCashEnabledForApproval(true);
          }

          if (data.approvalEnabledGateways[i] === CCConstants.PAYULATAM_CHECKOUT_TYPE) {
            self.isPayULEnabledForApproval(true);
          }
          
          if (data.approvalEnabledGateways[i] === CCConstants.PAYPAL_PAYMENT_TYPE) {
            self.isPaypalEnabledForApproval(true);
          }
          
          if (data.approvalEnabledGateways[i] === CCConstants.LOYALTY_POINTS_PAYMENT_TYPE) {
            self.isLoyaltyEnabledForApproval(true);
          }
          
          if (data.approvalEnabledGateways[i] === CCConstants.STORE_CREDIT_PAYMENT_TYPE) {
            self.isStoreCreditEnabledForApproval(true);
          }

          if (data.approvalEnabledGateways[i] === CCConstants.IN_STORE_PAYMENT_TYPE) {
            self.isInStorePaymentEnabledForApproval(true);
          }
        }
        
        // Converting the enabledTypes to a Map so that it can be used in drop downs
        for(var i=0; i < self.enabledTypes.length; i++) {
          // Since the cash in common resource file is already mapped to 'Cash Payment' need to 
          // handle this indirectly by creating another constant cashPaymentMethod which maps to 'Cash'
          if(self.enabledTypes[i] == CCConstants.CASH_PAYMENT_TYPE) {
            self.paymentMethods().push({name:CCi18n.t('ns.common:resources.cashPaymentMethod'), value:self.enabledTypes[i]});	
          } else if (self.enabledTypes[i] == CCConstants.LOYALTY_POINTS_PAYMENT_TYPE) {
            // Loyalty should be enabled only for loggedin users 
            if (pUserData().loggedIn() && !pUserData().isUserSessionExpired()) {
              self.isLoyaltyEnabled(true);
              self.paymentMethods().push({name:CCi18n.t('ns.common:resources.loyaltyPoints'), value:self.enabledTypes[i]});
            } else {
              self.isLoyaltyEnabled(false);
            }
          } else {
            self.paymentMethods().push({name:CCi18n.t('ns.common:resources.'+ self.enabledTypes[i]), value:self.enabledTypes[i]});
          }

        }
      };
      
      /**
       * Payment authorization details.
       * @param {string} pEmailAddress Email address.
       * @param {Address} pBillingAddress Billing address.
       * @param {Object} pPaymentDetails The payment details.
       * @param {Object} pPaymentGroupDetails The Payment group details
       * @param {Object} pOrderDetails The order details.
       */
      self.createPaymentAuthDetails = function(pEmailAddress, pBillingAddress,
          pPaymentDetails, pPaymentGroupDetails, pOrderDetails) {
      	
      	var paymentAuthDetails = {};
      	
      	paymentAuthDetails.emailAddress = pEmailAddress;
        paymentAuthDetails.billingAddress = pBillingAddress;
        paymentAuthDetails.paymentDetails = pPaymentDetails;
        
        pOrderDetails.payments = [];
        pOrderDetails.payments.push(ko.toJS(pPaymentGroupDetails));
        paymentAuthDetails.orderDetails = pOrderDetails;

        return paymentAuthDetails;
      }
      
      /**
       * Utility method to create the payment object based on the payment method type
       * @param {String} type - Type of the payment method
       * */
      self.createPaymentGroup = function(type) {
        var payment = null;
        switch (type) {
          case CCConstants.CARD_PAYMENT_TYPE:
            payment = new CreditCard();
            break;
            
          case CCConstants.GIFT_CARD_PAYMENT_TYPE:
            payment = new GiftCard();
            break;
            
          case CCConstants.CASH_PAYMENT_TYPE:
            payment = new Cash();
            break;
            
          case CCConstants.INVOICE_PAYMENT_TYPE:
            payment = new Invoice();
            break;
            
          case CCConstants.PAYPAL_PAYMENT_TYPE:
            payment = new Paypal();
            break;
            
          case CCConstants.PAYULATAM_CHECKOUT_TYPE:
            payment = new PayU();
            break;
            
          case CCConstants.LOYALTY_POINTS_PAYMENT_TYPE:
            payment = new PointsPayment();
            break;
            
          case CCConstants.STORE_CREDIT_PAYMENT_TYPE:
            payment = new StoreCredit();
            break;

          case CCConstants.IN_STORE_PAYMENT_TYPE:
            payment = new InStorePayment();
            break;
        }
        payment.useDefaultBillingAddress = ko.observable(false);
        payment.isDefaultAddressValid = ko.observable(true);
        return payment;
      };
      
      /**
       * This method is used to process (partial) payments using the add payments API
       * @param {function} success - Success Call back function
       * @param {function} error - Error Call back function
       * @param {String} pOrderId - (optional) Order Id
       * @param {String} pUUID - UUID of the order. Mandatory for Anonymous flows in Store front context
       * @param {String} pProfileId - (optional) Profile Id of the shopper. For Store front, 
       * this is not required 
       * @param {String} pChannel - (optional) Channel 
       * @param {Object} pPayments - (optional) Payments array to be processed. If not passed, 
       * will take from the pendingPayments view model property 
       **/
      self.processPayments = function(success, error, pOrderId, pUUID, pProfileId, pChannel, pPayments) {
        var self = this;
        
        // Validate the input before processing the payments
        var tempValidationForPayment = null;
        if(pPayments) {
          tempValidationForPayment = pPayments;
        } else {
          tempValidationForPayment = self.pendingPayments();
        }
        for(var i=0; i<tempValidationForPayment.length; i++) {
        	if(!tempValidationForPayment[i].validatePaymentData()) {
        		// OOTB widget already validating the payment data while adding to pending
        		// payment array. Additional check in case merchant did not handle the widget
        		// validation properly
        		return;
        	}
        }
        
        // Request pay load for addPayment API
        var input = {};
        
        // Order Id
        if(pOrderId) {
          input["orderId"] = pOrderId;
        } 
        
        // order uuid
        if (pUUID) {
        	input["uuid"] = pUUID;
        }
        
        // Profile Id
        if(pProfileId) {
          input["profileId"] = pProfileId;
        }
        
        // Channel 
        if(pChannel) {
          input["channel"] = pChannel;
        }
        
        // Payments
        var tempPayments = [];
        if(pPayments && pPayments.length > 0) {
          tempPayments = self.preparePaymentsRequest(pPayments);
        } else if(self.pendingPayments() && self.pendingPayments().length > 0) {
          tempPayments = self.preparePaymentsRequest(self.pendingPayments());
        }
        input[CCConstants.PAYMENTS] = tempPayments;
        
        // Wrapper over the success call back where we are mapping the server payment group
        // response data to the request payment objects before calling the success call back
        var successHandler = function(data) {
          var requestPaymentsArray = [];
          if(pPayments) {
          	requestPaymentsArray = pPayments;
          } else {
          	requestPaymentsArray = self.pendingPayments();
          }
          
          // Map request to response
          for(var i=0; i<data.paymentResponses.length; i++) {
          	for(var j=0; j<requestPaymentsArray.length; j++) {
          		if(data.paymentResponses[i].seqNum == requestPaymentsArray[j].seqNum) {
          			
          			for(var key in data.paymentResponses[i]) {
                  if($.inArray(key, self.excludeFieldsMap) == -1) {
                  	if(ko.isObservable(requestPaymentsArray[j][key])) {
                  		requestPaymentsArray[j][key](data.paymentResponses[i][key]);
                  	} else {
                  		requestPaymentsArray[j][key] = data.paymentResponses[i][key];
                  	}
                  }
                }
          			break;
          		}
          	}
          }
          
          // call success function
          success(data);
        };
        
        // Invoke the add payment API
        ccRestClient.request(CCConstants.ADD_ORDER_PAYMENTS, input, successHandler, error);
      };
      
      /**
       * This method is used to inquire gift card balance add payments API. Multiple gift cards 
       * can be passed in the request
       * @param {function} success - Success Call back function
       * @param {function} error - Error Call back function
       * @param {String} pOrderId - Order Id
       * @param {Object} pPayments - Payments array to be processed
       * @param {String} pProfileId - User profile id for shopper
       **/
      self.inquireBalance = function(success, error, pPayments, pProfileId) {
        // Request pay load for addPayment API
        var input = {};
        
        input["op"] = CCConstants.INQUIRE_GIFT_CARD_BALANCE;
        
        // Gift card payments to which balance to be inquired
        var tempPayments = [];
        if(pPayments && pPayments.length > 0) {
          tempPayments = self.preparePaymentsRequest(pPayments);
        }
        input[CCConstants.PAYMENTS] = tempPayments;
        
        if(pProfileId){
          input[CCConstants.PROFILE_ID] = pProfileId;
        }
        // Invoke the API
        ccRestClient.request(CCConstants.ADD_ORDER_PAYMENTS, input, success, error);
      };
      
      /**
       * This method is used to invoke the API that calculates the balance due based on the primary and alternate (secondary) amounts
       * @param {function} success - Success Call back function
       * @param {function} error - Error Call back function
       * @param {Object} pRequestObject - Request Object containing the request params
       * Following parameters can be passed in the pRequestObject
       * - pOrderId
       * - pProfileId
       * - payments
       * - plgCurrency
       * - alternateCurrency
       **/
      self.calculateBalanceDue = function (success, error, pRequestObject) {
        if (!self.isBalanceDueAPICallInProgress()) {
          self.isBalanceDueAPICallInProgress(true);
          var input = self.prepareRequestForCalculateBalanceAPI(pRequestObject);
          ccRestClient.request(CCConstants.ENDPOINT_CALCULATE_REMAINING_BALANCE, input,
            function (data) {
              // Success callback
              self.isBalanceDueAPICallInProgress(false);
              success(data);
            },
            function (data) {
              // Error callback
              self.isBalanceDueAPICallInProgress(false);
              error(data);
            });
        }
      };

      /**
       * Utility method to prepare request to calculate Balance Due API based on the payments
       * @param {Object} pRequestObject - Request Object containing the request params
       * Following parameters can be passed in the pRequestObject
       * - pOrderId
       * - pProfileId
       * - payments
       * - plgCurrency
       * - alternateCurrency
       * @return {Object} response - The constructed response object that can be passed to API
       * */
      self.prepareRequestForCalculateBalanceAPI = function(pRequestObject) {
        var pointsAmount = 0;
        var monetaryAmount = 0;
        var pointsType = "";
        if (pRequestObject[CCConstants.ALTERNATE_CURRENCY].currencyType === CCConstants.LOYALTY_POINTS_PAYMENT_TYPE) {
          pointsType = pRequestObject[CCConstants.ALTERNATE_CURRENCY].currencyCode;
        } else {
          pointsType = pRequestObject.plgCurrency.currencyCode;
        }
        var payments = pRequestObject[CCConstants.PAYMENTS];
        var tempAmount = 0;
        for(var i = 0; i < payments.length; i++) {
          tempAmount = payments[i].amount();
          if (isNaN(tempAmount)) {
            tempAmount = 0;
          }
          if(payments[i].type === CCConstants.LOYALTY_POINTS_PAYMENT_TYPE) {
            pointsAmount = pointsAmount + parseFloat(tempAmount);
            if(pointsType == null) {
              pointsType = payments[i].currencyCode;
            } else if (pointsType !== payments[i].currencyCode) {
              // Error, there are Loyalty payments of different pointsType
              // To be handled in future if multiple loyalty payments are supported
            }
          } else {
            monetaryAmount = monetaryAmount + parseFloat(tempAmount);
          }
        }

        var request = {};
        if(pRequestObject.plgCurrency.currencyType == null) {
          request[CCConstants.AMOUNT] = monetaryAmount;
          request[CCConstants.ALTERNATE_CURRENCY_CODE] = pointsType;
          request[CCConstants.ALTERNATE_CURRENCY_AMOUNT] = pointsAmount;
        } else {
          request[CCConstants.AMOUNT] = pointsAmount;
          request[CCConstants.ALTERNATE_CURRENCY_CODE] = pRequestObject[CCConstants.ALTERNATE_CURRENCY].currencyCode;
          request[CCConstants.ALTERNATE_CURRENCY_AMOUNT] = monetaryAmount;
        }
        request.orderId = pRequestObject.pOrderId;
        if (pRequestObject.pProfileId) {
          request.profileId = pRequestObject.pProfileId;
        }
        return request;
      };

      /**
       * Utility method to prepare payments JSON data from the payment view models
       * @param {Object} payments - Array of payments view models
       * @return {Object} Array of JSON payment data
       * */
      self.preparePaymentsRequest = function(payments) {
        var tempPayments = [];
        for(var i = 0; i < payments.length; i++) {
        	payments[i].seqNum = i.toString();
          var payment = {};
          var jsViewModel = null;
          if (payments[i].toJSON && $.isFunction(payments[i].toJSON)) {
            jsViewModel = payments[i].toJSON();
          } else {
            jsViewModel = payments[i];
          }
          //var jsViewModel = ko.mapping.toJS(payments[i]);
          for(var prop in jsViewModel) {
            if($.isFunction(jsViewModel[prop])) {
              continue;
            }
            payment[prop] = jsViewModel[prop];
          }
          tempPayments.push(payment);
        }
        return tempPayments;
      };
      
      /**
       * Utility method to reset the state of paymentsViewModel
       * */
      self.resetPaymentsContainer = function() {
        if(!self.isPaypalVerified()){
          // Reset payment related data
          self.completedPayments([]);
          self.pendingPayments([]);
          self.failedPayments([]);
          self.historicalCompletedPayments([]);
          self.historicalCompletedLoyaltyPayments([]);
          self.toBeVoidedPayments([]);
          self.paymentDue(0);
          self.loyaltyPaymentDue(0);
          self.minimumMonetaryAmountDue(0);
        }
      };
      
      /**
       * Utility method to populate a view model from server data
       */
      self.populateViewModelWithServerData = function(viewModel, data) {
        if (data.paymentResponses && data.paymentResponses.length > 0) {
          for(var key in data.paymentResponses[0]) {
            viewModel[key] = data.paymentResponses[0][key];
          }
        }
      };
      
      /**
       * Finds whether there are minimum points required.
       */
      self.hasSufficientPoints = function(loyaltyViewModel, pointsType, minPtsRequired) {
        var hasPoints = false;
        if (loyaltyViewModel && loyaltyViewModel.selectedProgramDetails()
            && loyaltyViewModel.selectedProgramDetails().loyaltyPointDetails) {
          var loyaltyPointDetails = loyaltyViewModel.selectedProgramDetails().loyaltyPointDetails;
          for (var i = 0; i < loyaltyPointDetails.length; i++) {
            if (loyaltyPointDetails[i].pointsType && loyaltyPointDetails[i].pointsType == pointsType) {
              if (loyaltyPointDetails[i].pointsBalance && loyaltyPointDetails[i].pointsBalance >= minPtsRequired) {
                hasPoints = true;
                break;
              }
            }
          }
        }
        return hasPoints;
      };

      /**
       *update payment data from url parameters
       */
      self.updatePaymentGatewayData = function(data) {
        var parameterString = data.parameters;
        if (parameterString) {
            var params = parameterString.split('&');
            var result = {};
            for (var i = 0; i < params.length; i++) {
              var entries = params[i].split('=');
              result[entries[0]] = entries[1];
            }

            if(result[CCConstants.TOKEN] && !result[CCConstants.PAYMENT_ID]
              && result[CCConstants.PAYMENT_TYPE] === CCConstants.PAYPAL_PAYMENT_TYPE){
                result[CCConstants.PAYMENT_ID] = result[CCConstants.TOKEN];
            }
            if (result[CCConstants.PAYMENT_ID] && result[CCConstants.TOKEN] && result[CCConstants.PAYER_ID]) {
              var payment = self.createPaymentGroup(CCConstants.PAYPAL_PAYMENT_TYPE);
              payment.updatePaymentData(result);
              self.isPaypalVerified(true);
              if (self.pendingPayments().length > 0) {
                if (self.pendingPayments()[0].paymentMethodType == CCConstants.PAYPAL_PAYMENT_TYPE) {
                  self.pendingPayments()[0] = payment;
                }
              } else {
                self.pendingPayments().unshift(payment);
              }
            } else if (parameterString.indexOf(CCConstants.PAYU_REFERENCE_POL) != -1) {
              var txstatus_payu = result[CCConstants.PAYU_TRANSACTION_STATE];
              if (txstatus_payu == CCConstants.PAYU_TRANSACTION_APPROVED_CODE || 
                  txstatus_payu == CCConstants.PAYU_TRANSACTION_PENDING_CODE || 
                  txstatus_payu == CCConstants.PAYU_TRANSACTION_DECLINED_CODE || 
                  txstatus_payu == CCConstants.PAYU_TRANSACTION_EXPIRED_CODE || 
                  txstatus_payu == CCConstants.PAYU_TRANSACTION_ERROR_CODE) {
                var referenceCode = result[CCConstants.PAYU_REFERENCE_CODE];
                var tx_value = result[CCConstants.PAYU_TX_VALUE];
                var currency = result[CCConstants.CURRENCY];
                var signature = result[CCConstants.SIGNATURE];
              }
            }  else {
              self.isPaypalVerified(false);
            }
          } else if (self.isPaypalVerified() && !(navigation.getPathWithoutLocale() == self.checkoutLink)) {
            self.isPaypalVerified(false);
            self.resetPaymentsContainer();
          }

      };
      
        /**
         * Utility method to populate a gift card data
         */
        self.populateGiftCardsCallBack = function(pricingModel, lastCartEvent) {
          if (self.pendingPayments().length > 0) {
            for (var i = 0; i < self.pendingPayments().length; i++) {
              if (self.pendingPayments()[i].paymentMethodType == "physicalGiftCard") {
                var giftItem = {};
                var giftCard = self.pendingPayments()[i];
                giftItem['type'] = CCConstants.GIFT_CARD_PAYMENT_TYPE;
                giftItem['giftCardNumber'] = giftCard.giftCardNumber();
                giftItem['giftCardPin'] = giftCard.giftCardPin();
                if (giftCard.amountInGiftCard()) {
                  if (!(lastCartEvent
                      && lastCartEvent.type === CART_EVENT_GIFTCARD_REAPPLY && lastCartEvent.product
                      .giftCardNumber() === giftCard.giftCardNumber())) {
                    giftItem['amount'] = giftCard.amountInGiftCard();
                  }
                }
                pricingModel.payments.push(giftItem);
              }
            }
          }
        };

        /**
         * Utility method to update gift card data
         */
        self.updateGiftCardDetailsCallBack = function(data, lastCartEvent) {
          var gcToBeRemoved = [];
          if (data.payments && self.pendingPayments().length > 0) {
            for (var i = 0; i < self.pendingPayments().length; i++) {
              var found = false;
              var giftCard = self.pendingPayments()[i];
              for (var j = 0; j < data.payments.length; j++) {
                var giftCardPayment = data.payments[j];
                if (giftCardPayment.paymentMethod == CCConstants.GIFT_CARD_PAYMENT_TYPE
                    && giftCard.giftCardNumber() == giftCardPayment.giftCardNumber) {
                  giftCard.amountInGiftCard(giftCardPayment.balance);
                  giftCard.amount(giftCardPayment.amount);
                  // giftCard.isAmountRemaining(giftCardPayment.isAmountRemaining);
                  giftCard.maskedNumber(giftCardPayment.maskedCardNumber);
                  // giftCard.isApplyGiftCardClicked(false);
                  found = true;
                  break;
                }
              }

              // if (lastCartEvent && lastCartEvent.product &&
              // lastCartEvent.product.giftCardNumber &&
              // lastCartEvent.product.giftCardNumber() ===
              // giftCard.giftCardNumber()) {
              // giftCard.isPinCleared(false);
              // }

              if (found == false) {
                gcToBeRemoved.push(giftCard);
              }
            }
            for (var k = 0; k < gcToBeRemoved.length; k++) {
              self.pendingPayments.remove(function(item) {
                return item.giftCardNumber() == gcToBeRemoved[k]
                    .giftCardNumber();
              });
            }
            // TODO: Verify if we should send cart error or paymentVM error
            if (gcToBeRemoved && gcToBeRemoved.length > 0) {
              notifier.sendError(PAYMENTS_VIEW_MODEL_ID, CCi18n
                  .t('ns.common:resources.orderPricingPromotionError'), true);
            }

          }
        };
      /**
       * Get the payment gateway settings to check if the storeCredit is enabled
       * If its enabled, get store credit balance
       * @param pSuccessCallback
       * @param pFailureCallback
       * @param pProfileId
       */
      self.retrieveStoreCreditBalance = function(pSuccessCallback, pFailureCallback, pProfileId){
          var self = this;
          var data = {};
          ccRestClient.request(CCConstants.ENDPOINT_PAYMENT_GET_GATEWAYS, data,
            function(pData) {
            var paymentGateways = pData.paymentGateways;
            for(var i = 0; i < paymentGateways.length; i++) {
              if(paymentGateways[i].enabled) {
                // if Generic payment Gateway
                if(paymentGateways[i].type === CCConstants.GENERIC_PAYMENT_GATEWAY) {
                  var enabledTypes = paymentGateways[i].agentConfigEnabledTypes || [];
                  if( enabledTypes.length == 0){
                    continue;
                  }
                  for(var j = 0; j < enabledTypes.length; j++) {
                    if (enabledTypes[j] === CCConstants.STORE_CREDIT_PAYMENT_TYPE) {
                      var payments = [];      
                      payments.push({paymentMethodType : CCConstants.STORE_CREDIT_PAYMENT_TYPE});
                      self.inquireBalance(pSuccessCallback, pFailureCallback, payments, pProfileId);
                    }
                  }
                }
              }
            }
          },
          function(pData) {
          });
      };
      /*
       * This method is used to reset the authorized amount variables
       * in case of order amendment.
       * (Specific to agent only) 
       */
      self.resetAmountAuthorized = function(){
        self.totalAmountAuthorized(0);
        self.primaryCurrencyTotalAmountAuthorized(0);
        self.totalAmountAuthorizedOnVoid(0);
      };

      /**
       * Utility method to check whether a payment is authorized or not based on state.
       * */
      self.isPaymentAuthorized = function(pPaymentState) {
        if(!(pPaymentState != CCConstants.PAYMENT_STATE_AUTHORIZED
              && pPaymentState != CCConstants.PAYMENT_GROUP_STATE_AUTHORIZED
                  && pPaymentState != CCConstants.PAYMENT_STATE_SETTLED
                  && pPaymentState != CCConstants.PAYMENT_GROUP_STATE_SETTLED
                  && pPaymentState != CCConstants.PAYMENT_STATE_PAYMENT_REQUEST_ACCEPTED
                  && pPaymentState != CCConstants.PAYMENT_GROUP_STATE_PAYMENT_REQUEST_ACCEPTED
                  && pPaymentState != CCConstants.PAYMENT_STATE_PAYMENT_DEFERRED
                  && pPaymentState != CCConstants.PAYMENT_GROUP_PAYMENT_DEFERRED)) {
          return true;
        } else {
          return false;
        }
      };

      /**
       * Utility method to check if void is initiated on payment group
       * @param pPaymentGroup payment group
       * @return {Boolean}
       */
      self.isVoidInitiatedForPaymentGroup = function(pPaymentGroup) {
        var isVoidInitiated = false;
        if(pPaymentGroup.transactionTypeInitiated && pPaymentGroup.transactionTypeInitiated == "void") {
          isVoidInitiated = true;
        }
        return isVoidInitiated;
      };
      /**
       * Removes a payment that is already authorized
       * @param pPaymentGroupData The payment group to be voided
       * @param pCancReason
       * @param pOrderID orderId
       * @param success successCallback
       * @param failure failureCallback
       * */
        self.voidAuthorizedPayment = function(pPaymentGroupData, pCancelReason, pOrderId,
            success, failure) {

          var self = this;
          var payments = [];

          var payment = {};
          self.removablePaymentGroup = pPaymentGroupData.array[pPaymentGroupData.index];
          self.removabelPaymentIndex = pPaymentGroupData.index;
          payment.paymentGroupId = pPaymentGroupData.array[pPaymentGroupData.index].paymentGroupId;
          payment.cancelReason = pCancelReason;
          payments.push(payment);

          var inputData = {};
          var url = "voidPayments";
          inputData["orderId"] = pOrderId;
          inputData[CCConstants.PAYMENTS] = payments;
          ccRestClient.request(url, inputData,
          // success callback
          function(pData) {
            success(pData);
          },
          // failure callback
          function(pData) {
            failure(pData);
          });
        };
      /**
       * Method to populate completed payments array
       * @param pPaymentGroups payment groups to be populated
       */
      self.populateCompletedPayments = function(pPaymentGroups) {
        var self = this;
        var paymentGroupsLength = pPaymentGroups.length;
        for (var i=0 ; i < paymentGroupsLength ; i++) {
          var isVoidInitiated = self.isVoidInitiatedForPaymentGroup(pPaymentGroups[i]);
          if(self.isPaymentAuthorized(pPaymentGroups[i].paymentState) && !isVoidInitiated) {
            self.completedPayments.push(pPaymentGroups[i]);

          }
        }
      };
      /**
       * Method to void payments in edit order.
       * Logic:
       * 1. Calls cancel payments endpoint to void required payments. Then order can either move to SUBMITTED state or
       *    PENDING PAYMENT state.- added another call to get the state of the order.
       * NOTE: As order state is not accepted to send in cancel payments endpoint response we are making another endpoint call(Get Order) to get the state.
       * 2. If the order moved to submitted state then display order details <end>
       * 3. If the order is in pending payment state and if the payments that need to be voided are voided successfully then do authorization
       *    (with the given payments) by calling submit order else redirect the order to pending payment state without authorizing.
       * */
      self.voidPaymentsInAmendment = function(pOrderId, success, failure) {
        var self = this;
        var deferredObjectPromise = self.getDeferredVoidPaymentResponse(pOrderId);
        deferredObjectPromise.done(function(responseSuccessObject){
          success(responseSuccessObject, pOrderId);
        });
        deferredObjectPromise.fail(function(failureObject) {
          failure(failureObject);
       });
      };
      /**
       * Method to invoke the void payments request in pending payment scenario
       *
       */
        self.getDeferredVoidPaymentResponse = function(pOrderId) {
          var self = this;
          var inputData = {};
          inputData[CCConstants.ORDER_ID] = pOrderId;
          inputData[CCConstants.PAYMENTS] = self.toBeVoidedPayments();
          var deferred = $.Deferred();

          ccRestClient.request(CCConstants.END_POINT_VOID_PAYMENTS, inputData,
              function(data) {
                deferred.resolve(data);
              },
              function(pError) {
                  deferred.reject(pError);
              });
          return deferred.promise();
        };

      /**
       * Utility method to clear the completed payments array (In edit order flow)
       * Currently being used only for Agent app
       */
      self.clearAuthorizedPayments = function(){
        var self = this;
        self.completedPayments.removeAll();
      };

      if (ccRestClient.profileType !== CCConstants.PROFILE_TYPE_AGENT) {
        $.Topic(pubsub.topicNames.PAGE_CHANGED).subscribe(self.updatePaymentGatewayData.bind(self));
      }
      return self;
    }

    /**
     * Return the single instance of PaymentsViewModel. Create it if it doesn't exist.
     * @function
     * @name PaymentsViewModel.getInstance
     * @param {Object} [data] Additional data.
     * @return {PaymentsViewModel} Singleton instance.
     */
    PaymentsViewModel.getInstance = function(data, pUserData) {
      if(!PaymentsViewModel.singleInstance) {
        PaymentsViewModel.singleInstance = new PaymentsViewModel();
      }
      if (data) {
        PaymentsViewModel.singleInstance.populatePaymentData(data, pUserData);
      }
      return PaymentsViewModel.singleInstance;
    };
    
    return PaymentsViewModel;
    
});
