define(
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  'viewModels/storeCreditContainer',
  
  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  ['knockout'],
  
  //-------------------------------------------------------------------
  // MODULE DEFINITION
  //-------------------------------------------------------------------
  function (ko) {
    'use strict';
    
    function StoreCreditContainer() {
      if(StoreCreditContainer.singleInstance) {
        throw new Error("Cannot instantiate more than one StoreCreditContainer, use getInstance(data)");  
      }
      var self = this;
      
      //List of all user stored credit balance
      self.userStoreCreditDetails = {};
      
      //Sum of all stored credits
      self.totalStoreCredits = ko.observable();
    };
    
    StoreCreditContainer.prototype.populateViewModelWithServerData = function(data) {
      var self = this;
      if (data && data.paymentResponses && data.paymentResponses.length > 0) {
        var totalStoredCredits = 0;
        if(data.paymentResponses[0].totalAvailableAmount && !isNaN(data.paymentResponses[0].totalAvailableAmount)) {
          totalStoredCredits = data.paymentResponses[0].totalAvailableAmount;
          self.userStoreCreditDetails = data.paymentResponses[0].storeCredits;
        }
        self.totalStoreCredits(totalStoredCredits);
      }     
    };
    
    /**
     * Return the single instance of StoreCreditContainer. Create it if it doesn't exist.
     * @function
     * @name StoreCreditContainer.getInstance
     * @param {Object} [data] Additional data.
     * @return {StoreCreditContainer} Singleton instance.
     */
    StoreCreditContainer.getInstance = function() {
      if(!StoreCreditContainer.singleInstance) {
        StoreCreditContainer.singleInstance = new StoreCreditContainer();
      }
      
      return StoreCreditContainer.singleInstance;
    };
    
    return StoreCreditContainer;
});
