define(
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  'viewModels/addressTypesDataContainer',
  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  ['knockout', 'ccConstants', 'ccRestClient'],
  //-------------------------------------------------------------------
  // MODULE DEFINITION
  //-------------------------------------------------------------------
  function (ko, CCConstants, ccRestClient) {
    'use strict';

    /**
     * Creates address types data container view model.
     * The Address Types Data Container is a singleton class.
     *
     * @public
     * @class Represents address types data holder.
     * @name AddressTypesDataContainer
     * @property availableAddressTypes stores list of addressTypes
     */
    function AddressTypesDataContainer() {
      if (AddressTypesDataContainer.singleInstance) {
        throw new Error("Cannot instantiate more than one AddressTypesDataContainer view model, use getInstance()");
      }
      var self = this;
      self.availableAddressTypes = ko.observableArray();
      self.getAddressTypeList();
    };

    /**
     * Function to get address types list
     */
    AddressTypesDataContainer.prototype.getAddressTypeList = function () {
      var self = this;
      var url = CCConstants.END_POINT_LIST_ADDRESS_TYPES;
      self.availableAddressTypes.removeAll();
      var data = {};
      ccRestClient.request(url, data, self.listAddressTypesSuccess.bind(self), self.listAddressTypesFailed.bind(self), CCConstants.END_POINT_LIST_ADDRESS_TYPES);

    };

    /**
     * This is success callback for listAddressType rest call
     * @param {Object}
     * pResult Result object
     */
    AddressTypesDataContainer.prototype.listAddressTypesSuccess = function (pResult) {

      var addressTypes = [];
      var self = this;
      var itemsLength = pResult.items.length;
      for (var i = 0; i < itemsLength; i++) {
        if (pResult.items[i].id) {
          //assign label as repository id if display name is null
          var typeLabel = pResult.items[i].displayName ? pResult.items[i].displayName : pResult.items[i].id;
          var typeValue = pResult.items[i].id;
          addressTypes.push({
            label: typeLabel,
            value: typeValue
          });
        }
      }
      self.availableAddressTypes(addressTypes);
    };

    /**
     * This is failure callback for listAddressType rest call
     * @param {Object}
     * pResult Result object
     */
    AddressTypesDataContainer.prototype.listAddressTypesFailed = function (pResult) {

    };

    /**
     * Return the singleton global instance of AddressTypesDataContainer view model.
     * Creates it if it doesn't already exist.
     *
     * @function
     * @name AddressTypesDataContainer.getInstance
     * @returns {AddressTypesDataContainer} The address types view model.
     */
    AddressTypesDataContainer.getInstance = function () {
      if (!AddressTypesDataContainer.singleInstance) {
        AddressTypesDataContainer.singleInstance = new AddressTypesDataContainer();
      }
      return AddressTypesDataContainer.singleInstance;
    };

    return AddressTypesDataContainer;
  });
