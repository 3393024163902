// Copyright (C) 1994, 2019, Oracle and/or its affiliates. All rights reserved.
/**
 * @fileoverview
 */
define(
  // -------------------------------------------------------------------
  // PACKAGE NAME
  // -------------------------------------------------------------------
  'pageLayout/infinity',
  // -------------------------------------------------------------------
  // DEPENDENCIES
  // -------------------------------------------------------------------
  [ 'jquery', 'knockout', 'pubsub', 'storageApi', 'CCi18n', 'ccLogger'],

    // -------------------------------------------------------------------
    // MODULE DEFINITION
    // -------------------------------------------------------------------
    function($, ko, pubsub, storageApi, CCi18n, log) {
    "use strict";

    //-------------------------------------------------------------------
    // INFINITY PARAMETERS
    //-------------------------------------------------------------------

    var PAGE_TITLE_PARAM = "wt.ti";
    var OCC_REFERRER = "occ.referrer";
    var ANALYTICS_READY_EVENT = "ORA_ANALYTICS_READY";
    var PAGE_URI_PARAM = "page-uri";
    var PAGE_URL_PARAM = "wt.es";
    var EXTERNAL_VISITOR_ID_PARAM = "wt.dcsvid";
    var FIRST_VISIT_COOKIES_DISABLED_PARAM = "wt.vt_f";
    var FIRST_PARTY_COOKIES_ENABLED_PARAM = "wt.ce";
    var CONTENT_GROUP_NAME_PARAM = "wt.cg_n";
    var CONTENT_SUBGROUP_NAME_PARAM = "wt.cg_s";
    var SCENARIO_STEP_NAME_PARAM = "wt.si_p";
    var PRODUCT_SKU_PARAM = "wt.pn_sku";
    var PRODUCT_ID_PARAM = "wt.pn_id";
    var PRODUCT_FAMILY_PARAM = "wt.pn_fa";
    var PRODUCT_MANUFACTURER_PARAM = "wt.pn_ma";
    var TRANSACTION_EVENT_PARAM = "wt.tx_e";
    var CART_ID_PARAM = "wt.tx_cartid";
    var QUANTITY_PARAM = "wt.tx_u";
    var TRANSACTION_SUBTOTAL_PARAM = "wt.tx_s";
    var TRANSACTION_CURRENCY_PARAM = "wt.z_currency";
    var INVOICE_NUMBER_PARAM = "wt.tx_i";
    var INVOICE_DATE_PARAM = "wt.tx_id";
    var INVOICE_TIME_PARAM = "wt.tx_it";
    var CONVERSION_NAME_PARAM = "wt.conv";
    var SEARCH_PHRASE_PARAM = "wt.oss";
    var SEARCH_RESULTS_PARAM = "wt.oss_r";
    var SELECTED_SEARCH_FACET_PARAM =  "wt.z_selectedSearchFacet";
    var CAMPAIGN_ID_PARAM = "wt.mc_id";
    var SHIPPING_COUNTRY_PARAM = "wt.z_country";
    var SHIPPING_STATE_PARAM = "wt.z_region";
    var SHIPPING_CITY_PARAM = "wt.z_city";
    var SHIPPING_ZIPCODE_PARAM = "wt.z_zip";
    var GATEWAY_NAME_PARAM = "wt.z_gatewayName";
    var GATEWAY_TRANSACTION_AMOUNT_PARAM = "wt.z_gatewayTransactionAmount";
    var SHIPPING_METHOD_PARAM = "wt.z_shippingMethod";
    var SHIPPING_CHARGES_PARAM = "wt.z_shippingCharges";
    var INFINITY_MUTATION = "OCC_MUTATION";
    var MARKETING_CAMPAIGN_ID_PARAM = "wt.z_campaignId";
    var MARKETING_CAMPAIGN_SOURCE_PARAM = "wt.z_campaignSource";

    /**
     * Constructor Function
     *
     * @name InfinityViewModel
     * @public
     * @class Subscription to different events which are to be tracked and sent to Infinity.
     */
    function InfinityViewModel(pUser, pCart, pConfirmation, pSite) {
      if (InfinityViewModel.singleInstance) {
        throw new Error(
         "Cannot instantiate more than one InfinityViewModel, use InfinityViewModel.getInstance()");
      }
      var self = this;
      self.user = pUser;
      self.cart = pCart;
      self.confirmation = pConfirmation;
      self.site = pSite;
      self.infinityLoaded = $.Deferred();

      self.configInfinity = function () {
        localStorage.setItem(OCC_REFERRER,document.referrer);
        window.ORA.analytics.addMutation(INFINITY_MUTATION,
          function(p) {
            p.params.payload[PAGE_URL_PARAM] = encodeURI(window.location).split('?')[0];
            p.params.payload[PAGE_TITLE_PARAM] = document.title;
            p.params.payload.dcsuri=window.location.pathname;
            p.params.payload.dcsref=localStorage.getItem(OCC_REFERRER);
          });
        self.infinityLoaded.resolve();
      };

      self.addSubscriptions();
      document.body.addEventListener(ANALYTICS_READY_EVENT, self.configInfinity, false);

      /**
       * Populates pDataMap based on the pProduct object
       *
       * @function
       * @name populateProductData
       * @params pProduct, pDataMap
       */
       self.populateProductData = function(pProduct, pDataMap) {

         pDataMap[PRODUCT_ID_PARAM] = pProduct.id;
         // sku
         pDataMap[PRODUCT_SKU_PARAM] = pProduct.childSKUs[0].repositoryId;
         // if salePrice exists send it, otherwise send listPrice
         var price;
         // verify first sku level prices then product prices
         if (pProduct.childSKUs[0].salePrice) {
           price = pProduct.childSKUs[0].salePrice;
         } else if (pProduct.childSKUs[0].listPrice) {
           price = pProduct.childSKUs[0].listPrice;
         } else if (pProduct.salePrice) {
           price = pProduct.salePrice;
         } else {
           price = pProduct.listPrice;
         }
         pDataMap[TRANSACTION_SUBTOTAL_PARAM] = price;
         pDataMap[TRANSACTION_CURRENCY_PARAM] = this.site().selectedPriceListGroup().currency.currencyCode;
         // product family mapped to product Type
         if (pProduct.type) {
           pDataMap[PRODUCT_FAMILY_PARAM] = pProduct.type;
         } else {
           pDataMap[PRODUCT_FAMILY_PARAM] = 'Basic';
         }
         // product manufacturer mapped to product brand
         if (pProduct.brand && pProduct.brand !== null && pProduct.brand !== 'null') {
           pDataMap[PRODUCT_MANUFACTURER_PARAM] = pProduct.brand;
         }
       }

      /**
       * Populates pDataMap based on pCampaigns array
       *
       * @param pCampaigns
       * @param pDataMap
       */
      self.addCampaignsData = function(pCampaigns, pDataMap) {
        var campaignIds = '';
        var campaignSources = '';
        for (var j = 0; j < pCampaigns.length; j++) {
          if (j === 0) {
            campaignIds = pCampaigns[j].campaignId;
            campaignSources = pCampaigns[j].source;
          } else {
            campaignIds = campaignIds + ';' + pCampaigns[j].campaignId;
            campaignSources = campaignSources + ';' + pCampaigns[j].source;
          }
        }
        if (campaignIds.length !== 0 && campaignSources.length !== 0) {
          pDataMap[MARKETING_CAMPAIGN_ID_PARAM] = campaignIds;
          pDataMap[MARKETING_CAMPAIGN_SOURCE_PARAM] = campaignSources;
        }
      }
    }

    /**
     * Returns a single global instance of InfinityViewModel
     * only if tag URL is present in clientConfigData. Creates
     * the instance if it does not already exist.
     *
     * @function InfinityViewModel
     * @params pUser, pCart, pConfirmation, pSite
     * @returns {InfinityViewModel} The single global instance.
     */
    InfinityViewModel.initialize = function(pUser, pCart, pConfirmation, pSite) {

      if (!InfinityViewModel.singleInstance) {
        // This will load odc.js from which in turn loads all dependencies from infinity
        if(pSite().extensionSiteSettings.InfinityTag.content && pSite().extensionSiteSettings.InfinityTag.content.url){
          require([pSite().extensionSiteSettings.InfinityTag.content.url]);
          InfinityViewModel.singleInstance = new InfinityViewModel(pUser, pCart, pConfirmation, pSite);
        }
      }
      return InfinityViewModel.singleInstance;
    };

    /**
     * Adds subscriptions to pubsubs in the viewmodel.
     *
     * @function
     * @name InfinityViewModel#addSubscriptions
     */
    InfinityViewModel.prototype.addSubscriptions = function(){

      var self = this;
      //EVENT SUBSCRIPTIONS
      $.Topic(pubsub.topicNames.PAGE_CHANGED).subscribe(self.mapPageEventData.bind(self));
      $.Topic(pubsub.topicNames.CART_ADD_SUCCESS).subscribe(self.mapCartAddEventData.bind(self));
      $.Topic(pubsub.topicNames.CART_REMOVE_SUCCESS).subscribe(function(){self.mapCartRemoveEventData(this)});
      $.Topic(pubsub.topicNames.CART_UPDATE_QUANTITY).subscribe(function(){self.mapCartUpdateQuantityEventData(this)});
      $.Topic(pubsub.topicNames.SEARCH_RESULTS_UPDATED).subscribe(function(message){self.mapSearchResultsEventData(this, message)});
      $.Topic(pubsub.topicNames.USER_AUTO_LOGIN_SUCCESSFUL).subscribe(self.mapRegistrationEventData.bind(self));
      $.Topic(pubsub.topicNames.USER_LOGIN_SUCCESSFUL).subscribe(self.mapLoginSuccessEventData.bind(self));
      $.Topic(pubsub.topicNames.USER_LOGIN_SUBMIT).subscribe(self.mapLoginSubmitEventData.bind(self));
      $.Topic(pubsub.topicNames.USER_LOGOUT_SUBMIT).subscribe(self.mapLogoutSubmitEventData.bind(self));
      $.Topic(pubsub.topicNames.USER_PROFILE_UPDATE_SUCCESSFUL).subscribe(self.mapProfileUpdateSuccessEventData.bind(self));
      $.Topic(pubsub.topicNames.USER_PROFILE_UPDATE_SUBMIT).subscribe(self.mapProfileUpdateSubmitEventData.bind(self));
      $.Topic(pubsub.topicNames.PRODUCT_VIEWED).subscribe(self.mapProductViewedEventData.bind(self));
      $.Topic(pubsub.topicNames.CHECKOUT_REGISTER_USER).subscribe(self.mapCheckoutRegisterEventData.bind(self));
      $.Topic(pubsub.topicNames.CHECKOUT_SAVE_SHIPPING_ADDRESS).subscribe(self.mapSaveShippingAddressEventData.bind(self));
      $.Topic(pubsub.topicNames.CHECKOUT_SHIPPING_METHOD).subscribe(function(){self.mapShippingMethodSelectedEventData(this)});
      $.Topic(pubsub.topicNames.PAYMENT_AUTH_SUCCESS).subscribe(self.mapPaymentCompleteEventData.bind(self));
      $.Topic(pubsub.topicNames.PAYMENT_AUTH_DECLINED).subscribe(self.mapPaymentDeclinedEventData.bind(self));
      $.Topic(pubsub.topicNames.COUPON_APPLY_SUCCESSFUL).subscribe(self.mapCouponAddEventData.bind(self));
      $.Topic(pubsub.topicNames.PRODUCT_ADDED_TO_PURCHASE_LIST_SUCCESS).subscribe(self.mapPurchaseListAddEventData.bind(self));
      $.Topic(pubsub.topicNames.ADD_TO_QUICK_ORDER).subscribe(self.mapQuickOrderAddEventData.bind(self));
      $.Topic(pubsub.topicNames.ORDER_SUBMISSION_SUCCESS).subscribe(self.mapOrderSubmissionSuccessEventData.bind(self));
      $.Topic(pubsub.topicNames.ORDER_SUBMISSION_FAIL).subscribe(self.mapOrderSubmissionFailEventData.bind(self));
      $.Topic(pubsub.topicNames.ORDER_COMPLETED).subscribe(self.mapOrderCompleteEventData.bind(self));
    };

    /**
     * Function to map product data related to cart addition
     * to Infinity parameters. It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapCartAddEventData
     * @param product
     */
    InfinityViewModel.prototype.mapCartAddEventData = function(product, campaigns) {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      try{
        if (self.user().loggedIn()) {
          // if logged in send  user id
          data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
        }
        // product add to cart
        data[TRANSACTION_EVENT_PARAM] = 'a';
        // content group mapped to parent category
        data[CONTENT_GROUP_NAME_PARAM] = "Cart";
        //step name
        data[SCENARIO_STEP_NAME_PARAM] = "Add to Cart";
        data[QUANTITY_PARAM] = product.orderQuantity;
        self.populateProductData(product, data);
        if (campaigns) {
          self.addCampaignsData(campaigns, data);
        }
        self.sendDataToInfinity(data);
      }catch (e) {
        log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
      }

    };

    /**
     * Function to map product data related to cart removal
     * to Infinity parameters. It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapCartRemoveEventData
     * @param object
     */
    InfinityViewModel.prototype.mapCartRemoveEventData = function(object) {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      try{
        if (self.user().loggedIn()) {
          // if logged send  user id
          data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
        }
        var product = object[0].cartItem.productData();
        data[QUANTITY_PARAM] = object[0].cartItem.quantity();
        self.populateProductData(product, data);
        // product remove from cart
        data[TRANSACTION_EVENT_PARAM] = 'r';
        // content group mapped to parent category
        data[CONTENT_GROUP_NAME_PARAM] = "Cart";
        //step name
        data[SCENARIO_STEP_NAME_PARAM] = "Remove from Cart";
        self.sendDataToInfinity(data);
      }catch (e){
        log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
      }

    };

   /**
     * Function to map product data related to cart update
     * to Infinity parameters. It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapCartUpdateQuantityEventData
     * @param product
     */
    InfinityViewModel.prototype.mapCartUpdateQuantityEventData = function(product){

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      try{
        if (self.user().loggedIn()) {
          // if logged send  user id
          data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
        }
        // product add
        data[TRANSACTION_EVENT_PARAM] = 'a';
        // content group mapped to parent category
        data[CONTENT_GROUP_NAME_PARAM] = "Cart";
        //step name
        data[SCENARIO_STEP_NAME_PARAM] = "Update Cart";
        var cartItem = this.cart().getCartItem(product.id, product.childSKUs[0].repositoryId, product.commerceItemId);
        // Return if this is a remove event, which has been handled in separate method
        if(!cartItem){
          return;
        }
        data[QUANTITY_PARAM] = cartItem.updatableQuantity();
        self.populateProductData(product, data);
        self.sendDataToInfinity(data);
      }catch (e) {
        log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
      }

    };

    /**
     * Function to maps user data related to registration
     * to Infinity parameters. It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapRegistrationEventData
     */
    InfinityViewModel.prototype.mapRegistrationEventData = function(){

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      if (self.user().loggedIn()) {
        // if logged send  user id
        data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
      }
      // content group mapped to "Register"
      data[CONTENT_GROUP_NAME_PARAM] = "User Profile";
      //step name
      data[SCENARIO_STEP_NAME_PARAM] = "Registered";
      //check if first party cookies are disabled
      if(storageApi.getInstance().getItem("GDPRCookieP13nConsentGranted") === "false"){
        data[FIRST_VISIT_COOKIES_DISABLED_PARAM] = 2;
        data[FIRST_PARTY_COOKIES_ENABLED_PARAM] = 0;
      }else {
        data[FIRST_VISIT_COOKIES_DISABLED_PARAM] = 1;
        data[FIRST_PARTY_COOKIES_ENABLED_PARAM] = 0;
      }

      self.sendDataToInfinity(data);
    };

    /**
     * Function to maps user data related to login
     * to Infinity parameters. It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapLoginSuccessEventData
     */
    InfinityViewModel.prototype.mapLoginSuccessEventData = function() {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];

      var self = this;

      if(self.user().loggedIn()) {
        // if logged send  user id
        data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
      }
      // content group mapped to "Login"
      data[CONTENT_GROUP_NAME_PARAM] = "User Profile";
      data[SCENARIO_STEP_NAME_PARAM] = "Logged In";
      //check if first party cookies are disabled
      if(storageApi.getInstance().getItem("GDPRCookieP13nConsentGranted") === "false"){
        data[FIRST_PARTY_COOKIES_ENABLED_PARAM] = 0;
      }else {
        data[FIRST_PARTY_COOKIES_ENABLED_PARAM] = 1;
      }

      self.sendDataToInfinity(data);
    };

   /**
     * Function to map user data related to login
     * to Infinity parameters. It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapLoginSubmitEventData
     */
    InfinityViewModel.prototype.mapLoginSubmitEventData = function() {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];

      var self = this;

      if (self.user().loggedIn()) {
        // if logged send  user id
        data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
      }
      // content group mapped to "Login"
      data[CONTENT_GROUP_NAME_PARAM] = "User Profile";
      data[SCENARIO_STEP_NAME_PARAM] = "Log In Submit";

      self.sendDataToInfinity(data);
    };

   /**
     * Function to map user data related to logout
     * to Infinity parameters. It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapLogoutSubmitEventData
     */
    InfinityViewModel.prototype.mapLogoutSubmitEventData = function() {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];

      var self = this;

      if (self.user().loggedIn()) {
        // if logged send  user id
        data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
      }
      // content group mapped to "Login"
      data[CONTENT_GROUP_NAME_PARAM] = "User Profile";
      data[SCENARIO_STEP_NAME_PARAM] = "User Logged Out";

      self.sendDataToInfinity(data);
    };

   /**
     * Function to map user data related to profile update
     * to Infinity parameters. It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapProfileUpdateSubmitEventData
     */
    InfinityViewModel.prototype.mapProfileUpdateSubmitEventData = function(){
      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      if (self.user().loggedIn()) {
        // if logged send  user id
        data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
      }

      // content group mapped to "Login"
      data[CONTENT_GROUP_NAME_PARAM] = "User Profile";
      data[SCENARIO_STEP_NAME_PARAM] = "Update Submit";

      self.sendDataToInfinity(data);
    };

   /**
     * Function to map user data related to profile update
     * to Infinity parameters. It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapProfileUpdateSuccessEventData
     */
    InfinityViewModel.prototype.mapProfileUpdateSuccessEventData = function(){
      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      if (self.user().loggedIn()) {
        // if logged send  user id
        data[EXTERNAL_VISITOR_ID_PARAM]=self.user().id();
      }

      // content group mapped to "Login"
      data[CONTENT_GROUP_NAME_PARAM] = "User Profile";
      data[SCENARIO_STEP_NAME_PARAM] = "Update Successful";

      self.sendDataToInfinity(data);
    };

   /**
     *Function to map user data related to checkout register
     *to Infinity parameters. It builds the data object which is sent
     *to sendDataToInfinity function.
     *@function
     *@name InfinityViewModel#mapCheckoutRegisterEventData
     */
    InfinityViewModel.prototype.mapCheckoutRegisterEventData = function(){
      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      if (self.user().loggedIn()) {
        // if logged send  user id
        data[EXTERNAL_VISITOR_ID_PARAM]=self.user().id();
      }

      // content group mapped to "Login"
      data[CONTENT_GROUP_NAME_PARAM] = "User Profile";
      data[SCENARIO_STEP_NAME_PARAM] = "Checkout Register";
      //check if first party cookies are disabled
      if(storageApi.getInstance().getItem("GDPRCookieP13nConsentGranted") === "false"){
        data[FIRST_PARTY_COOKIES_ENABLED_PARAM] = 0;
      }else {
        data[FIRST_PARTY_COOKIES_ENABLED_PARAM] = 1;
      }

      self.sendDataToInfinity(data);
    };

    /**
     * Function to maps product data related to product view
     * to Infinity parameters. It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapProductViewedEventData
     * @param product
     */
    InfinityViewModel.prototype.mapProductViewedEventData = function(product) {

      var data = {};
      data[PAGE_URI_PARAM]=encodeURI(window.location).split('?')[0];
      var self = this;

      try{
        if (self.user().loggedIn()) {
          //if logged send  user id
          data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
        }
        // product view
        data[TRANSACTION_EVENT_PARAM] = 'v';
        // content group mapped to parent category
        data[CONTENT_GROUP_NAME_PARAM] = "Product";
        data[SCENARIO_STEP_NAME_PARAM] = "Product Viewed";
        var productData = ko.mapping.toJS(product);
        self.populateProductData(productData, data);
        var prodSKUs = "";
        //send all the available skus
        for (var i = 0; i < productData.childSKUs.length; i++) {
          if (i === 0) {
            prodSKUs = productData.childSKUs[i].repositoryId;
          } else {
            prodSKUs = prodSKUs + ';' + productData.childSKUs[i].repositoryId;
          }
        }
        data[PRODUCT_SKU_PARAM] = prodSKUs;
        self.sendDataToInfinity(data);
      }catch (e) {
        log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
      }

    };

   /**
     * Function to map search data to Infinity parameters.
     * It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapSearchResultsEventData
     * @params searchResults, message
     */
    InfinityViewModel.prototype.mapSearchResultsEventData = function(searchResults, message) {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      try{
        if (self.user().loggedIn()) {
          // if logged send  user id
          data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
        }
        // content group mapped to parent category
        data[CONTENT_GROUP_NAME_PARAM] = "Search";
        data[SEARCH_PHRASE_PARAM] = message.requestor.searchText;
        data[SEARCH_RESULTS_PARAM] = searchResults.totalRecordsFound;
        if(searchResults.searchEventSummary.facetFilters && searchResults.searchEventSummary.facetFilters.length>0){
          var facet = searchResults.searchEventSummary.facetFilters[searchResults.searchEventSummary.facetFilters.length-1];
          data[SELECTED_SEARCH_FACET_PARAM] = facet.dimensionName + " - " + facet.spec;
        }
        self.sendDataToInfinity(data);
      }catch (e) {
        log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
      }

    };

    /**
     * Function to map coupon data to Infinity parameters.
     * It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapCouponAddEventData
     */
    InfinityViewModel.prototype.mapCouponAddEventData = function() {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      if (self.user().loggedIn()) {
        // if logged send  user id
        data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
      }

      // campaign id mapped to latest the coupon code applied
      data[CAMPAIGN_ID_PARAM] = self.cart().coupons()[(self.cart().coupons().length -1)].code();
      // content group mapped to "coupon"
      data[CONTENT_GROUP_NAME_PARAM] = "Coupon";

      self.sendDataToInfinity(data);
     };

    /**
     * Function to map shipping data to Infinity parameters.
     * It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapSaveShippingAddressEventData
     * @param addressObject
     */
    InfinityViewModel.prototype.mapSaveShippingAddressEventData = function(addressObject){

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      try{
        if (self.user().loggedIn()) {
          // if logged send  user id
          data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
        }
        // content group mapped to "Address"
        data[CONTENT_GROUP_NAME_PARAM] = "Address";
        var city = "";
        var country = "";
        var state = "";
        var postalCode = "";
        for (var i = 0; i < addressObject.shippingGroups.length; i++) {
          if (i === 0) {
            city = addressObject.shippingGroups[i].shippingAddress.city;
            country = addressObject.shippingGroups[i].shippingAddress.country;
            state = addressObject.shippingGroups[i].shippingAddress.state;
            postalCode = addressObject.shippingGroups[i].shippingAddress.postalCode;
          } else {
            city = city + ";" + addressObject.shippingGroups[i].shippingAddress.city;
            country = country + ";" + addressObject.shippingGroups[i].shippingAddress.country;
            state = state + ";" + addressObject.shippingGroups[i].shippingAddress.state;
            postalCode = postalCode + ";" + addressObject.shippingGroups[i].shippingAddress.postalCode;
          }
        }
        //custom params mapped to address
        data[SHIPPING_CITY_PARAM] = city;
        data[SHIPPING_COUNTRY_PARAM] = country;
        data[SHIPPING_ZIPCODE_PARAM] = postalCode;
        data[SHIPPING_STATE_PARAM] = state;
        self.sendDataToInfinity(data);
      }catch (e) {
        log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
      }

    };

    /**
     * Function to map payment data to Infinity parameters.
     * It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapPaymentCompleteEventData
     * @param paymentData
     */
    InfinityViewModel.prototype.mapPaymentCompleteEventData = function(paymentData) {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      try{
        if (self.user().loggedIn()) {
          // if logged send  user id
          data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
        }
        //step name
        data[SCENARIO_STEP_NAME_PARAM] = "Payment Success";
        data[CONTENT_GROUP_NAME_PARAM] = "Payment";
        data[CART_ID_PARAM] = paymentData[0].id;
        self.sendDataToInfinity(data);
      }catch (e) {
        log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
      }

    };

    /**
     * Function to map payment data to Infinity parameters.
     * It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapPaymentDeclinedEventData
     * @param paymentData
     */
    InfinityViewModel.prototype.mapPaymentDeclinedEventData = function(paymentData) {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      try{
        if (self.user().loggedIn()) {
          // if logged send  user id
          data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
        }
        //step name
        data[SCENARIO_STEP_NAME_PARAM] = "Payment Fail";
        data[CONTENT_GROUP_NAME_PARAM] = "Payment";
        data[CART_ID_PARAM] = paymentData[0].id;
        self.sendDataToInfinity(data);
      }catch (e) {
        log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
      }

    };

    /**
     * Function to map order data to Infinity parameters.
     * It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapOrderCompleteEventData
     */
    InfinityViewModel.prototype.mapOrderCompleteEventData = function() {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      if (self.user().loggedIn()) {
        // if logged send  user id
        data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
      }

      //step name
      data[SCENARIO_STEP_NAME_PARAM] = "Order Completed";
      data[CONTENT_GROUP_NAME_PARAM] = "Order";

      self.sendDataToInfinity(data);
    };

   /**
     * Function to map order data to Infinity parameters.
     * It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapOrderSubmissionFailEventData
     * @param orderData
     */
    InfinityViewModel.prototype.mapOrderSubmissionFailEventData = function(orderData) {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      if (self.user().loggedIn()) {
        // if logged send  user id
        data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
      }

      //step name
      data[SCENARIO_STEP_NAME_PARAM] = "Order Submission Fail";
      data[CONTENT_GROUP_NAME_PARAM] = "Order";

      self.sendDataToInfinity(data);
    };

   /**
     * Function to map order data to Infinity parameters.
     * It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapOrderSubmissionSuccessEventData
     * @param orderData
     */
    InfinityViewModel.prototype.mapOrderSubmissionSuccessEventData = function(orderData) {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      try{
        if (self.user().loggedIn()) {
          // if logged send  user id
          data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
        }
        //step name
        data[SCENARIO_STEP_NAME_PARAM] = "Order Submission Success";
        data[CONTENT_GROUP_NAME_PARAM] = "Order";

        var dt = new Date();
        var day = ("0" + dt.getDate()).slice(-2);
        var month = ("0" + (dt.getMonth() + 1)).slice(-2);
        var dateStr = month + "/" + day + "/" + dt.getFullYear();
        data[INVOICE_DATE_PARAM] = dateStr;

        var hour = ("0" + dt.getHours()).slice(-2);
        var minutes = ("0" + dt.getMinutes()).slice(-2);
        var seconds = ("0" + dt.getSeconds()).slice(-2);
        var timeStr = hour + ":" + minutes + ":" + seconds;
        data[INVOICE_TIME_PARAM] = timeStr;

        data[TRANSACTION_EVENT_PARAM] = 'p';
        data[CART_ID_PARAM] = orderData[0].id;
        data[INVOICE_NUMBER_PARAM] = orderData[0].uuid;
        data[CONVERSION_NAME_PARAM] = "purchase";
        if (orderData[0].items) {
          var prodSKUs = ''; //wt.pn_sku
          var prodUnits = '';//wt.tx_u
          var subTotal = ''; //wt.tx_s
          for (var i = 0; i < orderData[0].items.length; i++) {
            if (i === 0) {
              prodSKUs = orderData[0].items[i].catRefId;
              prodUnits = orderData[0].items[i].quantity;
              subTotal = orderData[0].items[i].rawTotalPrice;
            } else {
              prodSKUs = prodSKUs + ';' + orderData[0].items[i].catRefId;
              prodUnits = prodUnits + ';' + orderData[0].items[i].quantity;
              subTotal = subTotal + ';' + orderData[0].items[i].rawTotalPrice;
            }
          }
          data[PRODUCT_SKU_PARAM] = prodSKUs;
          data[QUANTITY_PARAM] = prodUnits;
          data[TRANSACTION_SUBTOTAL_PARAM] = subTotal;
        }
        data[TRANSACTION_CURRENCY_PARAM] = self.site().selectedPriceListGroup().currency.currencyCode;
        if (orderData[0].campaigns) {
          self.addCampaignsData(orderData[0].campaigns, data);
        }
        self.sendDataToInfinity(data);
      }catch (e) {
        log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
      }

    };

    /**
     * Function to map shipping method data to Infinity parameters.
     * It builds the data object which is sent
     * to sendDataToInfinity function.
     * @function
     * @name InfinityViewModel#mapShippingMethodSelectedEventData
     * @param shippingMethod
     */
    InfinityViewModel.prototype.mapShippingMethodSelectedEventData = function(shippingMethod) {

      var data = {};
      data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
      var self = this;

      if (self.user().loggedIn()) {
        // if logged send  user id
        data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
      }

      if (shippingMethod) {
        //step name
        data[SCENARIO_STEP_NAME_PARAM] = "Shipping Method Selected";
        data[CONTENT_GROUP_NAME_PARAM] = "Shipping Method";
        self.sendDataToInfinity(data);
      }
    };

   /**
    * Function to map purchase list data to Infinity parameters.
    * It builds the data object which is sent
    * to sendDataToInfinity function.
    * @function
    * @name InfinityViewModel#mapPurchaseListAddEventData
    * @param purchaseListData
    */
   InfinityViewModel.prototype.mapPurchaseListAddEventData = function(purchaseListData){

     var data = {};
     data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
     var self = this;

     try{
       if (self.user().loggedIn()) {
         // if logged send  user id
         data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
       }
       // product add to purchase list
       data[TRANSACTION_EVENT_PARAM] = 'w';
       // content group mapped to parent category
       data[CONTENT_GROUP_NAME_PARAM] = "Purchase List";
       //step name
       data[SCENARIO_STEP_NAME_PARAM] = "Add to Purchase List";
       var products = purchaseListData.items;
       var productIds = "", quantities = "", prices = "", types = "", brands = "";
       for(var i = 0; i < products.length; i++){
         if (i === 0) {
           productIds = products[i].catRefId;
           quantities = ((typeof products[i].quantityDesired === "function") ? products[i].quantityDesired() : products[i].quantityDesired);
           var price = "";
           if (products[i].childSKUs && products[i].childSKUs[0].salePrice) {
             price = products[i].childSKUs[0].salePrice;
           } else if (products[i].childSKUs && products[i].childSKUs[0].listPrice) {
             price = products[i].childSKUs[0].listPrice;
           } else if (products[i].salePrice) {
             price = products[i].salePrice;
           } else if (products[i].listPrice) {
             price = products[i].listPrice;
           }
           prices = price;
           if (products[i].type) {
             types = products[i].type;
           } else {
             types = 'Basic';
           }
           // product manufacturer mapped to product brand
           if (products[i].brand && products[i].brand !== null && products[i].brand !== 'null') {
             brands = products[i].brand;
           }
         } else {
           productIds = productIds + ";" + products[i].catRefId;
           quantities = ((typeof products[i].quantityDesired === "function") ? products[i].quantityDesired() : products[i].quantityDesired);
           var price = "";
           if (products[i].childSKUs && products[i].childSKUs[0].salePrice){
             price = products[i].childSKUs[0].salePrice;
           } else if (products[i].childSKUs && products[i].childSKUs[0].listPrice){
             price = products[i].childSKUs[0].listPrice;
           } else if (products[i].childSKUs && products[i].salePrice){
             price = products[i].salePrice;
           } else if (products[i].listPrice){
             price = products[i].listPrice;
           }
           prices = prices + ";" + price;
           if (products[i].type){
             types = types + ";" + products[i].type;
           } else{
             types = types + ";" + 'Basic';
           }
           // product manufacturer mapped to product brand
           if (products[i].brand && products[i].brand !== null && products[i].brand !== 'null'){
             brands = brands + ";" + products[i].brand;
           }
         }
         }
         // sku
         data[PRODUCT_SKU_PARAM] = productIds;
         data[QUANTITY_PARAM] = quantities;
         data[TRANSACTION_SUBTOTAL_PARAM] = prices;
         // product family mapped to product Type
         data[PRODUCT_FAMILY_PARAM] = types;
         // product manufacturer mapped to product brand
         data[PRODUCT_MANUFACTURER_PARAM] = brands;
         data[TRANSACTION_CURRENCY_PARAM] = self.site().selectedPriceListGroup().currency.currencyCode;
         self.sendDataToInfinity(data);

     }catch (e) {
       log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
     }

   };

   /**
    * Function to map quick order data to Infinity parameters.
    * It builds the data object which is sent
    * to sendDataToInfinity function.
    * @function
    * @name InfinityViewModel#mapQuickOrderAddEventData
    * @param product
    */
   InfinityViewModel.prototype.mapQuickOrderAddEventData = function(product){

     var data = {};
     data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];
     var self = this;

     try{
       if (self.user().loggedIn()) {
         // if logged send  user id
         data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
       }
       // product add to quick order
       data[TRANSACTION_EVENT_PARAM] = "q";
       // content group mapped to parent category
       data[CONTENT_GROUP_NAME_PARAM] = "Quick Order";
       //step name
       data[SCENARIO_STEP_NAME_PARAM] = "Add to Quick Order";
       data[QUANTITY_PARAM] = product.orderQuantity;
       self.populateProductData(product, data);
       self.sendDataToInfinity(data);
     }catch (e) {
       log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
     }

   };

   /**
    * Function to map page data to Infinity parameters.
    * It builds the data object which is sent
    * to sendDataToInfinity function.
    * @function
    * @name InfinityViewModel#mapPageEventData
    * @param eventData
    */
    InfinityViewModel.prototype.mapPageEventData = function(eventData){

       var data = {};
       var self = this;
       // encoded uri
       data[PAGE_URI_PARAM] = encodeURI(window.location).split('?')[0];

       try{
         // initialize content group as pageId
         data[CONTENT_GROUP_NAME_PARAM] = eventData.pageId;
         if (self.user().loggedIn()) {
           // if logged send  user id
           data[EXTERNAL_VISITOR_ID_PARAM] = self.user().id();
         }

         // if home page
         if (eventData.pageId === "home") {
           data[CONTENT_GROUP_NAME_PARAM] = "Home Page";
         }
         //if category page
         if (eventData.pageId === "category") {
           // set content-group to first level category Id and sub-group to second level

           data[CONTENT_GROUP_NAME_PARAM]=eventData.path.split('/')[0];
           var catLength = eventData.path.split('/').length;
           if(catLength > 3) {
             data[CONTENT_SUBGROUP_NAME_PARAM] = eventData.path.split('/')[catLength-1];
           }

         }
         // if searchresults
         if (eventData.pageId === "searchresults" || eventData.pageId === "noSearchResults") {
           // moved to specific method triggered via pubsub
           return;
         }
         // if product
         if (eventData.pageId === "product") {
           // moved to specific method triggered via pubsub
           return;
         }
         // if article
         if (eventData.pageId === "article") {
           data[CONTENT_GROUP_NAME_PARAM] = eventData.pageId;
         }
         // if cart
         if (eventData.pageId === "cart") {
           data[CONTENT_GROUP_NAME_PARAM] = eventData.pageId;
           // data["wt.si_n"]="shopping cart";
           // data["wt.si_x"]=3;
           // data[SCENARIO_STEP_NAME_PARAM]="Cart Page";
         }
         // if checkout
         if (eventData.pageId === "checkout") {
           data[CONTENT_GROUP_NAME_PARAM] = eventData.pageId;
           data[SCENARIO_STEP_NAME_PARAM] = "Checkout Page";
         }
         // if confirmation
         if (eventData.pageId === "confirmation") {
           var gatewayName = "";
           var transactionAmount = 0;
           // custom params to track payment data
           for(var i = 0; i < self.confirmation().payments.length; i++){
             if(i === 0){
               gatewayName =  self.confirmation().payments[i].gatewayName;
               transactionAmount =  self.confirmation().payments[i].amount;
             } else{
               gatewayName = gatewayName + ";" +  self.confirmation().payments[i].gatewayName;
               transactionAmount = transactionAmount + ";" +  self.confirmation().payments[i].amount;
             }
           }
           data[GATEWAY_NAME_PARAM] = gatewayName;
           data[GATEWAY_TRANSACTION_AMOUNT_PARAM] = transactionAmount;
           var shippingMethod = "";
           var shippingCost = "";
           for(var i =0; i < self.confirmation().shippingGroups.length; i++){
             if (i === 0){
               shippingMethod =  self.confirmation().shippingGroups[i].shippingMethod.value;
               shippingCost =  self.confirmation().shippingGroups[i].shippingMethod.cost;
             } else{
               shippingMethod = shippingMethod + ";" +  self.confirmation().shippingGroups[i].shippingMethod.value;
               shippingCost = shippingCost + ";" +  self.confirmation().shippingGroups[i].shippingMethod.cost;
             }
           }
           // custom parameters for shipping method
           data[SHIPPING_METHOD_PARAM] = shippingMethod;
           data[SHIPPING_CHARGES_PARAM] = shippingCost;
           data[TRANSACTION_CURRENCY_PARAM] = self.site().selectedPriceListGroup().currency.currencyCode;
         }
         self.sendDataToInfinity(data);
       }catch (e) {
         log.warn(CCi18n.t('ns.common:resources.publishableDataMissingText'), e);
       }

       localStorage.setItem(OCC_REFERRER,window.location.href);
    };

   /**
    * Sends data mapping to Infinity API using ORA.view
    * @function
    * @name InfinityViewModel#sendDataToInfinity
    * @param inputMap
    */
   InfinityViewModel.prototype.sendDataToInfinity = function(inputMap) {

     var payload = {};
     payload.data = inputMap;
     var self = this;
     self.infinityLoaded.done(function(){
       if(window.ORA && window.ORA.view){
         window.ORA.view(payload);
       }else{
         log.warn("Infinity Tag has not loaded.")
       }
     });
   };

    return InfinityViewModel;

  });

