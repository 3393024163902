define('ccMaintenanceConfiguration',
  [
    'knockout', 'ccConstants'
  ],
  function (ko, CCConstants) {

    "use strict";

    /**
     * Central location for maintenance configurations.
     *
     * @class CCMaintenanceConfiguration
     * @classdesc This class holds the configuration for maintenance-related endpoints.
     * @throws {Error} If more than one instance is created.
     */
    function CCMaintenanceConfiguration() {
      if (CCMaintenanceConfiguration.prototype.singleInstance) {
        throw new Error("Cannot instantiate more than one CCMaintenanceConfiguration. Use getInstance() method");
      }
      var self = this;

      /**
       * Registry of maintenance endpoints.
       * @type {Object}
       */
      self.maintenanceEndpointsRegistry = {
        "listMaintenancePages": {
          "useOptimisticLock": false,
          "method": "GET",
          "hasPathParams": false,
          "url": "/ccadminui/v1/maintenancePages",
          "authRequired": true,
          "localeHint": "assetLanguageRequired",
          "id": "listMaintenancePages"
        },
        "deleteMaintenancePage": {
          "useOptimisticLock": false,
          "method": "DELETE",
          "hasPathParams": true,
          "url": "/ccadminui/v1/maintenancePages/{}",
          "authRequired": true,
          "localeHint": "assetLanguageRequired",
          "id": "deleteMaintenancePage"
        },
        "uploadMaintenancePage": {
          "useOptimisticLock": false,
          "method": "POST",
          "hasPathParams": false,
          "url": "/ccadminui/v1/maintenancePages",
          "authRequired": true,
          "localeHint": "assetLanguageRequired",
          "id": "uploadMaintenancePage"
        },
        "getMaintenancePageMetadata": {
          "useOptimisticLock": false,
          "method": "GET",
          "hasPathParams": true,
          "url": "/ccadminui/v1/maintenancePages/{}",
          "authRequired": true,
          "localeHint": "assetLanguageRequired",
          "id": "getMaintenancePageMetadata"
        },
        "previewMaintenancePage": {
          "useOptimisticLock": false,
          "method": "GET",
          "hasPathParams": true,
          "url": "/ccadminui/v1/maintenancePages/{}",
          "authRequired": true,
          "localeHint": "assetLanguageRequired",
          "id": "previewMaintenancePage"
        },
        "downloadMaintenancePage": {
          "useOptimisticLock": false,
          "method": "GET",
          "hasPathParams": true,
          "url": "/ccadminui/v1/maintenancePages/{}",
          "authRequired": true,
          "localeHint": "assetLanguageRequired",
          "id": "downloadMaintenancePage"
        },
        "enableMaintenancePage": {
          "useOptimisticLock": false,
          "method": "POST",
          "hasPathParams": true,
          "url": "/ccadminui/v1/maintenancePages/{}/enable",
          "authRequired": true,
          "localeHint": "assetLanguageRequired",
          "id": "enableMaintenancePage"
        },
        "getMaintenancePageStatus": {
          "useOptimisticLock": false,
          "method": "GET",
          "hasPathParams": true,
          "url": "/ccadminui/v1/maintenancePages/operationStatus/{}",
          "authRequired": true,
          "localeHint": "assetLanguageRequired",
          "id": "getMaintenancePageStatus"
        },
        "disableMaintenancePage": {
          "useOptimisticLock": false,
          "method": "POST",
          "hasPathParams": true,
          "url": "/ccadminui/v1/maintenancePages/{}/disable",
          "authRequired": true,
          "localeHint": "assetLanguageRequired",
          "id": "disableMaintenancePage"
        }
      };

      /**
       * Checks if the given endpoint ID is a maintenance endpoint.
       *
       * @param {string} endpointId - The ID of the endpoint to check.
       * @returns {boolean} True if the endpoint is a maintenance endpoint, false otherwise.
       */
      self.isMaintenanceEndpoint = function (endpointId) {
        var maintenancePageEndpointsList = [
          CCConstants.ENDPOINT_UPLOAD_MAINTENANCE_PAGE,
          CCConstants.ENDPOINT_LIST_MAINTENANCE_PAGES,
          CCConstants.ENDPOINT_DELETE_MAINTENANCE_PAGE,
          CCConstants.ENDPOINT_GET_MAINTENANCE_PAGE_METADATA,
          CCConstants.ENDPOINT_DOWNLOAD_MAINTENANCE_PAGE,
          CCConstants.ENDPOINT_PREVIEW_MAINTENANCE_PAGE,
          CCConstants.ENDPOINT_ENABLE_MAINTENANCE_PAGE,
          CCConstants.ENDPOINT_DISABLE_MAINTENANCE_PAGE,
          CCConstants.ENDPOINT_GET_MAINTENANCE_PAGE_STATUS
        ];
        return maintenancePageEndpointsList.includes(endpointId);
      };
    }

    /**
     * Gets the singleton instance of CCMaintenanceConfiguration.
     *
     * @returns {CCMaintenanceConfiguration} The singleton instance.
     */
    CCMaintenanceConfiguration.getInstance = function () {
      if (!CCMaintenanceConfiguration.singleInstance) {
        CCMaintenanceConfiguration.singleInstance = new CCMaintenanceConfiguration();
      }
      return CCMaintenanceConfiguration.singleInstance;
    };

    return CCMaintenanceConfiguration;

  });

