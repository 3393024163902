/**
 * @fileoverview The require.js main file for the storefront
 */
/*jslint plusplus: true */
require.config({

  /**
   * Base URL for JavaScript references.
   * @ignore
   */
  baseUrl: 'js',

  /**
    * Packages for CommonJS modules.
    * http://requirejs.org/docs/api.html#packages
    * @ignore
    */
  packages: [
    "packageAce",
    {
      name: "packageAce",
      location: "/shared/js/libs/ace",
      main: "ace"
    }
  ],

  /**
   * Shim for any modules with dependencies that we need to explicitly define.
   * @ignore
   */
  shim: {
    'jqueryui': {
      deps: ['jquery']
    },

    'jquerymobile': {
      deps: ['jquery']
    },

    'jquery': {
      deps: [],
      exports: '$'
    },

    'trafficCop': {
      deps: ['jquery']
    },

    'infuser': {
      deps: ['jquery', 'trafficCop']
    },

    'spectrumColorPicker': {
      deps: ['jquery']
    },

    'bootstrapDatePicker': {
      deps: ['jquery']
    },

    'bootstrapTimePicker': {
      deps: ['jquery']
    },

    'bootstrap': {
      deps: ['jquery']
    },

    'bstypeahead': {
      deps: ['jquery']
    },

    'chosen': {
      deps: ['jquery']
    },

    'imagesloaded': {
      deps: ['jquery']
    },
    'facebook' : {
      exports: 'FB'
    }
  },

  /**
   * paths to resolve the reference names names on the left will be used for
   * requires/depends parameters.
   * @ignore
   */
  paths : {
    // 3rd party libs
    // jquery
    jqueryui : '/shared/js/libs/jquery-ui-1.13.2.custom.min',
    jquerymswipe : '/shared/js/libs/jquery.mobile.swipe-1.4.5.min',
    i18next : '/shared/js/libs/i18next-21.3.2.min',
    i18nextBackend: '/shared/js/libs/i18nextHttpBackend-1.3.1',
    chosen : '/shared/js/libs/chosen.jquery-1.8.7.min',

    // knockout, mapping, and templates
    koMapping : '/shared/js/libs/knockout.mapping-2.4.1.min',
    koValidate : '/shared/js/libs/knockout.validation-2.0.3',
    trafficCop: '/shared/js/libs/TrafficCop-modified',
    infuser: '/shared/js/libs/infuser',

    // bootstrap for most UI controls
    bootstrap : '/shared/js/libs/bootstrap.3.1.1',
    bootstrapDatePicker: '/shared/js/libs/bootstrap-datepicker',
    bootstrapDatePickerLocales: '/shared/js/libs/locales',
    bootstrapTimePicker: '/shared/js/libs/bootstrap-timepicker',
    bootstrapSlider: '/shared/js/libs/bootstrap-slider',
    spectrumColorPicker: '/shared/js/libs/spectrum-1.8.0',
    bstypeahead: '/shared/js/libs/bootstrap3-typeahead',

    // crossroads, etc,. for routing
    crossroads : '/shared/js/libs/crossroads.min',
    hasher : '/shared/js/libs/hasher-1.2.0',
    ccURLPatterns: '/shared/js/ccLibs/cc-url-patterns',

    // Moment library for date and time formatting
    moment : '/shared/js/libs/moment-2.29.4',
    // Moment language bundles are stored in this path
    momentLangs : '/shared/js/libs/moment',

    // Oracle-CC libs
    ccStoreConfiguration : '/shared/js/ccLibs/cc-store-configuration-1.0',
    profiletools: '/shared/js/ccLibs/profile-tools-1.0',
    ccConstants : '/shared/js/ccLibs/cc-constants',
    koExtensions : '/shared/js/ccLibs/ko-extensions',
    storageApi : '/shared/js/ccLibs/cc-storage-api-1.0',
    ccOAuthTimeout : '/shared/js/ccLibs/cc-oauth-timeout',
    ccRestClientConstructor : '/shared/js/ccLibs/cc-rest-client-1.0',
    ccRestClient : '/shared/js/ccLibs/storefront-rest-client',
    addThis : '/shared/js/ccLibs/addThis',
    ociApm: '/shared/js/ccLibs/ociApm',
    koExternalTemplate: '/shared/js/ccLibs/koExternalTemplateEngine-amd-2.0.5-modified',
    ccKoExtensions: '/shared/js/ccLibs/cc-ko-extensions',
    ccKoOjExtensions: '/shared/js/ccLibs/cc-ko-oj-extensions',
    ccKoErrorWrapper: '/shared/js/ccLibs/cc-ko-error-wrapper',
    xDomainProxy: '/shared/js/ccLibs/xdomain-proxy',
    pubsub: '/shared/js/ccLibs/pubsub-1.0',
    pubsubImpl: '/shared/js/ccLibs/pubsub-impl',
    routing: '/shared/js/ccLibs/routing-3.0',
    notifier: '/shared/js/ccLibs/notifier-1.0',
    notifications: '/shared/js/ccLibs/notifications-1.0',
    storeKoExtensions: '/shared/js/ccLibs/store-ko-extensions',
    ccLogger: '/shared/js/ccLibs/ccLogger-1.0',
    CCi18n : '/shared/js/ccLibs/cc-i18n',
    ccKoValidateRules: '/shared/js/ccLibs/cc-ko-validate-rules',
    paginated: '/shared/js/ccLibs/paginated',
    ccPaginated: '/shared/js/ccLibs/cc-paginated',
    spinner: '/shared/js/ccLibs/spinner-1.0',
    placeholderPatch : '/shared/js/ccLibs/cc-placeholder',
    imagesloaded : '/shared/js/libs/imagesloaded.pkgd-4.1.4',
    ccDate : '/shared/js/ccLibs/cc-date-format-1.0',
    ccNumber : '/shared/js/ccLibs/cc-number-format-1.0',
    ccPasswordValidator : '/shared/js/ccLibs/cc-password-validator',
    swmRestClientConstructor: '/shared/js/ccLibs/swm-rest-client-1.0',
    swmRestClient: '/shared/js/ccLibs/swm-storefront-rest-client',
    swmKoValidateRules: '/shared/js/ccLibs/swm-ko-validate-rules',
    ccResourceLoader: '/shared/js/ccLibs/cc-resource-loader',
    navigation : '/shared/js/ccLibs/cc-navigation-1.0',
    ccNavState : '/shared/js/ccLibs/cc-nav-state',
    facebook: '/shared/js/ccLibs/load-facebookjs-error-handler-1.0',
    facebookjs : '/shared/js/ccLibs/facebook_sdk',
    pinitjs : '/shared/js/ccLibs/pinit_sdk',
    imageZoom : '/shared/js/ccLibs/image-zoom',
    ccImageZoom : '/shared/js/ccLibs/cc-image-zoom-2.0',
    pageViewTracker : '/shared/js/ccLibs/pageViewTracker-1.0',
    currencyHelper : '/shared/js/ccLibs/currency-helper',
    profileHelper: '/shared/js/ccLibs/profile-helper',
    ccEETagProcessor : '/shared/js/ccLibs/cc-ee-tag-processor-1.0',
    viewportHelper : '/shared/js/ccLibs/viewport-helper',
    parentOrganisation: '/shared/js/ccLibs/parentOrganisation',
    ccClientErrorCodes: '/shared/js/ccLibs/cc-client-error-codes',
    sfExceptionHandler : '/shared/js/ccLibs/sf-exception-handler',
    ccStoreServerLogger : '/shared/js/ccLibs/cc-store-server-logger-1.0',
    ccStoreUtils : '/shared/js/ccLibs/cc-store-utils-1.0',
    dateTimeUtils : '/shared/js/ccLibs/date-time-utils',
    numberFormatHelper : '/shared/js/ccLibs/number-format-helper',
    ccStoreConfiguration : '/shared/js/ccLibs/cc-store-configuration-1.0',
    ccLazyImages : '/shared/js/ccLibs/cc-lazy-images',

    // OracleJET v7.3.0
    hammerjs: '/js/oraclejet/js/libs/hammer/hammer-2.0.8.min',
    jquery: '/js/oraclejet/js/libs/jquery/jquery-3.5.1.min',
    'jqueryui-amd': '/js/oraclejet/js/libs/jquery/jqueryui-amd-1.12.1.min',
    knockout: '/js/oraclejet/js/libs/knockout/knockout-3.5.0',
    ojdnd: '/js/oraclejet/js/libs/dnd-polyfill/dnd-polyfill-1.0.0.min',
    ojs: '/js/oraclejet/js/libs/oj/v7.3.0/min',
    ojL10n: '/js/oraclejet/js/libs/oj/v7.3.0/ojL10n',
    ojtranslations: '/js/oraclejet/js/libs/oj/v7.3.0/resources',
    ojswitch: '/js/oraclejet/js/libs/oj/v7.3.0/min/ojswitch',
    promise: '/js/oraclejet/js/libs/es6-promise/es6-promise.min',
    signals: '/js/oraclejet/js/libs/js-signals/signals.min',
    template: '/js/oraclejet/js/libs/require/text',
    customElements: '/js/oraclejet/js/libs/webcomponents/custom-elements.min',

    // Dynamic paths to make naming/referencing modules easier
    pageLayout: '/shared/js/pageLayout',
    viewModels: '/shared/js/viewModels',
    shared: '/shared/js'
  },

  // wait 45 seconds before giving up loading a script
  // extended from 7 (default) to permit all dependencies to load
  waitSeconds: 45
});

// Allow SiteStudio to configure paths as required
var paths = { layoutContainer : 'pageLayout/layout-container' };

if(typeof(configurePaths) === 'function') {
  configurePaths(paths);
}

if (typeof container === 'undefined') {
  var container;
}

var requiredDependencies = [];
if(window.storeLibsPath){
  requiredDependencies.push(window.storeLibsPath);
}
else{
  requiredDependencies.push('shared/store-libs'); 
}


//Conditionally loading the unused OOTB store bindings to maintain backward compatibility
if(window.clientConfigData && window.clientConfigData.loadOjDependentBindings=='true'){
  if(window.ccKoOjExtensionsPath){
    requiredDependencies.push(window.ccKoOjExtensionsPath);
  }
  else{
    requiredDependencies.push('ccKoOjExtensions'); 
  }
}


require(requiredDependencies, function() {
  require(
    ['jquery',
     'ccConstants',
     'spinner',
     'pubsub',
     'profiletools',
     'knockout',
     'koValidate',
     'ccKoValidateRules',
     paths.layoutContainer,
     'pageLayout/rest-adapter',
     'storageApi',
     'pageLayout/api-builder',
     'routing',
     'CCi18n',
     'ccDate',
     'ccEETagProcessor',
     'ccRestClient',
     'addThis',
     'ccOAuthTimeout',
     'viewportHelper',
     'shared/store-loader',
     'ccResourceLoader!APPLICATION_JS',
     'jquerymswipe',
     'ccLazyImages'],

    function ($, CCConstants, Spinner, PubSub, ProfileTools, ko, koValidate,
               rules, LayoutContainer, MyRestAdapter, storageApi, APIBuilder, Routing, CCi18n, ccDate,
               ccEETagProcessor, ccRestClient, AddThis, CCOAuthTimeout, viewportHelper, jquerymswipe,
               CCLazyImages) {
      "use strict";

      // Check to see if we are in the Design Studio preview
      if (container === CCConstants.CONTAINER_DESIGN_STUDIO) {
        // We are in the Design Studio preview so set up the loading spinner
        var spinnerVisible = false;
        var pageReady = false;

        // Event fired when page is changed
        $.Topic(PubSub.topicNames.PAGE_CHANGED).subscribe(function() {
          if (!spinnerVisible && !pageReady) {
            Spinner.createWithTimeout({
              parent: 'body',
              posTop: '200px'
            }, 10000,
            function() {
              $('.modal-backdrop').remove();
            });

            $('.cc-spinner').css('z-index', '20000');

            // Signal that the spinner must be removed when the page is loaded
            spinnerVisible = true;
          }

          // Reset pageReady to initial state
          pageReady = false;
        });

        // Event fired when new page is ready
        $.Topic(PubSub.topicNames.PAGE_READY).subscribe(function() {
          if (spinnerVisible) {
            Spinner.destroyWithoutDelay('body');

            // Reset spinnerVisible and pageReady to initial state
            spinnerVisible = false;
            pageReady = false;
          }
          else {
            // Signal that page is ready and there is no need to show the spinner
            pageReady = true;
          }
        });
        // Make sure that the EE libs do not work in DS
        ccEETagProcessor.enabled = false;
      }

      //---------------------------------------------------------------
      // Check if this is a SAML/OPEN ID Connect login
      //---------------------------------------------------------------
      function processSamlResponse(rawSamlResponse, pRelayState) {
        var data;
        ccRestClient.samlLogin(rawSamlResponse, handleSamlLoginSuccess, handleSamlLoginError, pRelayState);
      }

      function handleSamlLoginSuccess(pResult) {
      $.Topic(PubSub.topicNames.SAML_LOGIN_SUCCESSFUL).publish();
      }

      function handleSamlLoginError(pResult) {
      $.Topic(PubSub.topicNames.SAML_LOGIN_FAILURE).publishWith(self, [pResult]);
      }

      function handleOpenIdLoginSuccess(pResult) {
        $.Topic(PubSub.topicNames.OPENID_LOGIN_SUCCESSFUL).publish();
      }

      function handleOpenIdLoginError(pResult) {
        $.Topic(PubSub.topicNames.OPENID_LOGIN_FAILURE).publishWith(self, [pResult]);
      }

      function processSamlResponseCallback() {
        $.Topic(PubSub.topicNames.CART_LOADED).unsubscribe(processSamlResponseCallback);
        processSamlResponse(getSamlResponse(), getRelayState());
      }

      function processOpenIdResponse(pCode) {
        ccRestClient.openIdLogin(pCode, handleOpenIdLoginSuccess, handleOpenIdLoginError);
      }

      function processOpenIdResponseCallback() {
        $.Topic(PubSub.topicNames.CART_LOADED).unsubscribe(processOpenIdResponseCallback);
        processOpenIdResponse(getOpenIdCode());
      }

      var authCallback;
      if (typeof getSamlResponse === 'function' && getSamlResponse() != null && getSamlResponse().length > 0) {
        authCallback = processSamlResponseCallback;
      } else if (typeof getOpenIdCode === 'function' && getOpenIdCode() != null && getOpenIdCode().length > 0) {
        authCallback = processOpenIdResponseCallback;
      }

      if (typeof authCallback !== 'undefined') {
        if (authCallback === processOpenIdResponseCallback) {
          storageApi.getInstance().saveToMemory(CCConstants.CC_IS_OPENID_LOGIN_UNDER_PROGRESS, true);
        } else {
          storageApi.getInstance().saveToMemory(CCConstants.CC_IS_SSO_LOGIN_UNDER_PROGRESS, true);
        } 
        var path = ccRestClient.getSessionStoredValue("SSO_RETURN_URL");
        if (path == null) {
          path = "/";
        }
        window.history.replaceState(null, "", path);
        $.Topic(PubSub.topicNames.CART_LOADED).subscribe(authCallback);
      }

      function processAgentAuthToken () {
        ccRestClient.storeToken(getAgentAuthToken());
        ccRestClient.setStoredValue("cc-agent-redirect-url", getAgentRedirectUrl());
        var agentOrgId = getAgentOrganizationIdValue();
        var agentPriceListValue = getAgentPriceListGroupValue();
        ccRestClient.setStoredValue("occs_organization_id", agentOrgId ? JSON.stringify(agentOrgId): null);
        ccRestClient.setStoredValue("occs_pricelistgroup_id", agentPriceListValue ? JSON.stringify(agentPriceListValue): null);
        window.location.assign(window.location.href);
      }
      
      if (typeof getAgentAuthToken === 'function' && getAgentAuthToken() != null && getAgentAuthToken().length > 0) {
          ccRestClient.registerInitCallback(processAgentAuthToken);
      }

      var oauthToken = null;
      if (typeof getOAuthToken === 'function' && getOAuthToken() != null && getOAuthToken().length > 0) {
        oauthToken = getOAuthToken();
      }
      function processPunchoutRequest () {
        // storeToken() call may call refresh to ensure
        // that the passed in token is persisted via cookie, so wait until
        // that completes before switching the window location
        var storeTokenCallback = function() {
          var additionalFormData = getAdditionalFormData();
          if(additionalFormData){
            ccRestClient.setStoredValue("cc-additional-form-data",JSON.stringify(additionalFormData));
          }
          window.location.assign("/");
        };
        ccRestClient.storeToken(oauthToken, storeTokenCallback);
      }
      
      if (oauthToken != null) {
        ccRestClient.registerInitCallback(processPunchoutRequest);
      }
      

      //---------------------------------------------------------------
      // CONFIGURE VIEW MODEL PATHS
      //---------------------------------------------------------------
      var adapter = new MyRestAdapter('/ccstore/v1/'),
          basePath = "/",
          layoutContainer = new LayoutContainer(adapter,basePath),
          masterViewModel = new layoutContainer.LayoutViewModel(),
          routing = new Routing(),
          ccOAuthTimeout = new CCOAuthTimeout(ccRestClient);

      layoutContainer.masterViewModel(masterViewModel);
      
      // Initialize the EE tag library
      ccEETagProcessor.init();

      // Change the default template suffix for storefront
      infuser.defaults.templateSuffix = '';
      // Configuration Options for Validation Plugin
      // See https://github.com/ericmbarnard/Knockout-Validation/wiki/Configuration
      var options = {};
      options.errorsAsTitle = false;
      options.insertMessages = false;
      options.decorateElement = true;
      options.errorElementClass = "invalid";

      // Initialize Validation Plugin
      ko.validation.init(options);

      // set the i18next and load the common name space
      var i18nOptions = {
        compatibilityJSON: 'v1',
        compatibilityAPI: 'v1',
		appendNamespaceToMissingKey:true,
        returnNull: true,
        returnEmptyString: true,
        joinArrays : '\n',
        namespaces : ['ns.common','ns.ccformats'],
        ns : ['ns.common','ns.ccformats'],
        defaultNS : ['ns.common'],
        fallbackLng : ['en'],
        useLocalStorage : false,
        useCookie : false,
        debug : false,
        contextSeparator: ":",
        resGetPath: '/ccstoreui/v1/resources/__ns__?locale=__lng__',
        interpolation: {
            prefix:'__',
            suffix:'__',
            unescapeSuffix : 'HTML',
            escapeValue : false,
            nestingPrefix : '$t(',
            nestingSuffix : ')'
        },
        backend: {
          request: CCi18n.ajax,
          loadPath: '/ccstoreui/v1/resources/__ns__?locale=__lng__',
          addPath: '/ccstoreui/v1/resources/__ns__?locale=__lng__'
        }
      };

      // initialise i18next with the options at some point in the future
      // after we know the correct locale. Otherwise we'll end up
      // loading resource bundles twice. Once at init and once again
      // at setLocale time. Better to just wait and do the init
      // when we know what the locale is (this comes from the server)

      CCi18n.deferInit(i18nOptions, function() {

        // Initialize preview message
        masterViewModel.previewMessage(
          CCi18n.t('ns.common:resources.previewMessage'));
        masterViewModel.oboShopperName(
          CCi18n.t('ns.common:resources.fullName', {'firstName': masterViewModel.data.global.user.firstName,'lastName': masterViewModel.data.global.user.lastName}));
        masterViewModel.sharedWidgetMessage(
          CCi18n.t('ns.common:resources.sharedWidget'));
        masterViewModel.oboShopperMessage(CCi18n.t('ns.common:resources.shoppingAsText'));
        masterViewModel.displayErrorMessage(
          CCi18n.t('ns.common:resources.displayError'));
        if (masterViewModel.pageChangeMessage() == "") {
          if (masterViewModel.dataForPageChangeMessage().page.category) {
               masterViewModel.pageChangeMessage(CCi18n.t(
                 'ns.common:resources.categoryPageLoadedText',
                  {
                     collection: masterViewModel.dataForPageChangeMessage().page.category.displayName
                  }));
          } else if (masterViewModel.dataForPageChangeMessage().page.product) {
              masterViewModel.pageChangeMessage(CCi18n.t(
                'ns.common:resources.productPageLoadedText',
                {
                  product: masterViewModel.dataForPageChangeMessage().page.product.displayName
                }));
          } else if (masterViewModel.dataForPageChangeMessage().page.repositoryId === "userSpacesPage") {
              masterViewModel.pageChangeMessage(CCi18n.t('ns.common:resources.wishlistPageLoadedText'));
          }  else if (masterViewModel.dataForPageChangeMessage().repositoryId != "searchResultsPage") {
               masterViewModel.pageChangeMessage(CCi18n.t(
                 'ns.common:resources.pageLoadedText',
                 {
                    page: masterViewModel.dataForPageChangeMessage().page.repositoryId
                 }));
          }
        }
      });


      /**
       * Returns true if the given regions refer to widgets with different typeIds.
       * This is *not* an object comparison, it's only comparing the typeId property
       * of the widget contained in each region.
       **/
      function regionsDiffer(regionA,regionB) {
        if (!regionA || !regionB) return true;

        if(regionA.widgets()) {
          if(regionB.widgets()) {
            if(regionA.widgets().length !== regionB.widgets().length) {
              return true;
            }

            if(regionA.width() !== regionB.width()) {
              return true;
            }

            for(var i = 0; i < regionA.widgets().length; i++) {
              if(ko.utils.unwrapObservable(regionA.widgets()[i].id) !== ko.utils.unwrapObservable(regionB.widgets()[i].id)) {
                return true;
              }
            }

            return false;
          }
          return true;
        }

        if(regionB.widgets()) {
          return true;
        }

        return false;
      }

      /**
       * Returns the index of the given region in the given list of regions
       */
      function findRegion(region,regionsList) {
        var result = -1;
        for (var i = 0; i < regionsList.length; i++) {
          if (!regionsDiffer(region,regionsList[i])) {
            result = i;
            break;
          }
        }
        return result;
      }

      var host = window.location.protocol + "//" + window.location.host;
      /**
       * Updates observables containing SEO rel tags data.
       *
       * @param {args} server JSON response
       * @param {data} PAGE_LAYOUT_UPDATED event data
       */
      var handleLayoutUpdate = function(args, data) {
        var route;

        // the server decides which pages should contain the canonical tag
        // locale and potential context root returned from server
        if (args.canonicalRoute) {
          var canonicalRoute = args.canonicalRoute;
          
          if (ko.isObservable(canonicalRoute)){
        	  	canonicalRoute  = ko.utils.unwrapObservable(canonicalRoute)
          }

          route = host + canonicalRoute;
        }

        masterViewModel.canonicalRoute(route);
        masterViewModel.agentReturnUrl = ccRestClient.getStoredValue("cc-agent-redirect-url");
        masterViewModel.alternates(args.alternates);
        // the pagination values are calculated in ProductListingViewModel
        // but need to be cleared here for pages without pagination
        masterViewModel.nextPageNo(null);
        masterViewModel.prevPageNo(null);
        masterViewModel.currPageNo('');
        masterViewModel.paginationDone(false);
        masterViewModel.pageType = args.data.page.pageType;
        masterViewModel.isObo(masterViewModel.data.global.agentId != null);
        
      };
      
      /**
       * Updates observables containing SEO pagination data.
       *
       * @param {data} PAGE_PAGINATION_CALCULATED event data
       */
      var handlePaginationCalculated = function(data) {
        masterViewModel.nextPageNo(data.nextPageNo);
        masterViewModel.prevPageNo(data.prevPageNo);
        masterViewModel.currPageNo(data.currPageNo);

        if (masterViewModel.paginationDone() !== true) {
          masterViewModel.paginationDone(true);
        } else {
          masterViewModel.paginationDone.valueHasMutated();
        }
      };

      // Updates the master view model when we new layout has been loaded
      // Favors keeping references to the same RegionViewModel
      // object when possible (ex: header and footer on all pages)
      // so that the knckout foreach binding can detect and
      // optimize to avoid redrawing the entire page
      var updateMetadata = function(args) {
        masterViewModel.title(args.title());
        masterViewModel.keywords(args.keywords());
        masterViewModel.description(args.description());
        masterViewModel.metaTags(args.metaTags());
        masterViewModel.isPreview(args.isPreview());
        masterViewModel.noindex(args.noindex());
        viewportHelper.layoutViewports(args.viewports ? args.viewports() : "");
      };

      $.Topic(PubSub.topicNames.PAGE_LAYOUT_UPDATED).subscribe(handleLayoutUpdate);
      $.Topic(PubSub.topicNames.PAGE_PAGINATION_CALCULATED).subscribe(handlePaginationCalculated);
      $.Topic(PubSub.topicNames.PAGE_METADATA_CHANGED).subscribe(updateMetadata);

      masterViewModel.name = ko.observable('Master View Model');

      masterViewModel.itemBeforeRemove = function (dom,index,removed) {
        //$(dom).fadeOut(500, function() { $(dom).remove(); });
        $(dom).remove();
      };

      masterViewModel.itemAfterAdd = function (dom, index, added) {
        // $(dom).fadeIn();
      };

      masterViewModel.afterRender = function (inserted, dataItem) {};


      APIBuilder.widgetBuilder(layoutContainer.WidgetViewModel, layoutContainer);
      APIBuilder.regionBuilder(layoutContainer.RegionViewModel, layoutContainer);

      // Register Common Component Instances
      layoutContainer.WidgetViewModel.prototype.profileTools = new ProfileTools();

      ko.applyBindings(masterViewModel, document.getElementById("oracle-cc"));

      if (typeof(waitForRenderComplete) === 'function') {
        waitForRenderComplete(ko, layoutContainer, masterViewModel);
      }
    } // require/function
  ); // require
});

define("js/main", function(){});

