define(
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  'viewModels/pointsPayment',
  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  ['knockout', 'ccConstants', 'CCi18n', 'koMapping'],
  //-------------------------------------------------------------------
  // MODULE DEFINITION
  //-------------------------------------------------------------------
  function (ko, ccConstants, CCi18n, koMapping) {
    'use strict';
    
    function PointsPayment() {
      var self = this;
      
      self.paymentMethodType = ccConstants.LOYALTY_POINTS_PAYMENT_TYPE;
      self.type = ccConstants.LOYALTY_POINTS_PAYMENT_TYPE;
      self.program = ko.observable(null);
      self.amount = ko.observable();
      self.billingAddress = ko.observable(undefined);
      
      self.message = ko.observable();
      //self.paymentGroupId = null;
      
      self.seqNum = null;
      self.currencyCode = ko.observable(null);
      self.isEdit = ko.observable(false);
      
      /**
       * Method to create additional properties for points payment
       * @param {Array} PropertyKeys - An array of strings, containing the keys for additional properties
       */
      self.createCustomProperties = function(PropertyKeys) {
        var extData = {};
        for(var key in PropertyKeys) {
          if(typeof PropertyKeys[key] === "string") {
            extData[PropertyKeys[key]] = "";
          }
        }
        self.customProperties = ko.mapping.fromJS(extData);
      };
      
      /**
       * Convert an observable object into a plain javascript object, and remove fields
       * that aren't relevant for payment processing API request
       *
       * @returns {Object} New Object containing points payment data.
       */
      self.toJSON = function() {
        var oldOptions = koMapping.defaultOptions().ignore;
        koMapping.defaultOptions().ignore = ["message", "paymentGroupId", "gatewayName",
                                             "paymentMethod", "paymentState", "program", "loyaltyPrograms", "customPaymentProperties"];
        //
        if (self.program()) {
          var tempObj = {};
          var loyaltyProgram =  self.program();
          $.each(loyaltyProgram, function(key, value) {
            if (typeof value === 'string' || value instanceof String) {
              tempObj[key] = value;
            } else {
              tempObj[key] = JSON.stringify(value);
            }
          });
          self.customProperties = tempObj;
        }
        //
        var copy = koMapping.toJS(self);
        koMapping.defaultOptions().ignore = oldOptions;

        // Since billing address can have dynamic properties, removing the old
        // billing address from copy and adding the converted billing address
        if(self.billingAddress()) {
          delete copy.billingAddress;
          copy.billingAddress = self.billingAddress().toJSON();
        }
        return copy;
      };
      
      /**
       * @function
       * @name resetPaymentDetails
       * @class pointsPayment
       * Clears the data and errors associated with this object.
       */
      self.resetPaymentDetails = function() {
        // pointsPayment related fields to reset.
        self.program(null);
        self.amount(null);
        self.billingAddress(null);
        self.message(null);
        self.currencyCode(null);
      };
      
      /**
       * Force all relevant member observables to perform their
       * validation now & display the errors (if any)
       */
      self.validatePaymentData = function() {
        return true;
      };
      
    };
    
    return PointsPayment;
});
