//----------------------------------------
/**
 * Public Javascript API for Oracle Cloud Commerce
 */

 define(
  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------

  'ociApm',['ccConstants', 'pubsub'],

  //-------------------------------------------------------------------
  // MODULE DEFINTIION
  //-------------------------------------------------------------------

  function (CCConstants, PubSub) {

  "use strict";


    function OCIApmViewModel() {

      this.initialized = false;

      this.initialize = function (globalSiteSettings) {

        if(this.initialized) return;

        const extensionSiteSettings = globalSiteSettings().extensionSiteSettings || {};
        const ociApmSettings = extensionSiteSettings.ociApm || {};

        if(ociApmSettings.enabled) {
          $.Topic(PubSub.topicNames.PAGE_LAYOUT_UPDATED).subscribe(function() {
            if(window.apmrum) {
              const visitorId = window.localStorage.getItem(CCConstants.VISITOR_ID) || 'unknown';
              window.apmrum.username = visitorId;
              window.apmrum.webApplication = globalSiteSettings().siteInfo.id + ' - ' + globalSiteSettings().siteInfo.name;
            }
          });
        }

        this.initialized = true;
      }
    }

    return new OCIApmViewModel();
});
