/**
 * @fileoverview Models data returned from the server as part of the page and 
 * global context.
 */
define(
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  'pageLayout/data',
  
  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  ['knockout'],
    
  //-------------------------------------------------------------------
  // MODULE DEFINITION
  //-------------------------------------------------------------------
  function (ko) {
    "use strict";
    /**
     * Constructor Function
     * @name ServerData
     * @private
     * @class ServerData Models data returned from the server as part of the
     * page and global context.
     **/
    function ServerData() {}
    
    return ServerData;
  });

