/**
 * @fileoverview Defines a UserViewModel used to register and
 * login a user to the site.
 */

/*global $ */
/*global define */
define(
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  'pageLayout/user',
  
  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  ['knockout', 'pubsub', 'notifier', 'CCi18n', 
   'ccRestClient', 'ccConstants', 'koValidate', 'ccKoValidateRules',
   'storeKoExtensions', 'navigation', 'viewModels/address', 'storageApi', 'pageLayout/parent-organisation', 'viewModels/dynamicProperty',
   'pageLayout/organization', 'ccStoreConfiguration', 'viewModels/loyalty', 'viewModels/storeCreditContainer'],
    
  //-------------------------------------------------------------------
  // MODULE DEFINITION
  //-------------------------------------------------------------------
  function (ko, pubSub, notifier, CCi18n, ccRestClient, 
            CCConstants, koValidate, rules, storeKoExtensions, navigation, address, storageApi, ParentOrganisation, DynamicProperty,
            Organization, CCStoreConfiguration, Loyalty, storeCreditContainer) {
  
    'use strict';
    
    /** 
     * Creates a user view model.
     * <p>
     * The User View Model is a singleton class that provides the context for logged in
     * user data, e.g. Profile data, Preferences, Shipping information.
     * 
     * @param {RestAdapter} pAdapter REST adapter.
     * @param {Object} pUserData Additional user data.
     * 
     * @public
     * @class Represents a user.
     * @name UserViewModel
     * @property {observable<string>} firstName First part of name
     * @property {observable<string>} lastName Last part of name
     * @property {observable<string>} loggedInUserName Logged in username.
     * @property {observable<string>} emailAddress Primary communication email address.
     * @property {observable<string>} emailAddressForForgottenPwd Email address to use for password retrieval.
     * @property {observable<string>} emailMarketingMails Email address to use for marketing messages.
     * @property {observable<boolean>} emailMarketingModified Flag showing if the marketing email field is modified.
     * @property {observable<boolean>} GDPRProfileP13nConsentGranted Flag showing if the GDPRProfileP13nConsent is granted.
     * @property {observable<boolean>} GDPRProfileP13nConsentModified Flag showing if the GDPRProfileP13nConsent is modified.
     * @property {observable<timestamp>} previousVisitDate Shopper's previous visit date.
     * @property {observable<string>} password Password.
     * @property {observable<string>} oldPassword Existing password field on change password form.
     * @property {observable<string>} newPassword New password field on change password form.
     * @property {observable<string>} confirmPassword Confirm password field on change password form.
     * @property {string} token The token to be used to reset the password.
     * @property {observableArray<Address>} shippingAddressBook Shipping addresses associated with user.
     * @property {observable<Address>} defaultShippingAddress helper for default address selection.
     * @property {observable<Address>} editShippingAddress Address object shown on editable address form.
     * @property {observable<boolean>} deleteShippingAddress Flag set to true if an address is being deleted.
     * @property {observable<string>} locale User locale.
     * @property {observable<boolean>} loggedinAtCheckout Flag to determine if user is logged in on the checkout page.
     * @property {observable<boolean>} loggedoutAtCheckout Flag to determine if user if logged out on the checkout page.
     * @property {observable<Object>} persistedOrder Saved recent, or in progress order.
     * @property {observable<string>} orderId ID of recent, or in progress order.
     * @property {observable<number>} countOfSubmittedOrders Track number of orders created by user.
     * @property {observable<boolean>} ignoreEmailValidation Flag to temporarily ignore validation on email address field.
     * @property {observable<boolean>} ignorePasswordValidation Flag to temporarily ignore validation on password field.
     * @property {observable<boolean>} isUserProfileEdited Whether the User profile has unsaved changes.
     * @property {observable<boolean>} delaySuccessNotification Whether to delay the notification popup.
     * @property {observable<boolean>} isSearchInitiatedWithUnsavedChanges Whether there are unsaved changes when a search is initiated.
     * @property {observable<boolean>} isUserSessionExpired True if the user's session has expired.
     * @property {observable<string>} pageToRedirect Hash code for page redirection
     * @property {observable<boolean>} isSessionExpiredDuringSave True if the user's session expires when a save operation is in progress.
     * @property {observable<boolean>} isUserLoggedOut True if user has logged out.
     * @property {observable<boolean>} isPageRedirected True if page was redirected.
     * @property {observable<boolean>} isResourcesLoaded True when resources are loaded.
     * @property {observable<string>} errorMessageKey Resource key to look up current error notification message.
     * @property {observable<string>} successMessageKey Resource key to look up current success notification message.
     * @property {observable<Object[]>} passwordPolicies List of store specific password rules enabled for user accounts.
     * @property {observable<boolean>} isChangePassword Flag to determine if we need to display the password change fields.
     * @property {observable<boolean>} showCreateNewPasswordMsg Flag to determine whether to display the new password prompt.
     * @property {observable<string>} createNewPasswordError Message to display if password creation failed.
     * @property {observable<boolean>} isPasswordExpired True if password has expired.
     * @property {observable<boolean>} hasFieldLevelError True if any of the fields on the profile has an error.
     * @property {observable<boolean>} ignoreConfirmPasswordValidation Flag to temporarily ignore validation on the confirm password field.
     * @property {observable<string>} forgotPasswordMsg Localisable message for 'forgotten password' link.
     * @property {observable<Object[]>} myWishLists List of owned wish lists
     * @property {observable<Object[]>} joinedWishLists List of joined wish lists
     * @property {observable<boolean>} approvalRequired Profile level flag , specifies if Order approval is enabled for profile or not
     * @property {observable<string>} orderPurchaseLimit Profile level purchase Limit
     * @property {CCStoreConfiguration} storeConfiguration An instance of the cc-store-configuration containing store-configuration data.
     * @property {observable<boolean>} rememberDevice To remember current device.
     * @property {observable<string>} passcode Verification code to be entered by the users.
     */
    function UserViewModel(pAdapter, pUserData) {
      
      if (UserViewModel.singleInstance) {
        throw new Error("Cannot instantiate more than one UserViewModel, use getInstance(pAdapter, pUserData)");  
      }
      var self = this;
      
      // Provide price list group object to all widgets.
      self.selectedPriceListGroup = ko.observable(pUserData.priceListGroup);
      self.currentOrganization = ko.observable();
      self.activePriceListGroups = ko.observableArray([]);
      self.dynamicProperties = ko.observableArray([]);
      // Adding user data from server to view model.
      
      // Observable that specifies the profile type of the user
      self.profileType = ko.observable();
      
      // Loyalty programs that user has enrolled for
      self.loyaltyPrograms = ko.observableArray([]);
      
      // Adding user data from server to the view model
      // Currently ignoring the 'links' property as it is not being used and causing problems in ccLink custom binding
      ko.mapping.fromJS(pUserData, {'ignore': ["links","dynamicProperties"]}, self);
      // Values from context
      self.id = ko.observable('');
      self.login = ko.observable('');
      self.customerContactId = ko.observable();
      self.adapter = pAdapter;
      
      // Flags
      self.resetAll = ko.observable(false);
      
      // Values to be entered
      self.emailAddress = ko.observable('');
      self.emailAddressForForgottenPwd = ko.observable('');
      self.emailMarketingMails = ko.observable(false);
      self.emailMarketingModified = ko.observable(false);
      
      // Global email preference switch
      self.receiveEmailGlobally = ko.observable(false);
      
      self.GDPRProfileP13nConsentGranted = ko.observable(false);
      self.GDPRProfileP13nConsentModified = ko.observable(false);
      self.previousVisitDate = ko.observable();
      self.justAfterLogin = ko.observable(false);
      self.firstName = ko.observable('');
      self.loggedInUserName = ko.observable('');
      self.lastName = ko.observable('');
      self.password = ko.observable('');
      self.newPassword = ko.observable('');
      self.confirmPassword = ko.observable('');
      self.token = "";
      self.primaryShippingAddress = ko.observable('');
      self.shippingAddressBook = ko.observableArray().extend({ deferred: true });
      self.defaultShippingAddress = ko.observable(); // For default address radio selection.
      self.editShippingAddress = ko.observable(); // Used when the edit address form is displayed.
      self.deleteShippingAddress = ko.observable(false);
      self.locale = ko.observable('');
      self.supportedLocales = [];
      self.ssoLoginAccountName = ko.observable('');
      
      self.client = ko.observable(ccRestClient);
      self.loginError = ko.observable('');
      self.loggedIn = ko.observable(self.client().loggedIn);
      self.isLoginFailed = ko.observable(false);
      self.readyToDisplay = ko.observable(true);
      self.autoLoginComplete = $.Deferred();
      self.pageAppeared = $.Deferred();
      self.registeringUser = false;
      
      self.oldPassword = ko.observable('');

      self.emailAddress.isData = true;
      self.firstName.isData = true;
      self.lastName.isData = true;
      self.password.isData = true;
      self.newPassword.isData = true;
      self.confirmPassword.isData = true;  
      self.oldPassword.isData = true;
      self.emailAddressForForgottenPwd.isData = true;
      self.locale.isData = true;
      self.createOrderForLoggedInAtCheckout = window.clientConfigData && window.clientConfigData.createOrderForLoggedInAtCheckout && window.clientConfigData.createOrderForLoggedInAtCheckout == "true" ? true : false;
      
      self.ssoLoginAccountName.isData = true;
      
      self.loggedinAtCheckout = ko.observable(false);
      self.loggedoutAtCheckout = ko.observable(false);
      self.persistedOrder =  ko.observable();
      self.orderId = ko.observable('');
      self.countOfSubmittedOrders = ko.observable(0);
      self.ignoreEmailValidation = ko.observable(true);
      //If login is not email then enable this flag to remove email format check for login
      self.isLoginNotEmail = ko.observable(false);
      self.ignorePasswordValidation = ko.observable(true);
      self.isUserProfileEdited = ko.observable(false);
      self.delaySuccessNotification = ko.observable(false);
      self.isSearchInitiatedWithUnsavedChanges = ko.observable(false);
      
      self.isUserSessionExpired = ko.observable(false);
      self.pageToRedirect = ko.observable();
      //Customers can use this variable to customize the page we navigate after a successful B2B login
      self.pageToRedirectAfterLogin = ko.observable('/home');
      self.isSessionExpiredDuringSave = ko.observable(false);
      self.isUserLoggedOut = ko.observable(true);
      self.isPageRedirected = ko.observable(false);
      self.isResourcesLoaded = ko.observable(false);
      
      self.errorMessageKey = ko.observable('');
      self.successMessageKey = ko.observable('');
      
      // Sets the password policies
      self.passwordPolicies = ko.observable();
      // Checks whether the case is a change password
      self.isChangePassword = ko.observable(false);
      
      self.showCreateNewPasswordMsg = ko.observable(false);
      self.createNewPasswordError = ko.observable('');
      self.isPasswordExpired = ko.observable(false);
      self.hasFieldLevelError = ko.observable(false);
      self.ignoreConfirmPasswordValidation = ko.observable(true);
      self.forgotPasswordMsg = ko.observable('');
      
      // Social Wish List
      self.myWishLists = ko.observableArray();
      self.joinedWishLists = ko.observableArray();
      
      self.isSesExpDuringPlaceOrder = ko.observable(false);

      // B2B ViewModel
      self.parentOrganization = new ParentOrganisation();
      self.defaultPrimaryOrganization=new ParentOrganisation();
      if(pUserData.parentOrganization) {
        ko.mapping.fromJS(pUserData.parentOrganization, {}, self.defaultPrimaryOrganization);
        ko.mapping.fromJS(pUserData.parentOrganization, {}, self.parentOrganization);
      }
      
      self.refreshPageAfterContactLogout = ko.observable(false);
      self.catalogId = ko.observable('');
      self.roles = ko.observableArray([]);

      self.rememberDevice = ko.observable(false);
      self.passcode = ko.observable('');
      self.loginVerificationMessage = ko.observable('');
      
      /** Currently, for organization end point we are getting all roles available and for profile end point we are getting all roles available for that
      *  user for all organizations. To filter the roles available for the current organization for the current user,
      *  we have to filter out user roles based on current organization.
      */
      self.rolesForCurrentOrganization = ko.observableArray([]);
      
      /**
       * Flag to check if user data is modified, if this flag is set then we call update customer
       */
      self.isUserDataModified = ko.observable(false);
      
      self.isDelegatedAdmin = ko.observable(false);
      self.isApprover = ko.observable(false);
      self.isAccountAddressManager = ko.observable(false);
      self.isProfileAddressManager = ko.observable(false);
      self.organizations = ko.observableArray([]);
      self.currentOrganizationDetails = ko.observable();
      self.defaultShippingAddressType = ko.observable();
      self.defaultBillingAddressType = ko.observable();

      // Used to capture a notification message that should be displayed when a user is redirected.
      self.redirectNotificationMessage = ko.observable('');
      self.sessionExpiredProfileRedirected = false;

      //fields for order approval
      self.approvalRequired = ko.observable(false);
      self.orderPurchaseLimit = ko.observable(null);
      
      //fields for split payments
      self.isHistoricalOrder = false;
//      self.historicalOrderuuid = null;
      
      // reference to loyalty view model
      self.loyaltyViewModel = ko.observable(null);

      //reference to store credit container view model
      self.storeCreditContainer = ko.observable(storeCreditContainer.getInstance());
      
      // notes/comments for customer
      self.comments = ko.observable();
      
      self.clonedOrderId = ko.observable('');
      // Active/Inactive status
      self.active = ko.observable();
      self.profileHomeRedirectRoutesArray = [CCConstants.PAGETYPE_PROFILE,CCConstants.PAGETYPE_ORDER_HISTORY,CCConstants.PAGETYPE_ORDER_DETAILS,CCConstants.PAGETYPE_SCHEDULED_ORDERS,
        CCConstants.PAGETYPE_ASSET_DETAILS,CCConstants.PAGETYPE_ASSETS,CCConstants.PAGETYPE_CONFIRMATION];
      self.onLoadCartSubscribe = null;
      
      /**
       * Callback invoked when email address field receives focus. Disables email validation.
       * 
       * @function 
       * @name UserViewModel#emailAddressFocused
       */
      self.emailAddressFocused = function() {
        self.ignoreEmailValidation(true);
        return true;
      };

      /**
       * Callback invoked when email address field loses focus. Re-enable email validation.
       * 
       * @function
       * @name UserViewModel#emailAddressLostFocus
       */
      self.emailAddressLostFocus = function() {
        self.ignoreEmailValidation(false);
        return true;
      };

      self.updatedShippingAddress = null;
      self.updatedShippingAddressBook = null;
      
      /*
       * Callback invoked when confirm password field is modified.
       */
      self.confirmPassword.subscribe(function(newValue) {
        self.newPassword.isModified(true);
      });
      
      /*
       * Callback invoked when marketing emails field is modified.
       */
      self.emailMarketingMails.subscribe(function(newValue) {
        self.emailMarketingModified(true);
      });

      /*
       * Callback invoked when GDPRProfileP13nConsentGranted field is modified.
       */
      self.GDPRProfileP13nConsentGranted.subscribe(function(newValue) {
	    self.GDPRProfileP13nConsentModified(true);
      });
      
      /**
       * Callback to update shipping address if modified from other parts of the site. Invoked on
       * receiving a CHECKOUT_SAVE_SHIPPING_ADDRESS pubsub event.
       * 
       * @private
       * @function
       * @name UserViewModel#updateShippingAddress
       */
      self.updateShippingAddress = function(opts) {
        // Now if any other module is updating a shipping address on the User Profile, it will just be
        // adding a secondary address.
        if (self.shippingAddressBook()) {
          var translateHelper = {
            translate: function(key, options) {
              return CCi18n.t('ns.common:resources.' + key, options);
            }
          };
          //If any field(isDefaultAddress) in existing address is changed then it triggers unwanted subscribers.
          //Hence created new object of shipping address.
          var shippingAddress = new address('user-shipping-address', '', translateHelper, self.contextData.page.shippingCountriesPriceListGroup, self.contextData.page.defaultShippingCountry);
          shippingAddress.copyFrom(this.toJSON(), self.contextData.page.shippingCountriesPriceListGroup);
          shippingAddress.isDefaultAddress(self.shippingAddressBook().length === 0);

          if (!self.isAddressSaved(shippingAddress) && shippingAddress.isValid()) {
            self.shippingAddressBook.push(shippingAddress);
          }        
        }
      };
      
      /**
       * Updates the locale associated to this user. Invoked on receiving
       * a CHECKOUT_USER_LOCALE pubsub event.
       * 
       * @private
       * @function
       * @name UserViewModel#updateLocale
       */
      self.updateLocale = function() {
        self.locale(this);
      };
      
      /**
       * Updates the user locale when it is not part of supported locales. Invoked on receiving
       * a USER_LOCALE_NOT_SUPPORTED pubsub event.
       * 
       * @private
       * @function
       * @name UserViewModel#updateLocaleToSupported
       */
      self.updateLocaleToSupported = function() {
        if (self.locale.isModified !== undefined) {
          var currentLocale = ccRestClient.getStoredValue(CCConstants.LOCAL_STORAGE_USER_CONTENT_LOCALE);
          var updateLocaleToProfile = false;
          // Check if the locale is a supported one. If so, do not update profile.
          var supportedLocaleNames = [];
          for (var i = 0; i < self.supportedLocales.length; i++) {
            supportedLocaleNames.push(self.supportedLocales[i].name);
          }
          if (ccRestClient.previewMode && ! self.contextData && currentLocale) {
            self.locale(JSON.parse(currentLocale)[0].name);
            updateLocaleToProfile = true;
          } else if (supportedLocaleNames.indexOf(self.contextData.global.user.locale) > -1) {
            self.locale(self.contextData.global.user.locale);
          } else if (currentLocale) {
            self.locale(JSON.parse(currentLocale)[0].name);
            updateLocaleToProfile = true;
          } else {
            self.locale(self.contextData.global.locale);
            updateLocaleToProfile = true;
          }
          self.locale.isModified(true);
          if (updateLocaleToProfile && !(ccRestClient.profileType == CCConstants.PROFILE_TYPE_AGENT)) {
            $.Topic(pubSub.topicNames.USER_PROFILE_UPDATE_SUBMIT).publishWith(self, [{message: "success"}]);
            self.locale.isModified(false);
            $.Topic(pubSub.topicNames.UPDATE_USER_LOCALE_NOT_SUPPORTED_ERROR).publish();
          }
        }
      };

      /* 
       * This computed will return  whether the error message on the 
       * create new password modal should be visible or not.
       */
      self.showExpiredPasswordErrorMsg = ko.computed(function() {
        if (self.isPasswordExpired() && 
             ((self.oldPassword.isModified() && !self.oldPassword.isValid()) ||
              (self.newPassword.isModified() && !self.newPassword.isValid()) ||
              (self.confirmPassword.isModified() && !self.confirmPassword.isValid()) ||
              self.hasFieldLevelError())) {
          return false;
        }
        return true;
      }, self);
      
      /**
       * Handle the validation of the user data entered while creating an account.
       * 
       * @function
       * @name UserViewModel#validateUser
       * @returns {boolean} <code>true</code> if the user details are valid, and
       * <code>false</code> otherwise.
       */
      self.validateUser = function() {
        self.ignoreEmailValidation(false);
        self.ignorePasswordValidation(false);
        self.ignoreConfirmPasswordValidation(false);
        self.emailAddress.isModified(true);
        self.firstName.isModified(true);
        self.lastName.isModified(true);
        if(!self.contextData.global.enableProfileRegistrationEmailCheck) {
          self.newPassword.isModified(true);
          self.confirmPassword.isModified(true);
        }
        return self.isValid();
      };
      
      /**
       * Check if the complete user profile is valid by checking the components.
       * 
       * @function
       * @name UserViewModel#isValid
       * @returns {boolean} true if the user profile is valid, otherwise false.
       */
      self.isValid = function() {
        return (self.emailAddress.isValid() &&
                self.firstName.isValid() && 
                self.lastName.isValid() && 
                (self.contextData.global.enableProfileRegistrationEmailCheck || (self.newPassword.isValid() && self.confirmPassword.isValid()) )
        );
      };
      
      /**
       * Reset all registration details.
       * 
       * @function
       * @name UserViewModel#reset
       */
      self.reset = function() {
        if (self.isUserLoggedOut() || (!self.loggedIn() && !self.isUserSessionExpired())) {
          self.login('');
          if (self.isResourcesLoaded()) {
            self.login.isModified(false);
          }
          self.loggedInUserName('');
        }
        self.emailAddress('');
        self.firstName('');
        self.lastName('');
        self.loginError('');
        self.resetPassword();
        self.resetVerifyLoginData();
        self.isLoginFailed(false);
        self.emailMarketingMails(false);
        self.receiveEmailGlobally(false);
        self.GDPRProfileP13nConsentGranted(false);
        if (self.isResourcesLoaded()) {
          self.lastName.isModified(false);
          self.emailAddress.isModified(false);
          self.firstName.isModified(false);
        }
      };
      
      /**
       * Reset the password field.
       * 
       * @function
       * @name UserViewModel#resetPassword
       */
      self.resetPassword = function() {
        self.password('');
        self.newPassword('');
        self.confirmPassword('');
        self.oldPassword('');
        if (self.isResourcesLoaded()) {
          self.password.isModified(false);
          self.newPassword.isModified(false);
          self.confirmPassword.isModified(false);
          self.oldPassword.isModified(false);
        }
        self.isChangePassword(false);
      };
      
      /**
       * Reset all the details from the registration fields without
       * resetting the email address.
       * 
       * @function
       * @name UserViewModel#resetDetails
       */
      self.resetDetails = function() {
        self.firstName('');
        self.lastName('');
        self.resetPassword();
        self.emailMarketingMails(false);
        self.GDPRProfileP13nConsentGranted(false);
        self.firstName.isModified(false);
        self.lastName.isModified(false);
        self.emailMarketingModified(false);
        self.GDPRProfileP13nConsentModified(false);
      };
      
      /**
       * Handle the validation of login.
       * 
       * @function
       * @name UserViewModel#isLoginValid
       * @returns {boolean} true if login is valid, otherwise false. 
       */
      self.isLoginValid = function() {
        self.ignoreEmailValidation(false);
        return (this.login.isValid()
            && this.password.isValid());
      };
      
      /**
       * Handle the validation of user data entered during login. Also raises the modified flags on
       * login and password fields.
       * 
       * @function
       * @name UserViewModel#validateLogin
       * @returns {boolean} true if login is valid, otherwise false.
       */
      self.validateLogin = function() {
        var self = this;
        self.login.isModified(true);
        self.password.isModified(true);
        return self.isLoginValid();
      };

      /**
       * Handle the validation of user data entered during loginVerification. Also raises the modified flags on
       * the passcode field.
       *
       * @function
       * @name UserViewModel#validateLoginOTP
       * @returns {boolean} true if login is valid, otherwise false.
       */
      self.validateLoginOTP = function() {
        var self = this;
        self.passcode.isModified(true);
        return self.passcode.isValid();
      };

      /**
       * Reset login details.
       * 
       * @function
       * @name UserViewModel#resetLoginData 
       */
      self.resetLoginData =  function() {
        var self = this;
        self.login('');
        self.password('');
        self.loginError('');
        self.rememberDevice(false);
        self.passcode('');
        self.isLoginFailed(false);
        if (self.isResourcesLoaded()) {
          self.login.isModified(false);
          self.password.isModified(false);
          self.passcode.isModified(false);
        }
      };

      /**
       * Reset login verification details.
       *
       * @function
       * @name UserViewModel#resetVerifyLoginData
       */
      self.resetVerifyLoginData =  function() {
        var self = this;
        self.rememberDevice(false);
        self.passcode('');
        if (self.isResourcesLoaded()) {
          self.passcode.isModified(false);
        }
      };

      /**
       * Reset modified flags on knockout observables.
       * 
       * @function
       * @name UserViewModel#resetModified
       */
      self.resetModified = function() {
        var self = this;
        self.emailAddress.isModified(false);
        self.firstName.isModified(false);
        self.lastName.isModified(false);
        self.oldPassword.isModified(false);
        self.newPassword.isModified(false);
        self.confirmPassword.isModified(false);
        self.emailMarketingModified(false);
        self.GDPRProfileP13nConsentModified(false);
      };
      
      /**
       * Reset modified flags of address objects in the address book.
       * 
       * @function
       * @name UserViewModel#resetShippingAddressBookModified
       */
      self.resetShippingAddressBookModified = function() {
        var self = this;
        ko.utils.arrayForEach(self.shippingAddressBook(), function (shippingAddress) {
          shippingAddress.resetModified();
        });
      };
      
      /**
       * Check whether profile is modified.
       * 
       * @function
       * @name UserViewModel#isProfileModified
       * @returns {boolean} true if profile has been modified, otherwise false.
       */
      self.isProfileModified = function() {
        // Checking whether user basic profile has been modified.
        if (self.firstName.isModified() || self.lastName.isModified() ||
            self.emailAddress.isModified() || self.emailMarketingModified() || self.locale.isModified() || self.GDPRProfileP13nConsentModified()) {
          return true;
        }
        return false;
      };
      
      /**
       * Check whether any of the password fields have been
       * modified: oldPassword, newPassword, confirmPassword.
       * 
       * @function
       * @name UserViewModel#isPasswordModified
       * @returns {boolean} true if any password fields are modified, otherwise false.
       */
      self.isPasswordModified = function() {
          if (self.oldPassword.isModified() || self.newPassword.isModified() || self.confirmPassword.isModified()) {
            return true;
          }
          return false;
      };
      
      /**
       * Check whether all password fields are valid.
       * 
       * @function
       * @name UserViewModel#isPasswordValid
       * @param {boolean} isUsingToken needs to be set to true in order to not validate old password.
       * @returns {boolean} true if all password fields are valid, otherwise false.
       */
      self.isPasswordValid = function (isUsingToken) {
        if (!isUsingToken) {
          self.oldPassword.isModified(true);
        }
        self.newPassword.isModified(true);
        self.confirmPassword.isModified(true);
        
        // Checking whether user password fields are valid.
        if (self.newPassword.isValid() &&
            self.confirmPassword.isValid()) {
          if (isUsingToken || self.oldPassword.isValid()) {
            return true;
          }
        }
        return false;
      };
      
      /**
       * Check whether profile is valid.
       * 
       * @function
       * @name UserViewModel#isProfileValid
       * @returns {boolean} true if the profile is valid, otherwise false.
       */
      self.isProfileValid = function () {
        self.firstName.isModified(true);
        self.lastName.isModified(true);
        self.emailAddress.isModified(true);
        self.ignoreEmailValidation(false);
        // Checking whether user basic profile has been modified.
        if (self.firstName.isValid() && self.lastName.isValid() &&
            self.emailAddress.isValid()) {
          return true;
        }
        return false;
      };
      
      /**
       * Checks if the supplied address already exists in the profile address book.
       * 
       * @function
       * @name UserViewModel#isAddressSaved
       * @param {Address} addr An address object.
       * @returns {boolean} true if the address is already present, otherwise false.
       */
      self.isAddressSaved = function (addr) {
        
        for (var k = 0; k < self.shippingAddressBook().length; k++) {
          if (addr.compare(self.shippingAddressBook()[k]))
          {
            return true;
          }
        }
        
        return false;
      };

      /**
       * Returns true if current user is b2b user.
       */
      self.isB2BUser = function(){
        if(self.parentOrganization && self.parentOrganization.name()) {
          return true;
        }
        return false;
      }
      
      /**
       * Check if profile type is B2B or not
       */
      self.isB2BProfileType = function(){
        if(self.profileType() && self.profileType() == CCConstants.B2B_PROFILE_TYPE){
          return true
        }
      }

      /**
       * Check if the shipping address book contains any addresses which have been modified.
       * 
       * @function
       * @name UserViewModel#isShippingAddressBookModified
       * @returns true if any shipping addresses are modified, otherwise false.
       */
      self.isShippingAddressBookModified = function() {
        if (self.deleteShippingAddress())
        {
          return true;
        }
        
        for (var k = 0; k < self.shippingAddressBook().length; k++) {
          if (self.shippingAddressBook()[k].isModified())
          {
            return true;
          }
        }
        return false;
      };
      
      /**
       * Check if all the addresses in the shipping address book are valid.
       * 
       * @function
       * @name UserViewModel#isShippingAddressBookValid
       * @returns true if all shipping addresses are valid, otherwise false.
       */
      self.isShippingAddressBookValid = function() {
        for (var k = 0; k < self.shippingAddressBook().length; k++) {
          if (!self.shippingAddressBook()[k].isValid())
          {
            return false;
          }
        }
        return true;
      };

      /**
       * Check if the dynamic properties array contains any dynamic property which have been modified.
       * 
       * @function
       * @name UserViewModel#isDynamicPropertiesModified
       * @returns true if any dynamic property is modified, otherwise false.
       */
      self.isDynamicPropertiesModified = function() {
        for (var k = 0; k < self.dynamicProperties().length; k++) {
          if (self.dynamicProperties()[k].value.isModified())
          {
            return true;
          }
        }
        return false;
      };

      /**
       * Select a default shipping address from the shipping address book.
       * Assumes that addr is a reference to an Address object that already exists in the address book.
       * 
       * @function
       * @name UserViewModel#selectDefaultAddress
       * @param {Address} addr An address object.
       */
      self.selectDefaultAddress = function(addr) {
        for (var k = 0; k < self.shippingAddressBook().length; k++) {
          var shippingAddress = self.shippingAddressBook()[k];
          shippingAddress.isDefaultAddress(shippingAddress === addr);
        }
      };

      /**
       * Sort the addresses in shipping address book by the following requirements:
       * 
       * <ol>
       *   <li>Default Shipping Address should always be first.</li>
       *   <li>Then sort addresses by Last Name in ascending order.</li>
       *   <li>Then sort addresses by Address Line 1 in ascending order.</li>
       * </ol>
       * 
       * @private
       * @function
       * @name UserViewModel#sortShippingAddresses
       */
      self.sortShippingAddresses = function () {
        if (self.updatedShippingAddressBook && self.updatedShippingAddressBook.length) {
          self.updatedShippingAddressBook.sort(function(left, right) {
              // Default Address has the highest sort priority.
              if (left.isDefaultAddress)
                  return -1;
              if (right.isDefaultAddress)
                  return 1;

              if (left.lastName && right.lastName && left.lastName.toLowerCase() == right.lastName.toLowerCase()) {
                  return left.address1 && right.address1 && left.address1.toLowerCase() == right.address1.toLowerCase() ? 0 
                            : (left.address1 && right.address1 && left.address1.toLowerCase() < right.address1.toLowerCase() ? -1 : 1);
              } else if (left.lastName && right.lastName && left.lastName.toLowerCase() < right.lastName.toLowerCase()) {
                  return -1;
              } else {
                  return 1;
              }
          });
        }
      };
      
      /**
       * Successful Login Callback invoked on receiving a success response from the login service.
       * 
       * @private
       * @function
       * @name UserViewModel#loginSuccessFunc
       * @param {boolean} isAutoLogin Flag to track if user was logged in automatically, or 
       *        if we need to reset user data.
       */
      self.loginSuccessFunc = function(isAutoLogin) {
        self.loggedIn(self.client().loggedIn);
        self.isUserSessionExpired(false);
        self.isLoginFailed(false);
        self.isSesExpDuringPlaceOrder(false);
        self.isUserLoggedOut(false);
        self.password('');
        self.password.isModified(false);
        self.passcode('');
        self.passcode.isModified(false);
        self.rememberDevice(false);
        notifier.clearError(self.WIDGET_ID);
        self.id(self.client().profileId);
        if (!navigation.isPathEqualTo(self.myAccountHash) && self.pageToRedirect() == self.myAccountHash) {
          self.sessionExpiredProfileRedirected = true;
        }
        if (self.pageToRedirect() && self.pageToRedirect()!= '' && self.pageToRedirect() != self.checkoutHash) {
          var hash = self.pageToRedirect();
          self.pageToRedirect(null);
          if (navigation.isPathEqualTo(hash)) {
            var localData = self.getLocalData();
            //if the session expired during the save on my profile page and if the user logs in with the same user, then do not reload the user data
            if (null != localData && localData.login == self.login() && navigation.isPathEqualTo(self.myAccountHash)  && !self.isPasswordExpired() && self.isSessionExpiredDuringSave()) {
              //self.isSessionExpiredDuringSave(false); this is set to false on the profile page
            } else {
              self.getCurrentUser(isAutoLogin, true);
              //if the session expired during the save on my profile page and if the user logs in with different login, then reset the user data
              if (navigation.isPathEqualTo(self.myAccountHash)) {
                self.isSessionExpiredDuringSave(false);
                $.Topic(pubSub.topicNames.USER_PROFILE_SESSION_RESET).publish();
              }
            }
          }
          self.isPageRedirected(true);
          navigation.goTo(hash);
        } else {
          self.getCurrentUser(isAutoLogin , true);
          // Wait for Cart to be completely loaded before reloading the layout.
          if(null == self.onLoadCartSubscribe){
          self.onLoadCartSubscribe = $.Topic(pubSub.topicNames.CART_LOADED_FOR_PROFILE).subscribe(function(){
            if(!(self.registeringUser && self.loggedinAtCheckout())){
              //Refreshing layout to set Content Variation Slots, if any.
              self.registeringUser = false;
              var navPath=navigation.getPathWithoutLocale().split("?");
              var eventData = {
                  		  'pageId': navigation.getPath(),
                  		  'parameters':navPath[1],
                  		  'onLogin' : true};
              self.pageAppeared = $.Deferred();
              $.when(
                $.Topic(pubSub.topicNames.PAGE_VIEW_CHANGED).publish(eventData)
              ).done(
                function(){
                //If it is auto login, resolve promise for notifier to be displayed
                  if(isAutoLogin){
                    if(!self.storeConfiguration.enablePrioritizedLoading){
                      self.autoLoginComplete.resolve();
                   } else{$.when(self.pageAppeared).done(function(){
                    // In case of prioritized loading, check if the widgets are rendered on UI before resolving notifier
                      self.autoLoginComplete.resolve();
                      self.pageAppeared = $.Deferred();
                    }); }
                  }
               });
            }
          })
          }
        }
        self.isPasswordExpired(false);
      };
      
      /**
       * Failed Login Callback invoked on receiving an error from the login service.
       * 
       * @private
       * @function
       * @name UserViewModel#loginErrorFunc
       */
      self.loginErrorFunc = function() {
        self.isLoginFailed(true);
        self.loginError(CCi18n.t('ns.common:resources.loginError'));
        self.password('');
        self.password.isModified(false);
      };

      /**
       * Processes the dynamic property metadata
       * @private
       * @function
       * @name UserViewModel#processDynamicPropertiesMetadata
       * @param {Object[]} data List of dynamic properties
       */
      self.processDynamicPropertiesMetadata = function(data) {
        var self = this;
        var newProperties = [];
        for (var i = 0; i < data.length; i++) {
          var newProperty = true;
          // If property already defined, update its metadata
          for (var j = 0; j < self.dynamicProperties().length && newProperty; j++) {
            if (data[i].id === self.dynamicProperties()[j].id()) {
              newProperty = false;
              self.dynamicProperties()[j].initializeMetadata(data[i], false);
              self.dynamicProperties()[j].value(data[i].value);
            }
          }
          
          // Set up new property
          if (newProperty) {
            var dynPropItem = new DynamicProperty();
            dynPropItem.initializeMetadata(data[i], true);
            dynPropItem.value(data[i].value);
            newProperties.push(dynPropItem);
          }
        }

        // Add new properties
        for (var i = 0; i < newProperties.length; i++) {
          self.dynamicProperties.push(newProperties[i]);
        }
      };
      if (pUserData.dynamicProperties) {
        self.processDynamicPropertiesMetadata(pUserData.dynamicProperties);
      }
      self.populateUserFromLocalData(false);
      self.storeConfiguration = CCStoreConfiguration.getInstance();
      
    //fields for Punchout
      self.isPunchout = ko.observable(false);
      self.isPunchout(pUserData.isPunchout?pUserData.isPunchout:false);
      
      var punchoutStorageData =ccRestClient.getStoredValue(CCConstants.LOCAL_STORAGE_ADDITIONAL_FORM_DATA);
      if(punchoutStorageData){
        var punchoutStorageObject =JSON.parse(punchoutStorageData);
        if(punchoutStorageObject){
          if(punchoutStorageObject.orderId){self.orderId(punchoutStorageObject.orderId);}
        }
      }
      
      
      //section for calling the subscriptions 
      $.Topic(pubSub.topicNames.USER_REGISTRATION_SUBMIT).subscribe(self.registerUser);
      $.Topic(pubSub.topicNames.USER_LOGIN_SUBMIT).subscribe(self.handleLogin);
      $.Topic(pubSub.topicNames.USER_LOGIN_CANCEL).subscribe(self.handleCancel);
      $.Topic(pubSub.topicNames.SAML_LOGIN_SUCCESSFUL).subscribe(self.SamlLoginSuccessCallback.bind(self));
      $.Topic(pubSub.topicNames.SAML_LOGIN_FAILURE).subscribe(self.SamlLoginErrorCallback.bind(self));
      $.Topic(pubSub.topicNames.OPENID_LOGIN_SUCCESSFUL).subscribe(self.openIdLoginSuccessCallback.bind(self));
      $.Topic(pubSub.topicNames.OPENID_LOGIN_FAILURE).subscribe(self.openIdLoginErrorCallback.bind(self));
      // Custom extensions for registration fields
      // Wait until i18n is ready before attempting to resolve any resource bundles
      // This isn't strictly required because locale *should* be ready before
      // any view models are created, but just to be safe, subscribe anyway
      $.Topic(pubSub.topicNames.LOCALE_READY).subscribe(self.loadResources.bind(self));
      $.Topic(pubSub.topicNames.USER_PROFILE_UPDATE_SUBMIT).subscribe(self.handleUpdateProfile);
      $.Topic(pubSub.topicNames.USER_PROFILE_UPDATE_CANCEL).subscribe(self.handleUpdateCancel);
      $.Topic(pubSub.topicNames.PAGE_LAYOUT_LOADED).subscribe(self.handlePageChanged.bind(self));
      $.Topic(pubSub.topicNames.PAGE_METADATA_CHANGED).subscribe(self.handlePageChanged.bind(self));
      $.Topic(pubSub.topicNames.USER_LOGOUT_SUBMIT).subscribe(self.handleLogout.bind(self));
      $.Topic(pubSub.topicNames.USER_SESSION_VALID).subscribe(self.handleSessionValid.bind(self));
      $.Topic(pubSub.topicNames.USER_SESSION_EXPIRED).subscribe(self.handleSessionExpired.bind(self));
      $.Topic(pubSub.topicNames.CHECKOUT_SAVE_SHIPPING_ADDRESS).subscribe(self.updateShippingAddress);
      $.Topic(pubSub.topicNames.CHECKOUT_USER_LOCALE).subscribe(self.updateLocale);
      $.Topic(pubSub.topicNames.USER_LOCALE_NOT_SUPPORTED).subscribe(self.updateLocaleToSupported);
      $.Topic(pubSub.topicNames.USER_MFA_LOGIN_SUBMIT).subscribe(self.handleVerifyLogin);

      return (self);
    };
    
    /**
     * Intialize localized validations and error messages. Invoked on receiving a .LOCALE_READY pubsub event.
     * 
     * @private
     * @function
     * @name UserViewModel#loadResources
     */
    UserViewModel.prototype.loadResources = function() {
      var self = this;
      self.firstName.extend({ required: { params: true, message: CCi18n.t('ns.common:resources.firstNameRequired')}});
      self.lastName.extend({ required: { params: true, message: CCi18n.t('ns.common:resources.lastNameRequired')}});
      self.locale.extend({ required: { params: true, message: CCi18n.t('ns.common:resources.localeRequired')}});
      self.emailAddressForForgottenPwd.extend({ 
        required: { params: true, message: CCi18n.t('ns.common:resources.emailAddressRequired')},
        maxLength: { params: CCConstants.CYBERSOURCE_EMAIL_MAXIMUM_LENGTH, message: CCi18n.t('ns.common:resources.maxLengthEmailAdd', {maxLength: CCConstants.CYBERSOURCE_EMAIL_MAXIMUM_LENGTH})},
        email: { params: true, onlyIf: function () { return (!self.ignoreEmailValidation()); }, message: CCi18n.t('ns.common:resources.emailAddressInvalid')}
      });
      self.emailAddress.extend({ 
        required: { params: true, message: CCi18n.t('ns.common:resources.emailAddressRequired')},
        maxLength: { params: CCConstants.CYBERSOURCE_EMAIL_MAXIMUM_LENGTH, message: CCi18n.t('ns.common:resources.maxLengthEmailAdd', {maxLength: CCConstants.CYBERSOURCE_EMAIL_MAXIMUM_LENGTH})},
        email:{ params: true, onlyIf: function () { return (!self.ignoreEmailValidation()); }, message: CCi18n.t('ns.common:resources.emailAddressInvalid')}});
      if(self.isLoginNotEmail){
      self.login.extend({ 
        required: { params: true, message:  function () { if(!self.isLoginNotEmail()) return CCi18n.t('ns.common:resources.emailAddressRequired'); else return  CCi18n.t('ns.common:resources.loginRequired'); }},
        maxLength: { params: CCConstants.CYBERSOURCE_EMAIL_MAXIMUM_LENGTH, message: CCi18n.t('ns.common:resources.maxLengthEmailAdd', {maxLength: CCConstants.CYBERSOURCE_EMAIL_MAXIMUM_LENGTH})},
        email: { params: true, onlyIf: function () { return (!self.ignoreEmailValidation()&&!self.isLoginNotEmail()); }, message: CCi18n.t('ns.common:resources.emailAddressInvalid')}});
      }
      else{
      self.login.extend({ 
        required: { params: true, message: CCi18n.t('ns.common:resources.emailAddressRequired')},
        maxLength: { params: CCConstants.CYBERSOURCE_EMAIL_MAXIMUM_LENGTH, message: CCi18n.t('ns.common:resources.maxLengthEmailAdd', {maxLength: CCConstants.CYBERSOURCE_EMAIL_MAXIMUM_LENGTH})},
        email: { params: true, onlyIf: function () { return (!self.ignoreEmailValidation()); }, message: CCi18n.t('ns.common:resources.emailAddressInvalid')}});
      }
      self.password.extend({ required: { params: true, message: CCi18n.t('ns.common:resources.passwordRequired')}});
      self.newPassword.extend({
        password: { params: { policies: self.passwordPolicies, login: self.emailAddress, observable: self.newPassword, includePreviousNPasswordRule: self.isChangePassword}, 
                    onlyIf: function () { return ( (!self.ignoreEmailValidation() || self.newPassword.isModified()) && !self.ignorePasswordValidation() ); }, 
                    message: CCi18n.t('ns.common:resources.passwordPoliciesErrorText')},
        required: { params: true, message: CCi18n.t('ns.common:resources.passwordRequired')}
      });
      self.oldPassword.extend({ required: { params: true, message: CCi18n.t('ns.common:resources.currentPasswordRequired')}});
      self.confirmPassword.extend({ 
        required: { params: true, message: CCi18n.t('ns.common:resources.confirmPasswordRequired')},
        match: { params: self.newPassword, onlyIf: function () { return (!self.ignoreConfirmPasswordValidation()); }, message: CCi18n.t('ns.common:resources.passwordUnmatched')}});
      self.passcode.extend({
        required: { params: true, message: CCi18n.t('ns.common:resources.verificationCodeRequired')},
      });
      self.isResourcesLoaded(true);
    };
    
    /**
     * Read and populate the view model with loggedinAtCheckout, loggedoutAtCheckout, 
     * OrderID, loggedInUserName, login and isUserSessionExpired flags.
     * 
     * @function
     * @name UserViewModel#populateUserFromLocalData
     * @param {boolean} loadAll Flag to control whether we load all data.
     */    
    UserViewModel.prototype.populateUserFromLocalData = function (loadAll) {
      var self = this;
      //load the data from local storage
      var cookieData = self.getLocalData();
      if (cookieData != null && !(ccRestClient.profileType == CCConstants.PROFILE_TYPE_AGENT)) {
        self.loggedinAtCheckout(cookieData.loggedinAtCheckout);
        self.loggedoutAtCheckout(cookieData.loggedoutAtCheckout);
        self.orderId(cookieData.orderId);
        if (loadAll && cookieData.login) {
          self.loggedInUserName(cookieData.loggedInUserName);
          self.login(cookieData.login);
          self.isUserSessionExpired(cookieData.isUserSessionExpired);
          self.isUserLoggedOut(cookieData.isUserLoggedOut);
        }
      }
    };
    
    /**
     * Read loggedinAtCheckout, loggedoutAtCheckout, OrderID, loggedInUserName, login 
     * and isUserSessionExpired flags from the user local storage.
     * 
     * @private
     * @function 
     * @name UserViewModel#getLocalData
     * @returns {Object} cookieData Parsed local storage data.
     */ 
    UserViewModel.prototype.getLocalData = function () {
      var self = this;
      //load the data from local storage
      var cookieData = null;
      try {
        cookieData = storageApi.getInstance().getItem("user");
        if (cookieData) {
          cookieData = JSON.parse(cookieData);
        }
      }
      catch (pError) {
      }
      return cookieData;
    };
    
    /**
     * Write the loggedinAtCheckout, loggedoutAtCheckout, OrderID, loggedInUserName, login and 
     * isUserSessionExpired flags to user local storage. The argument 'setParam' is a string which
     * may be one of the following:
     * 
     * <ul>
     *  <li><b>all</b> - Save all parameters.</li>
     *  <li><b>checkoutFlag</b> - Save loggedInAtCheckout and loggedOutAtCheckout.</li>
     *  <li><b>orderId</b> - Save orderID.</li>
     *  <li><b>sessionExpiry</b> - Save isUserSessionExpired and isUserLoggedOut.</li>
     * </ul>
     * 
     * @private
     * @function
     * @name  UserViewModel#setLocalData
     * @param {string} setParam Enumerated string to select parameters saved to local storage.
     * @returns {boolean} true if setParam is falsey or empty, otherwise implicit return.
     */
    UserViewModel.prototype.setLocalData = function (setParam) {
      var self = this;
      if (!setParam && setParam == '') {
        return true;
      }
      var userCookieData = self.getLocalData();
      if (userCookieData == null) {
        userCookieData = {};
      }
      switch (setParam) {
        case 'all':
          userCookieData = {
            loggedinAtCheckout: self.loggedinAtCheckout(),
            loggedoutAtCheckout: self.loggedoutAtCheckout(),
            orderId: self.orderId(),
            loggedInUserName: self.loggedInUserName(),
            login: self.login(),
            isUserSessionExpired: self.isUserSessionExpired(),
            isUserLoggedOut: self.isUserLoggedOut()
          };
          break;
        case 'checkoutFlag':
          userCookieData.loggedinAtCheckout = self.loggedinAtCheckout();
          userCookieData.loggedoutAtCheckout = self.loggedoutAtCheckout();
          break;
        case 'orderId':
          userCookieData.orderId = self.orderId();
          break;
        case 'sessionExpiry':
          userCookieData.isUserSessionExpired = self.isUserSessionExpired();
          userCookieData.isUserLoggedOut = self.isUserLoggedOut();
          break;
      }
      // Write the user to local storage
      try {
        storageApi.getInstance().setItem("user", JSON.stringify(userCookieData));
      }
      catch(pError) {
      }
    };
    
    /**
     * Update the values of loggedinAtCheckout, and loggedoutAtCheckout and write them to 
     * user local storage.
     * 
     * @function
     * @name UserViewModel#updateLocalData
     * @param {boolean} loggedinAtCheckout Whether the user is logged in on the checkout page.
     * @param {boolean} loggedoutAtCheckout Whether the user is logged out on the checkout page.
     */ 
    UserViewModel.prototype.updateLocalData = function(loggedinAtCheckout, loggedoutAtCheckout) {
      var self = this;
      self.loggedinAtCheckout(loggedinAtCheckout);
      self.loggedoutAtCheckout(loggedoutAtCheckout);
      self.setLocalData('checkoutFlag');
    };
    
    /**
     * Remove user local storage on logout.
     * 
     * @function
     * @name UserViewModel#removeLocalData
     */
    UserViewModel.prototype.removeLocalData = function() {
      try {
        storageApi.getInstance().removeItem("user");
      }
      catch(pError) {
      }
    };

    /**
     * Remove personalization soft-login cookie on logout.  This cookie is set via HTTP.
     *
     * @function
     * @name UserViewModel#removeSoftLoginCookie
     */
    UserViewModel.prototype.removeSoftLoginCookie = function() {
      try {
        storageApi.getInstance().saveToCookies(CCConstants.SOFT_LOGIN, "", -1);
      }
      catch(pError) {
      }
    };
    
    /**
     * Send a reset password email.
     * 
     * @function
     * @name #UserViewModel#resetForgotPassword 
     */ 
    UserViewModel.prototype.resetForgotPassword = function(pEmailAddress) {
      var self = this;
      var inputParams = {};
      
      inputParams[CCConstants.LOGIN] = pEmailAddress || self.emailAddressForForgottenPwd();
      
      var successCallback =  function(data) {
        $.Topic(pubSub.topicNames.USER_RESET_PASSWORD_SUCCESS).publish(data);
      };
      
      var failureCallback = function(data) {
        $.Topic(pubSub.topicNames.USER_RESET_PASSWORD_FAILURE).publish(data);
      }
      
      if(ccRestClient.profileType == CCConstants.PROFILE_TYPE_AGENT){
    	ccRestClient.request(CCConstants.ENDPOINT_FORGOT_PASSWORD, inputParams, successCallback, failureCallback, self.id());  
      }else{
        self.adapter.persistCreate(CCConstants.ENDPOINT_FORGOT_PASSWORD, 'id', inputParams, null, successCallback, failureCallback);
      }

    };
    
    /**
     * Handle user login. Invoked on receiving a USER_LOGIN_SUBMIT pubsub event.
     * 
     * @private
     * @function
     * @name UserViewModel#handleLogin
     */
    UserViewModel.prototype.handleLogin = function() {
      var self = this;
      
      if (!self.isLoginValid()) {
        self.login.isModified(true);
        self.password.isModified(true);
        return false;
      }
      ccRestClient.setStoredValue(CCConstants.LOCAL_STORAGE_ORGANIZATION_ID, null);
      self.client().login(this.login(), this.password(),  
        // Success Function
          function(isVerificationRequired) {
            // If validation token is received during login, then user needs to perform further verfication to login.
            if (isVerificationRequired) {
              $.Topic(pubSub.topicNames.LOGIN_VERIFICATION_REQUIRED).publish();
            } else {
              // If access token is received then user can login directly.
              self.loginSuccessFunc(false);
              $.Topic(pubSub.topicNames.USER_LOGIN_SUCCESSFUL).publish();
            }
          },
        // Error function
        function(pResult) {
          if (pResult.error == CCConstants.PASSWORD_EXPIRED) {
            self.isPasswordExpired(true);
            $.Topic(pubSub.topicNames.USER_PASSWORD_EXPIRED).publish();
          } else if (pResult.error == CCConstants.PASSWORD_GENERATED) {
            self.isPasswordExpired(true);
            $.Topic(pubSub.topicNames.USER_PASSWORD_GENERATED).publish();
          } else {
            self.loginErrorFunc();
            $.Topic(pubSub.topicNames.USER_LOGIN_FAILURE).publish(pResult);
          }
        }
      );
    };

    /**
     * Handle user loginVerification. Invoked on receiving a USER_MFA_LOGIN_SUBMIT pubsub event.
     *
     * @private
     * @function
     * @name UserViewModel#handleVerifyLogin
     * @param {Object} obj Widget
     */
    UserViewModel.prototype.handleVerifyLogin = function(obj) {
      var self = this;

      if (!self.passcode.isValid()) {
        self.passcode.isModified(true);
        return false;
      }
      var successCallback = function () {
        self.loginSuccessFunc(false);
        $.Topic(pubSub.topicNames.USER_LOGIN_SUCCESSFUL).publish();
      };
      var failureCallback = function (pResult) {
        self.resetVerifyLoginData();
        self.loginErrorFunc();
        $.Topic(pubSub.topicNames.USER_MFA_LOGIN_FAILURE).publish(pResult);
      };
      var identifier = obj && obj.getDeviceIdentifier ;
      if ( identifier && typeof identifier === "function") {
        identifier = obj.getDeviceIdentifier();
      }
      self.client().verifyLogin(self.passcode(), self.rememberDevice(),successCallback,failureCallback,identifier);
    };

    /**
     * Handle SAML login.
     * 
     * @private
     * @function
     * @name UserViewModel#handleSamlLogin
     */
    UserViewModel.prototype.handleSamlLogin = function() {
      var self = this;
      ccRestClient.setStoredValue(CCConstants.LOCAL_STORAGE_ORGANIZATION_ID, null);
      var path = navigation.getPathWithoutLocale();
      if (path.indexOf('?loggedIn=false') > -1) {
        var pagePos = path.indexOf('page=') + 5;
        path = decodeURIComponent(path.substr(pagePos));
        
        path = (path.charAt(0) === '/' ? path : '/' + path);
      }
      else {
        path = navigation.getRelativePath() + navigation.getQueryString();
      }
      
      self.client().setSessionStoredValue("SSO_RETURN_URL", path);
      storageApi.getInstance().removeFromMemory(CCConstants.CC_IS_SSO_LOGIN_FAILURE);
      
      self.client().generateSamlAuthnRequest(
        function(pResult) {
          var form = $(
            "<form/>", 
             {
        	   action: pResult.authnRequestTarget,
               method: 'POST'
    	     });
          form.append( 
            $(
    		  "<input>", 
              {
    			type:'hidden',
                name:'SAMLRequest',
                value: pResult.authnRequest
              }
    		)
          );
          
          form.append( 
            $(
              "<input>", 
              {
            	type:'hidden',
                name:'RelayState',
                value: self.ssoLoginAccountName()
              }
            )
          );
          $(document.body).append(form);
          form.submit();
        },
        // Error function
        function(pResult) {
          self.loginErrorFunc();
          $.Topic(pubSub.topicNames.USER_LOGIN_FAILURE).publish(pResult);
        }
      );
    };
    /**
     * Auth Success callback
     * 
     * @function
     * @name UserViewModel#authSuccesscallback
     */
     UserViewModel.prototype.authSuccessCallback = function(authLoginInProgessFlag) {
      var self = this;
      var path = ccRestClient.getSessionStoredValue("SSO_RETURN_URL");
      if (path == null) {
        path = "/";
      }
      self.pageToRedirect(path);
      ccRestClient.setSessionStoredValue("SSO_RETURN_URL", null);
      self.loginSuccessFunc(false);
      storageApi.getInstance().removeFromMemory(authLoginInProgessFlag);
      $.Topic(pubSub.topicNames.USER_LOGIN_SUCCESSFUL).publish();      
    }

    /**
     * Auth Error callback
     * 
     * @function
     * @name UserViewModel#authErrorcallback
     */
    UserViewModel.prototype.authErrorCallback = function (authLoginInProgessFlag,pResult) {
      var self = this;
      ccRestClient.setSessionStoredValue("SSO_RETURN_URL", null);
      self.loginErrorFunc();
      if (authLoginInProgessFlag === CCConstants.CC_IS_OPENID_LOGIN_UNDER_PROGRESS) {
        storageApi.getInstance().removeFromMemory(CCConstants.CC_IS_OPENID_LOGIN_UNDER_PROGRESS);
        storageApi.getInstance().saveToMemory(CCConstants.CC_IS_OPENID_LOGIN_FAILURE, true);
      } else {
        storageApi.getInstance().removeFromMemory(CCConstants.CC_IS_SSO_LOGIN_UNDER_PROGRESS);
        storageApi.getInstance().saveToMemory(CCConstants.CC_IS_SSO_LOGIN_FAILURE, true);
      }
      $.Topic(pubSub.topicNames.USER_LOGIN_FAILURE).publish(pResult);
    }

    /**
     * Finish SAML login, after the user is successfully logged-in to OCCS
     *
     * @function
     * @name UserViewModel#SamlLoginSuccessCallback
     */
    UserViewModel.prototype.SamlLoginSuccessCallback = function() {
      var self = this;
      self.authSuccessCallback(CCConstants.CC_IS_SSO_LOGIN_UNDER_PROGRESS);
    };

    /**
     * Finish OpenId login, after the user is successfully logged-in to OCCS
     *
     * @function
     * @name UserViewModel#openIdLoginSuccessCallback
     */
     UserViewModel.prototype.openIdLoginSuccessCallback = function() {
      var self = this;
      self.authSuccessCallback(CCConstants.CC_IS_OPENID_LOGIN_UNDER_PROGRESS);
    };

    /**
    * Error callback, when the user is not able to login using SAML credentials
    *
    * @function
    * @name UserViewModel#SamlLoginErrorCallback
    */
    UserViewModel.prototype.SamlLoginErrorCallback = function(pResult) {
      var self = this;
      self.authErrorCallback(CCConstants.CC_IS_SSO_LOGIN_UNDER_PROGRESS,pResult);
    };

    /**
    * Error callback, when the user is not able to login using OpenId credentials
    *
    * @function
    * @name UserViewModel#openIdLoginErrorCallback
    */
     UserViewModel.prototype.openIdLoginErrorCallback = function(pResult) {
      var self = this;
      self.authErrorCallback(CCConstants.CC_IS_OPENID_LOGIN_UNDER_PROGRESS,pResult);
    };

    /**
     * Handle OpenID login.
     * 
     * @private
     * @function
     * @name UserViewModel#handleOpenIDLogin
     */
    UserViewModel.prototype.handleOpenIDLogin = function() {
      var self = this;
      ccRestClient.setStoredValue(CCConstants.LOCAL_STORAGE_ORGANIZATION_ID, null);
      var path = navigation.getPathWithoutLocale();
      if (path.indexOf('?loggedIn=false') > -1) {
        var pagePos = path.indexOf('page=') + 5;
        path = decodeURIComponent(path.substr(pagePos));
        
        path = (path.charAt(0) === '/' ? path : '/' + path);
      }
      else {
        path = navigation.getRelativePath() + navigation.getQueryString();
      }
      
      self.client().setSessionStoredValue("SSO_RETURN_URL", path);
      storageApi.getInstance().removeFromMemory(CCConstants.CC_IS_OPENID_LOGIN_FAILURE); 
      
      self.client().generateOpenIDAuthRequest(
        function(pResult) {
          document.location.href = pResult.authz_code_request_dst;
        },
        // Error function
        function(pResult) {
          self.loginErrorFunc();
          $.Topic(pubSub.topicNames.USER_LOGIN_FAILURE).publish(pResult);          
        }
      );
    };

    /**
     * Handle OpenID logout.
     * 
     * @private
     * @function
     * @name UserViewModel#handleOpenIDLogout
     */
    UserViewModel.prototype.handleOpenIDLogout = function() {
      var self = this;
      var path = navigation.getPathWithoutLocale();
      if (path.indexOf('?loggedIn=false') > -1) {
        var pagePos = path.indexOf('page=') + 5;
        path = decodeURIComponent(path.substr(pagePos));
        
        path = (path.charAt(0) === '/' ? path : '/' + path);
      }
      else {
        path = navigation.getRelativePath() + navigation.getQueryString();
      }
      
      self.client().setSessionStoredValue("SSO_RETURN_URL", path);
      self.client().generateOpenIDLogoutRequest(
        // Success Function
        function(pResult) {
          // Log out locally
          self.client().storeToken =  function(pTokenSecret) {};
          self.client().clearStoredValues();
          self.client().tokenSecret = null;
          self.client().loggedIn = false;
          self.client().clearStoredValue("code_challenge");
          $.Topic(pubSub.topicNames.USER_LOGOUT_SUBMIT).publishWith([{message: "success"}]);
          self.clearUserData();
          // Log out on IDP
          //Append state param with path to navigate back to the same page 
          if(!(self.contextData.page.pageType == CCConstants.PAGETYPE_ORDER_HISTORY || 
            self.contextData.page.pageType == CCConstants.PAGETYPE_ORDER_DETAILS || 
            self.contextData.page.pageType == CCConstants.PAGETYPE_PROFILE || 
            self.contextData.page.pageType == CCConstants.PAGETYPE_SCHEDULED_ORDERS || 
            self.contextData.page.pageType == CCConstants.PAGETYPE_SCHEDULED_ORDERS)) {
            document.location.href = pResult.openid_logout_request_dst + "&state=" + path;
          } else {
            document.location.href = pResult.openid_logout_request_dst;
          }
        },
        // Error function
        function(pResult) {
        }
      )
    };

    /**
     * Handle user logout. Invoked on receiving a USER_LOGOUT_SUBMIT pubsub event.
     * 
     * @private
     * @function
     * @name UserViewModel#handleLogout
     */
    UserViewModel.prototype.handleLogout = function() {
      var self = this;
      $.Topic(pubSub.topicNames.USER_CLEAR_CART).publish([{message: "success"}]);      
      var successFunc = function() {
        $.Topic(pubSub.topicNames.USER_LOGOUT_SUCCESSFUL).publish([{message: "success"}]);
        storageApi.getInstance().removeItem(CCConstants.LOCAL_STORAGE_SHOPPER_CONTEXT);
        storageApi.getInstance().removeItem(CCConstants.LOCAL_STORAGE_CURRENT_CONTEXT);
        ccRestClient.clearStoredValue(CCConstants.LOCAL_STORAGE_PRICELISTGROUP_ID);
        self.clearUserData();
        self.profileRedirect();
        if(self.refreshPageAfterContactLogout()){
          self.refreshPageAfterContactLogout(false);
          navigation.goTo(self.contextData.global.links.home.route);
        } else{
        //Refreshing layout to set Content Variation Slots, if any.
          var eventData = {'pageId': navigation.getPath()};
          $.Topic(pubSub.topicNames.PAGE_VIEW_CHANGED).publish(eventData);
        }
        //When logging out of preview mode, send an event to indicate logout to clear picker data
        if(ccRestClient.previewMode) {
          var eventParams = {};
          var event = new CustomEvent('preview_logout', eventParams)
          window.parent.dispatchEvent(event)
        }
      };
      var errorFunc = function(pResult) {
        self.clearUserData();
        self.profileRedirect();
        navigation.goTo(self.contextData.global.links.home.route);
      };
      if (self.loggedIn()) {
        if(self.parentOrganization && self.parentOrganization.name()){
          self.refreshPageAfterContactLogout(true);
        }
        self.client().logout(successFunc, errorFunc);
      } else {
        self.clearUserData();
        $.Topic(pubSub.topicNames.USER_LOGOUT_SUCCESSFUL).publish([{message: "success"}]);
        self.profileRedirect();
      }
    };
      
    /**
     * Profile Redirect.
     * 
     * @private
     * @function
     * @name UserViewModel#profileRedirect
     */
    UserViewModel.prototype.profileRedirect = function() {
      var self = this;
      var currentPath = navigation.getPathWithoutLocale();
      currentPath = (currentPath.charAt(0) === '/') ? currentPath.substr(1) : currentPath;
      for (var i = 0; i < self.profileHomeRedirectRoutesArray.length; i++) {
        var compareToPath = navigation.getPathWithoutLocale(self.contextData.global.links[self.profileHomeRedirectRoutesArray[i]].route);
        compareToPath = (compareToPath.charAt(0) === '/') ? compareToPath.substr(1) : compareToPath;
        if (currentPath.indexOf(compareToPath) === 0) {
          navigation.goTo(self.contextData.global.links.home.route);
          break;
        }
      }
    };
    
    /**
     * Clear user data after session expiry or logout, if the user does not opt to re-login.
     * 
     * @private
     * @function
     * @name UserViewModel#clearUserData
     */
    UserViewModel.prototype.clearUserData = function() {
      var self = this;
      self.isUserSessionExpired(false);
      self.isUserLoggedOut(true);
      self.removeSoftLoginCookie();
      self.loggedIn(false);
      self.resetLoginData();
      self.clearUserSpecificData();
    };

    /**
     * Clear user Id (called when landing onto the customer search, order search, return search, dashboard 
     * @private
     * @function
     * @name UserViewModel#clearUserSpecificData
     */
    UserViewModel.prototype.clearUserSpecificData = function() {
      var self = this;
      self.id(null);
      self.removeLocalData();
      self.parentOrganization = new ParentOrganisation();
      self.organizations([]);
      self.editShippingAddress(null);
      self.deleteShippingAddress(false);
      self.shippingAddressBook([]);
      self.updatedShippingAddress = null;
      self.updatedShippingAddressBook = null;
      self.reset();
      self.locale("");
      self.loyaltyViewModel(null);
      self.storeCreditContainer(null);
      $.Topic(pubSub.topicNames.USER_LOAD_SHIPPING).publish([{message: "success"}]);
      for (var i = 0; i < self.dynamicProperties().length; i++) {
          self.dynamicProperties()[i].reset();
       }
    };

    
    /**
     * Handle cancelling a login in progress. Invoked on receiving a .USER_LOGIN_CANCEL pubsub event.
     * 
     * @private
     * @function
     * @name UserViewModel#handleCancel
     */
    UserViewModel.prototype.handleCancel = function() {
      var self = this;
      self.resetLoginData();
      notifier.clearError(self.WIDGET_ID);
    };

    /**
     * Handle new user registration and auto login with registerProfile endpoint.
     *
     * @private
     * @function
     * @name UserViewModel#registerUserWithAutoLogin
     * @param {Object} obj Widget
     * @param {Object} inputParams
     */
    UserViewModel.prototype.registerUserWithAutoLogin = function (obj, inputParams) {
      var self = this;
      // When MFA is enabled and enableProfileRegistrationEmailCheck is disabled registerProfile endpoint is called.
      self.client().registerUserWithAutoLogin(CCConstants.ENDPOINT_REGISTER_PROFILE, inputParams,
          //success callback
          function (data) {
            self.resetDynamicPropertiesValueIsModified();
            if (obj.successCallback) {
              obj.successCallback(data);
            } else {
              data.widgetId = obj.widgetId;
              self.registeringUser = true;
              self.orderId('');
              ccRestClient.setStoredValue(CCConstants.LOCAL_STORAGE_ORGANIZATION_ID, null);
              data.isAutoLogin = true;
              self.loginSuccessFunc(true);
              $.Topic(pubSub.topicNames.USER_AUTO_LOGIN_SUCCESSFUL).publish(data);
            }
          },
          //failure callback
          function (data) {
            data.widgetId = obj.widgetId;
            if (data.errorCode == CCConstants.OAUTH_INTERNAL_ERROR ||
                (data.error == (CCConstants.OAUTH_ERROR_INVALID_REQUEST
                    || CCConstants.OAUTH_ERROR_INVALID_GRANT
                    || CCConstants.OAUTH_ERROR_UNAUTHORIZED_CLIENT
                    || CCConstants.OAUTH_ERROR_USER_LOCKED))) {
              // Login call failure
              self.loginErrorFunc();
              $.Topic(pubSub.topicNames.USER_AUTO_LOGIN_FAILURE).publish(data);
            } else {
              // Create call failure
              if (data.errorCode == CCConstants.CREATE_PROFILE_USER_EXISTS) {
                data.message = CCi18n.t('ns.common:resources.accountAlreadyExists');
              }
              $.Topic(pubSub.topicNames.USER_CREATION_FAILURE).publish(data);
            }
          }
      );
    };

    /**
     * Handle new user registration. Invoked on receiving a USER_REGISTRATION_SUBMIT pubsub event.
     * 
     * @private
     * @function
     * @name UserViewModel#registerUser
     * @param {Object} obj Widget
     */
    UserViewModel.prototype.registerUser = function (obj) {
      var self = this;
      var isDataInValid = false;
      var includeDynamicProperties = false;
      self.receiveEmail = self.emailMarketingMails() ? "yes" : "no";
      var inputParams = {};
      if (obj.inputParams) {
        inputParams = obj.inputParams;
      } else {
        inputParams[CCConstants.PROFILE_EMAIL] = self.emailAddress();
        if (!self.contextData.global.enableProfileRegistrationEmailCheck) {
          inputParams[CCConstants.PROFILE_PASSWORD] = self.newPassword();
        }
        inputParams[CCConstants.PROFILE_FIRST_NAME] = self.firstName();
        inputParams[CCConstants.PROFILE_LAST_NAME] = self.lastName();
        inputParams[CCConstants.PROFILE_RECEIVE_EMAIL] = self.receiveEmail;
        inputParams[CCConstants.PROFILE_GDPR_CONSENT_GRANTED_KEY] = self.GDPRProfileP13nConsentGranted();

        // Adding shipping addresses if they exist
        if (self.shippingAddressBook() !== null && self.shippingAddressBook() != undefined && self.isShippingAddressBookValid()) {
          inputParams[CCConstants.PROFILE_SHIPPING_ADDRESSES] = self.shippingAddressBook();
        }

        if (!self.locale()) {
          var currentLocale = ccRestClient.getStoredValue(CCConstants.LOCAL_STORAGE_USER_CONTENT_LOCALE);
          if (currentLocale) {
            self.locale(JSON.parse(currentLocale)[0].name);
          }
        }
        inputParams[CCConstants.PROFILE_LOCALE] = self.locale();
      }

      // checking whether dynamic properties are modified/valid
      if (self.dynamicProperties().length > 0) {
        if (self.isDynamicPropertiesModified()) {
          for (var i = 0; i < self.dynamicProperties().length; i++) {
            var dynProp = self.dynamicProperties()[i];
            if (!dynProp.isValid()) {
              isDataInValid = true;
              break;
            }
          }
          if (!isDataInValid) {
            includeDynamicProperties = true;
          }
        }
      }
      if (isDataInValid) {
        // If data is invalid, show error message.
        $.Topic(pubSub.topicNames.USER_PROFILE_UPDATE_INVALID).publish();
        return;
      }
      if (includeDynamicProperties) {
        self.handleDynamicPropertiesUpdate(inputParams);
      }

      if (!self.contextData.global.enableProfileRegistrationEmailCheck && self.storeConfiguration.multiFactorAuthenticationEnabled) {
        // When MFA is enabled and enableProfileRegistrationEmailCheck is disabled registerProfile endpoint is called.
        self.registerUserWithAutoLogin(obj,inputParams);
      } else {
        self.adapter.persistCreate(CCConstants.ENDPOINT_CREATE_PROFILE, CCConstants.ENDPOINT_CREATE_PROFILE, inputParams,
            //success callback
            function (data) {
              self.resetDynamicPropertiesValueIsModified();
              if (obj.successCallback) {
                obj.successCallback(data);
              } else if (data && data.message && self.contextData.global.enableProfileRegistrationEmailCheck) {
                var dataNew = {};
                dataNew.widgetId = obj.widgetId;
                dataNew.message = data.message;
                $.Topic(pubSub.topicNames.USER_ACCOUNT_CREATION_MESSAGE).publish(dataNew);
              } else {
                self.registeringUser = true;
                data.widgetId = obj.widgetId;
                self.handleAutoLogin(obj);
              }
            },
            function (data) {
              data.widgetId = obj.widgetId;
              if (data.errorCode == CCConstants.CREATE_PROFILE_USER_EXISTS) {
                data.message = CCi18n.t('ns.common:resources.accountAlreadyExists');
              }
              $.Topic(pubSub.topicNames.USER_CREATION_FAILURE).publish(data);
            }
        );
      }
    };

    /**
     * Handle the auto-login of a user from the front end.
     * This is used to auto-login users after they are registered.
     * 
     * THIS SHOULD ONLY BE USED TO AUTO-LOGIN as it does not have
     * a lot of checks needed for normal login. Invoked on registering
     * a user successfully.
     * 
     * @private
     * @function
     * @name UserViewModel#handleAutoLogin
     */
    UserViewModel.prototype.handleAutoLogin = function(obj) {
      var self = this;
      self.orderId('');
      self.login(self.emailAddress());
      ccRestClient.setStoredValue(CCConstants.LOCAL_STORAGE_ORGANIZATION_ID, null);
      self.client().login(self.login(), self.newPassword(),
        // Success Function
        function() {
          var data ={};
          data.widgetId = obj.widgetId;
          data.isAutoLogin = true;
          self.loginSuccessFunc(true);
          $.Topic(pubSub.topicNames.USER_AUTO_LOGIN_SUCCESSFUL).publish(data);
        },
        // Error function
        function(pResult) {
          pResult.widgetId = obj.widgetId;
          self.loginErrorFunc();
          $.Topic(pubSub.topicNames.USER_AUTO_LOGIN_FAILURE).publish(pResult);
        }
      );
    };

    /**
     * This resets the isModified attribute of dynamic properties's value for the profile
     * @private
     * @function
     * @name UserViewModel#resetDynamicPropertiesValueIsModified
     */
    UserViewModel.prototype.resetDynamicPropertiesValueIsModified = function() {
      var self = this;
      for (var i = 0; i < self.dynamicProperties().length; i++) {
        if (self.dynamicProperties()[i].value.isModified) {
          self.dynamicProperties()[i].value.isModified(false);
        }
      }
    };

    /**
     * Get current user profile
     * Gets the logged-in user's data via a REST call.
     * 
     * @function
     * @name UserViewModel#getCurrentUser
     * @param {boolean} isAutoLogin Flag to suppress some actions for auto-login.
     * @param {boolean} isLogin Flag to perform few actions after login.
     * @param {function} pSuccessCallback success callback passed from calling method
     * @param {function} pFailureCallback failure callback passed from calling method 
     */
    UserViewModel.prototype.getCurrentUser = function(isAutoLogin, isLogin, pSuccessCallback, pFailureCallback) {
      var self = this;

      // Reset in case it has been set by a previous operation.
      self.redirectNotificationMessage('');
      var params = {};
      var contextObj = {};
      contextObj[CCConstants.ENDPOINT_KEY] = CCConstants.ENDPOINT_GET_CURRENT_USER;
      contextObj[CCConstants.IDENTIFIER_KEY] = "userData";
      var filterKey = self.storeConfiguration.getFilterToUse(contextObj);
      if (filterKey) {
        params[CCConstants.FILTER_KEY] = filterKey;
      }
      var successCallback = function(data) {

	      self.currentOrganization(data.currentOrganization);
	      // Currently ignoring the 'links' property as it is not being used and causing problems in ccLink custom binding
          ko.mapping.fromJS(data, {'ignore': ["links","dynamicProperties"]}, self);
          self.populateUserViewModel(data);
          self.resetModified();
          self.readyToDisplay(true);
          if (!isAutoLogin) {
            if(isLogin && (self.createOrderForLoggedInAtCheckout || !self.loggedinAtCheckout())) {
              $.Topic(pubSub.topicNames.CART_READY).subscribe(function(){
                $.Topic(pubSub.topicNames.USER_LOAD_SHIPPING).publish([{message: "success"}]);
                $.Topic(pubSub.topicNames.CART_READY).unsubscribe();
              });
            } else {
              $.Topic(pubSub.topicNames.USER_LOAD_SHIPPING).publish([{message: "success"}]);
            }
          } else {
            $.Topic(pubSub.topicNames.AUTO_LOGIN_AND_GET_USER_DATA_SUCCESSFUL).publish();
          }
          if (self.sessionExpiredProfileRedirected) {
            isLogin = true;
            self.sessionExpiredProfileRedirected = false;
          }
          if(isLogin) {
            if(data.parentOrganization && data.parentOrganization.name) {
              if(!navigation.isPathEqualTo(self.checkoutHash)) {
                $.Topic(pubSub.topicNames.CART_READY).subscribe(self.navigateToHome());
              }
            } 

            $.Topic(pubSub.topicNames.USER_LOAD_CART).publish(data);
          }
          if(typeof pSuccessCallback != "undefined" && pSuccessCallback){
            pSuccessCallback(data);
          }
        };
        
        var errorCallback = function(data) {
          if (data.status == CCConstants.HTTP_UNAUTHORIZED_ERROR) {
            self.handleSessionExpired();
            if (navigation.isPathEqualTo(self.contextData.global.links.profile.route) || navigation.isPathEqualTo(self.contextData.global.links.orderHistory.route)) {
              navigation.doLogin(navigation.getPath(), self.contextData.global.links.home.route);
            }
          }
          if(typeof pFailureCallback != "undefined" && pFailureCallback){
        	  pFailureCallback(data);
          }
        }
      
      // For agent trigger another endpoint and parse success response 
      if(ccRestClient.profileType == CCConstants.PROFILE_TYPE_AGENT) {
    	ccRestClient.request(CCConstants.ENDPOINT_GET_CUSTOMER, {}, function(userData){
    		var profileDetail = userData.profileDetail;
    		profileDetail.dynamicProperties = userData.dynamicProperties;
    		profileDetail.priceListGroup.id = profileDetail.priceListGroup.repositoryId;
    		successCallback(profileDetail);
    	}, errorCallback, self.id());
      }else {
        self.adapter.loadJSON('getUser', 'getCurrentUser', params, successCallback, errorCallback);  
      }
      

      // Utility function to handle navigation to the home page when a B2B user logs in.
      self.navigateToHome = function () {

        // Give the cart some time to load the user data before navigating to the new page.
        setTimeout(function () {
          var path = self.pageToRedirectAfterLogin();
          //If undefined or blank redirect path is given, refresh the current page
          if(path == undefined || path == ''){
            path = navigation.getPathWithoutLocale();
          }
          self.navigateToPage(path, function () {
            if (self.redirectNotificationMessage() !== '') {
              notifier.sendWarningToPage('UserViewModel', self.redirectNotificationMessage(), true, 'home', true);
              self.redirectNotificationMessage('');
            }
          });
        }, 2000);

      };
    };
    
    /**
     * Populate the user view model.
     * 
     * @function
     * @name UserViewModel#populateUserViewModel
     * @param {Object} userData Object containing source user data.
     */
    UserViewModel.prototype.populateUserViewModel = function(userData) {
      var self = this;
      self.emailAddress(userData.email);
      self.firstName(userData.firstName);
      self.lastName(userData.lastName);
      self.loggedInUserName(userData.firstName);
      self.login(self.emailAddress());
      self.GDPRProfileP13nConsentGranted(userData.GDPRProfileP13nConsentGranted);
      self.customerContactId(userData.customerContactId);
      var recieveEmail = userData.receiveEmail;
      if (recieveEmail == "yes") {
        self.emailMarketingMails(true);
      } else {
        self.emailMarketingMails(false);
      }
      self.id(userData.id);
      self.approvalRequired(userData.derivedApprovalRequired);
      self.orderPurchaseLimit(userData.derivedOrderPriceLimit);
      self.isPunchout(userData.isPunchout?userData.isPunchout:false);
      self.locale("");
      self.isDelegatedAdmin(false);
      self.isAccountAddressManager(false);
      self.isProfileAddressManager(false);
      self.isApprover(false);
      self.comments(userData.comments);
      if(userData.catalog) {
        self.catalogId(userData.catalog.repositoryId);
      }
      
      //set loyalty programs
      self.loyaltyPrograms(userData.loyaltyPrograms);
      // Added this to be backward compatible.
      if(self.catalog) {
        self.catalog = undefined;
      }
      
      self.profileType(userData.profileType);

      if(userData.parentOrganization){
        //Refer CCSF-17937
        ko.mapping.fromJS(userData.parentOrganization, {}, self.defaultPrimaryOrganization);
        self.defaultPrimaryOrganization.id(userData.parentOrganization.repositoryId);
      }

      if(userData.currentOrganization){
            self.currentOrganization(userData.currentOrganization);
     	  	if(self.parentOrganization == null){
     	  	  self.parentOrganization = new ParentOrganisation();
     	  	}
     	  	ko.mapping.fromJS(userData.currentOrganization, {}, self.parentOrganization);
     	  	self.parentOrganization.id(userData.currentOrganization.repositoryId);
     	  	var localOrganizationId = JSON.parse(ccRestClient.getStoredValue(CCConstants.LOCAL_STORAGE_ORGANIZATION_ID));
		    // if organization id is not present in localStorage, persist the current organization to local storage
		    if (!localOrganizationId && userData.currentOrganization) {

		      ccRestClient.setStoredValue(CCConstants.LOCAL_STORAGE_ORGANIZATION_ID, 
			   		ko.toJSON(userData.currentOrganization.repositoryId));
		    }
      }else if (userData.parentOrganization && userData.parentOrganization.active) {
        /*
         * The parentOrganzition is expected to be active for the SF/Agent Application for
         * rendering the details for the customer.
         */
        if(!self.parentOrganization){
          self.parentOrganization = new ParentOrganisation();
        }
        ko.mapping.fromJS(userData.parentOrganization, {}, self.parentOrganization);
        self.parentOrganization.id(userData.parentOrganization.repositoryId);
        self.currentOrganization(userData.parentOrganization);
      } else if(userData.secondaryOrganizations && userData.secondaryOrganizations.length > 0){
         // WHen parent orgaization is not set in admin and profile is associated to other organization,
         // then one of the active organization is set to default
         var secondaryOrganizations = userData.secondaryOrganizations;
         for(var i = 0 ; i<secondaryOrganizations.length; i++){
           if(secondaryOrganizations[i].active){
             if(!self.parentOrganization){
               self.parentOrganization = new ParentOrganisation();
             }
             ko.mapping.fromJS(userData.secondaryOrganizations[i], {}, self.parentOrganization);
             self.parentOrganization.id(userData.secondaryOrganizations[i].repositoryId);
             self.currentOrganization(userData.secondaryOrganizations[i]);
             break;
           }
         }
       }else {
        self.parentOrganization = null;
        self.currentOrganization(null);
      }
      
      // set active status
      self.active(userData.active);
      
      if(userData.roles && userData.roles.length > 0) {
        var roleLength = userData.roles.length;
        var currentOrgId = self.currentOrganization() ?  self.currentOrganization().repositoryId : null;
        self.resetRoles();
        var rolesForCurrentOrg = [];
        for(var role = 0; role < roleLength; role++) {
          self.roles.push(userData.roles[role]);
          var relativeTo = userData.roles[role]["relativeTo"];          
          if((typeof(relativeTo) === "object" && relativeTo.repositoryId === currentOrgId) || relativeTo === currentOrgId){
        	rolesForCurrentOrg.push(userData.roles[role]["function"]);
            if(userData.roles[role]["function"] === "accountAddressManager"){
              self.isAccountAddressManager(true);
            }
            if(userData.roles[role]["function"] === "profileAddressManager"){
              self.isProfileAddressManager(true);
            }
          if(userData.roles[role]["function"] === "admin"){
            self.isDelegatedAdmin(true);
          } else if(userData.roles[role]["function"] === "approver") {
            self.isApprover(true);
          }
        }}
        self.rolesForCurrentOrganization(rolesForCurrentOrg);
      }
      if(self.isB2BUser() && (self.isDelegatedAdmin() || ccRestClient.profileType == CCConstants.PROFILE_TYPE_AGENT)) {
        //Currently hardcoded for first organization, as multiple organization is out of scope.
      if(ccRestClient.profileType === CCConstants.PROFILE_TYPE_AGENT){
         self.contextManager = require("agentViewModels/agent-context-manager").getInstance();
         self.contextManager.setShopperProfileId(self.id());
         // The currentOrganizationId needs to be set in agentHeader as user might want to retrieve the secondaryorganization details
         // during refresh or bookmarked URLs. If OrganizationId is not passed in the header and the organization is user's secondary
         // organization, endpoint returns an error as "User {id} is not associated to any active account".
         self.contextManager.setCurrentOrganizationId(self.parentOrganization ? self.parentOrganization.id() : null);
       }
        ccRestClient.request(CCConstants.ENDPOINT_GET_ORGANIZATION, null,
          //success callback
          function(data){
        	self.defaultShippingAddressType(data.derivedShippingAddressType);
        	self.defaultBillingAddressType(data.derivedBillingAddressType);
        	if (self.organizations && self.organizations().length > 0) {
              self.organizations()[0].populateOrganizationViewModel(data);
        	} else {
              self.organizations.push(new Organization(data, self.adapter));
        	}
            //Populate dynamic property meta information for organization view model.
            if (self.organizations && self.organizations().length > 0) {
              self.organizations()[0].populateDynamicPropertiesMetaData();
            }
            self.currentOrganizationDetails(self.organizations()[0]);
          },
          //error callback
          function(data) {
            if (data.status == CCConstants.HTTP_UNAUTHORIZED_ERROR) {
              self.handleSessionExpired();
              if (navigation.isPathEqualTo(self.contextData.global.links.profile.route) || navigation.isPathEqualTo(self.contextData.global.links.orderHistory.route)) {
                navigation.doLogin(navigation.getPath(), self.contextData.global.links.home.route);
              }
            }
          },
          self.parentOrganization.id());
      }
      self.selectedPriceListGroup(userData.priceListGroup);
      var activePriceListGroups = self.contextData.global.site.priceListGroup.activePriceListGroups;
      if (!self.isB2BUser() && self.selectedPriceListGroup()) {
        var isActive = false;

        if(ccRestClient.getStoredValue(CCConstants.LOCAL_STORAGE_PRICELISTGROUP_ID)) {
          var storedPriceListGroupId = JSON.parse(ccRestClient.getStoredValue(CCConstants.LOCAL_STORAGE_PRICELISTGROUP_ID));
          // Check whether the selected price list group is still active
          for (var i =  0; i < activePriceListGroups.length; i++) {
            if (storedPriceListGroupId && storedPriceListGroupId == activePriceListGroups[i].id) {
              self.selectedPriceListGroup(activePriceListGroups[i]);
              isActive = true;
              break;
            }
          }
        }
        if (!isActive) { // If the selected price list group is not active then set default price list group
          self.selectedPriceListGroup(self.contextData.global.site.priceListGroup.defaultPriceListGroup);
        }
      }
      
      ccRestClient.setStoredValue(CCConstants.LOCAL_STORAGE_PRICELISTGROUP_ID, ko.toJSON(self.selectedPriceListGroup().id));
      self.previousVisitDate(userData.previousVisitDate);
      
      // Reset KO modified properties  
      self.deleteShippingAddress(false);    
      self.updatedShippingAddress = userData.shippingAddress; // This is the default shipping address the server sends back
      if (self.updatedShippingAddress) {
        self.updatedShippingAddress.isDefaultAddress = true;
      }
      
      self.updatedShippingAddressBook = userData.shippingAddresses;
      if (self.updatedShippingAddressBook && self.updatedShippingAddressBook.length) {
        if(self.updatedShippingAddress == null) {
             //Setting the entry in address book to default address when null is being returned
             self.updatedShippingAddressBook[0].isDefaultAddress = true;
        } else {
        for (var k = 0, kMax = self.updatedShippingAddressBook.length; k < kMax; k++) {
          self.updatedShippingAddressBook[k].isDefaultAddress = 
              (self.updatedShippingAddressBook[k].repositoryId === self.updatedShippingAddress.repositoryId);
          }
        }
      }

      self.organizationAddressBook = [];
	  for(var addressIndex in userData.secondaryAddresses){
	    var shippingAddress = userData.secondaryAddresses[addressIndex];
		shippingAddress.isDefaultAddress=false;
	    self.organizationAddressBook.push(userData.secondaryAddresses[addressIndex]);
	  }
	  
	  self.contactShippingAddress = userData.contactShippingAddress;
      self.primaryShippingAddress(self.contactShippingAddress);
      self.contactBillingAddress = userData.contactBillingAddress;

      self.sortShippingAddresses();
      
      self.isUserSessionExpired(false);
      self.isUserLoggedOut(false);
      self.populateUserFromLocalData(false);
      self.setLocalData('all');
      
      //Assigning profile locale after checking if it is part of supported locales.
      //Update the user locale if it not supported.
      for (var i=0; i<this.supportedLocales.length; i++) {
          if (userData.locale === this.supportedLocales[i].name) {
            self.locale(userData.locale);
            if (self.locale.isModified !== undefined) {
              self.locale.isModified(false);
            }
          }
        }
      if (!self.locale()) {
        self.updateLocaleToSupported();
      }

      self.processDynamicPropertiesMetadata(userData.dynamicProperties);
      self.resetDynamicPropertiesValueIsModified();
    };
    
    /**
     * Clear special roles assigned to B2B user for the organization to set again
     */
    UserViewModel.prototype.resetRoles = function(){
      var self = this;
      self.roles([]);
      self.isAccountAddressManager(false);
      self.isProfileAddressManager(false);
      self.isDelegatedAdmin(false);
      self.isApprover(false);
    };
    
    /**
     * Set the context data from the UserDataInitializer.
     * 
     * @function
     * @name UserViewModel#setContext
     * @param {Object} pContext Context Data
     */
    UserViewModel.prototype.setContext = function(pContext) {
      var self = this;
      self.contextData = pContext;
      self.myAccountHash = self.contextData.global.links.profile.route;
      self.checkoutHash = self.contextData.global.links.checkout.route;
      self.homeHash = self.contextData.global.links.home.route;
      self.passwordPolicies(pContext.global.passwordPolicies);
      self.readyToDisplay(true);
      if(pContext.global.user.catalog) {
        self.catalogId(pContext.global.user.catalog.repositoryId);
      }
      // Added this to be backward compatible.
      if(self.catalog) {
        self.catalog = undefined;
      }

      // Set the supported locales in the user view model
      if (pContext.global.supportedLocales && pContext.global.supportedLocales.length > 0) {
        self.supportedLocales = pContext.global.supportedLocales;
      } else {
        self.supportedLocales = pContext.global.site.additionalLanguages;
      }

        if (pContext.global.user.firstName) {
          self.populateUserViewModel(pContext.global.user);
        } else {
          self.populateUserFromLocalData(true);
          if (!self.isUserLoggedOut() && !self.loggedIn() && !self.isUserSessionExpired() && self.login() != undefined && self.login() !='') {
            self.handleSessionExpired();
          }
        }
    };
    
    /**
     * Refreshes the data in the user view modal with the current user when the page changes. Invoked on
     * receiving a PAGE_LAYOUT_LOADED, or PAGE_METADATA_CHANGED pubsub event.
     * Exclude refresh cart, if it is triggered on login.
     * 
     * @private
     * @function
     * @name UserViewModel#handPageChanged
     */
    UserViewModel.prototype.handlePageChanged = function(layout, event) {
      var self = this;
      var isFreshPageLoad = self.storeConfiguration.isFreshPageLoad;

      var unSavedCart =false;
      if(self.storeConfiguration.isLargeCart() === true && isFreshPageLoad === true){
        var cookieData = storageApi.getInstance().getItem("shoppingCart");
        if (cookieData && typeof cookieData == 'string') {
          cookieData = JSON.parse(cookieData);
          if(cookieData.userActionPending === true){
            unSavedCart = true; 
          }
        }
      } 
      //Note for large cart :Do not  refresh the cart in case of page navigation ,when large cart is active 
      //Note for large cart :Don't load the cart from server side ,if its page refresh and there is unsaved cart on server 
      //Note for large cart : programmatically possible /application driven refresh scenarios will 
      //have saved the cart before refresh after the shopper choice
      //if (!self.isPageRedirected() && self.loggedIn() && !self.loggedinAtCheckout() && !window.isAgentApplication) {}
      if ((!self.isPageRedirected() && self.loggedIn() && (self.createOrderForLoggedInAtCheckout || !self.loggedinAtCheckout()) && !event.onLogin && !window.isAgentApplication && !self.storeConfiguration.isLargeCart() 
          && !self.storeConfiguration.largeCartConfig.suppressPageChangeValidations) 
          ||(self.storeConfiguration.isLargeCart() === true && isFreshPageLoad === true && unSavedCart ===false) ) {
        $.Topic(pubSub.topicNames.REFRESH_USER_CART).publish(self);
      }
      self.isPageRedirected(false);
    };

    /**
     * Handle session expiry- reset all data and reload login data from local storage. Invoked on
     * receiving a USER_SESSION_EXPIRED pubsub event.
     * 
     * @private
     * @function
     * @name UserViewModel#handleSessionExpired
     */
    UserViewModel.prototype.handleSessionExpired = function() {
      var self = this;
      storageApi.getInstance().removeItem(CCConstants.LOCAL_STORAGE_SHOPPER_CONTEXT);
      storageApi.getInstance().removeItem(CCConstants.LOCAL_STORAGE_CURRENT_CONTEXT);
      if (!self.isUserSessionExpired() && !self.isUserLoggedOut()) {
        self.loggedIn(false);
        if (!navigation.isPathEqualTo(self.myAccountHash) && self.isSesExpDuringPlaceOrder() == false) {       
          self.resetLoginData();
          self.reset();
          self.id(null);
        }
        if (!navigation.isPathEqualTo(self.checkoutHash) && !(navigation.getPath().indexOf(self.checkoutHash) >= 0)) {
          $.Topic(pubSub.topicNames.USER_CLEAR_CART).publish(self);
        }
        self.populateUserFromLocalData(true);
        self.orderId('');
        self.loggedinAtCheckout(false);
        self.loggedoutAtCheckout(false);
        self.isUserSessionExpired(true);
        self.isUserLoggedOut(false);
        self.setLocalData('sessionExpiry');
        self.readyToDisplay(true);
      }
    };
    
    /**
     * Handle session validation. Invoked on receiving a USER_SESSION_VALID pubsub event.
     * 
     * @private
     * @function
     * @name UserViewModel#handleSessionValid
     */
    UserViewModel.prototype.handleSessionValid = function() {
      var self = this;
      self.loggedIn(self.client().loggedIn);
      self.isUserSessionExpired(false);
      self.isUserLoggedOut(false);
      self.setLocalData('sessionExpiry');
      self.readyToDisplay(true);
    };
    
    /**
     * Check if user is allowed to navigate to privileged pages and redirect if not. Will do nothing
     * if user profile has unsaved changes.
     * 
     * @function
     * @name UserViewModel#validatePrivilagePageRequest
     * @param {Object} data The object bound to the event.
     * @param {Object} event The current event.
     * @returns {boolean} true if the user profile has unsaved changes, otherwise false
     */
   UserViewModel.prototype.validatePrivilagePageRequest = function(data, event) {
      var self = this;
      // returns if the profile has unsaved changes.
      if (self.isUserProfileEdited()) {
        return true;
      }
      var hash = event.currentTarget.pathname;
      var link = '';
      var index = hash.indexOf("#!");
      if (index >= 0) {
        hash = hash.substring(index + 2);
      }
      self.validateAndRedirectPage(hash);
      return false;
    };
    
    /**
     * Navigates to the page based on the given hash.If given hash and current path is same
     * then it refreshes the current layout with current page id else navigates to the page
     * based on the given hash.
     *
     * @function
     * @name UserViewModel#navigateToPage
     * @param {string} hash page to refresh or redirect to.
     */
    UserViewModel.prototype.navigateToPage = function(hash, callback) {
      if(navigation.isPathEqualTo(hash)) {
        var eventData = {
          'pageId': navigation.getPath()
        };
        $.Topic(pubSub.topicNames.PAGE_VIEW_CHANGED).publish(eventData);
      } else {
        navigation.goTo(hash);

        if (callback) {
          callback();
        }
      }
    };

    /**
     * Check user privilege and redirect based on hash.
     * 
     * @private
     * @function
     * @name UserViewModel#validateAndRedirectPage
     * @param {string} hash Redirection hash code.
     * @returns {boolean} Always false.
     */
    UserViewModel.prototype.validateAndRedirectPage = function(hash) {
      var redirectLinkDetails, self = this;
      if (!(hash == CCConstants.PAYPAL_CHECKOUT_TYPE)) {
        redirectLinkDetails = [{message: "success", linkToRedirect: hash}];
      }
      if (!self.loggedIn() && self.isUserSessionExpired()) {
        if (hash == CCConstants.PAYPAL_CHECKOUT_TYPE && navigation.isPathEqualTo(self.checkoutHash)) {
          $.Topic(pubSub.topicNames.CONTINUE_TO_PAYPAL).publish();
        } else {
          navigation.doLogin(hash);
        }
      } else if (self.loggedIn()) {
        var successFunc = function() {
          self.handleSessionValid();
          if (self.pageToRedirect() && self.pageToRedirect()!= '' && self.pageToRedirect() == hash) {
            self.pageToRedirect(null);
          }
          if (hash == CCConstants.PAYPAL_CHECKOUT_TYPE) {
            $.Topic(pubSub.topicNames.CONTINUE_TO_PAYPAL).publish();
          } else {
            navigation.goTo(hash);
          }
        };
        var errorFunc = function(pResult) {
          if (pResult) {
            self.handleSessionExpired();
            navigation.doLogin(hash);
          }
        };
        self.client().refresh(successFunc, errorFunc);
      } else {
        navigation.goTo(hash);
      }
      return false;
    };
    
    /**
     * Update the user profile details. Invoked on receiving a USER_PROFILE_UPDATE_SUBMIT pubsub event.
     * 
     * @function
     * @name UserViewModel#updateUserProfile
     */
    UserViewModel.prototype.handleUpdateProfile = function() {
      var self = this;
      var inputParams = {};
      var isDataInValid = false;
      var isDataModified = false;
      var includeUserProfile = false;
      var includeDynamicProperties = false;
      var includeUserPassword = false;
      var includeShippingAddress = false;
      // checking whether user profile is modified/valid.
      if (self.isProfileModified()) {
        if (!self.isProfileValid()) {
          isDataInValid = true;
        } else {
          includeUserProfile = true;
        }
        isDataModified = true;
      }
      // checking whether user password is modified/valid
      if (self.isPasswordModified()) {
        if (!self.isPasswordValid()) {
          isDataInValid = true;
        } else {
          includeUserPassword = true;
        }
        isDataModified = true;
      }
      // If we're updating because of a change to a shipping address...
      if(self.editShippingAddress() != null)
      {
        // Check if it's a new shipping address and add it to the address book.
        if ($.inArray(self.editShippingAddress(), self.shippingAddressBook()) < 0) {
          self.shippingAddressBook.push(self.editShippingAddress());
        }
        // If this was the first shipping address added, make it automatically the default.
        if (self.shippingAddressBook().length === 1) {
          self.shippingAddressBook()[0].isDefaultAddress(true);
        }
        if (self.editShippingAddress().isDefaultAddress()) {
          self.selectDefaultAddress(self.editShippingAddress());
        }
      }
      // checking whether shipping address is modified/valid.
      if (self.isShippingAddressBookModified()) {
        for (var k = 0; k < self.shippingAddressBook().length; k++) {
          if (!self.shippingAddressBook()[k].validateNow()) {
            isDataInValid = true;
            break;
          }
        }
        includeShippingAddress = !isDataInValid; // Because addresses are validated first!
        isDataModified = true;
      }
      // checking whether dynamic properties are modified/valid
      if (self.dynamicProperties().length > 0) {
        if (self.isDynamicPropertiesModified()) {
          for ( var i = 0; i < self.dynamicProperties().length; i++) {
            var dynProp = self.dynamicProperties()[i];
            if (!dynProp.isValid()) {
              isDataInValid = true;
              break;
            }
          }
          if (!isDataInValid) {
            includeDynamicProperties = true;
          }
          isDataModified = true;
        }
      }
      
      // if user data is modified then update user
      if(self.isUserDataModified()){
        isDataModified = true;
      }
      
       if(!isDataModified) {
        // If data is not modified, show the view profile page.
        $.Topic(pubSub.topicNames.USER_PROFILE_UPDATE_NOCHANGE).publish();
        return;
       }else if (isDataInValid) {
        // If data is invalid, show error message.
        $.Topic(pubSub.topicNames.USER_PROFILE_UPDATE_INVALID).publish();
        return;
       }
       if(includeUserProfile){
         self.handleAccountDetailsUpdate(inputParams);
       }
       if(includeDynamicProperties){
         self.handleDynamicPropertiesUpdate(inputParams);
       }
       if(includeUserPassword){
         self.handleUpdatePassword(inputParams);
       }
       if(includeShippingAddress){
         self.handleShippingAddressUpdate(inputParams);
       }
       // Update roles for the shopper by delegated admin  
       if(self.isB2BUser() && self.isDelegatedAdmin() && ccRestClient.profileType == CCConstants.PROFILE_TYPE_AGENT){
         self.updateRolesForCurrentOrganization(inputParams);
       }
       
       self.invokeUpdateProfile(inputParams);
    };
    
    /**
     * Method to populate the roles array with role name and relative To Id,
     * so that roles can be updated by delegated admin from customer details
     * page also.
     */
    UserViewModel.prototype.updateRolesForCurrentOrganization = function(inputParams) {
       var self = this;
       var rolesIds = [];
       var selectedRoles = self.rolesForCurrentOrganization();
       var organization = self.currentOrganization();
       for(var i = 0; i< selectedRoles.length; i++) {
         rolesIds.push({"function":selectedRoles[i],
           "relativeTo":{"id":organization.repositoryId}});
       }
       inputParams[CCConstants.ROLES_TEXT] = rolesIds;
    };
    
    /**
     * Update the user account details eg. first name , last name etc .
     * 
     * @function
     * @name UserViewModel#handleAccountDetailsUpdate
     */
    UserViewModel.prototype.handleAccountDetailsUpdate = function(inputParams) {
      var self = this;
      self.receiveEmail = self.emailMarketingMails() ? "yes" : "no";
      inputParams[CCConstants.PROFILE_EMAIL] = self.emailAddress();
      inputParams[CCConstants.PROFILE_FIRST_NAME] = self.firstName();
      inputParams[CCConstants.PROFILE_LAST_NAME] = self.lastName();
      inputParams[CCConstants.PROFILE_RECEIVE_EMAIL] = self.receiveEmail;
      inputParams[CCConstants.PROFILE_LOCALE] = self.locale();
      inputParams[CCConstants.PROFILE_GDPR_CONSENT_GRANTED_KEY] = self.GDPRProfileP13nConsentGranted();
    }
    /**
     * Update the dynamic properties
     * 
     * @function
     * @name UserViewModel#handleDynamicPropertiesUpdate
     */
    UserViewModel.prototype.handleDynamicPropertiesUpdate = function(inputParams) {
       var self = this;
       for (var i = 0; i < self.dynamicProperties().length; i++) {
          if (self.dynamicProperties()[i].value.isModified && self.dynamicProperties()[i].value.isModified()) {
            inputParams[self.dynamicProperties()[i].id()] = self.dynamicProperties()[i].value();
          }
        }
    }
    /**
     * Update the user password update
     * 
     * @function
     * @name UserViewModel#handleUpdatePassword
     */
    UserViewModel.prototype.handleUpdatePassword = function(inputParams) {
        var self = this;
        inputParams[CCConstants.PROFILE_OLD_PASSWORD] = self.oldPassword();
        inputParams[CCConstants.PROFILE_NEW_PASSWORD] = self.newPassword();
        inputParams[CCConstants.PROFILE_CONFIRM_PASSWORD] = self.confirmPassword();
    }
    /**
     * Update the shipping address update
     * 
     * @function
     * @name UserViewModel#handleShippingAddressUpdate
     */
    UserViewModel.prototype.handleShippingAddressUpdate = function(inputParams) {
        var self = this;
        inputParams[CCConstants.PROFILE_SHIPPING_ADDRESSES] = self.shippingAddressBook();
    }
    /**
     * Update call for any profile data update 
     * 
     * @function
     * @name UserViewModel#invokeUpdateProfile
     */
    UserViewModel.prototype.invokeUpdateProfile = function(inputParams) {
      var self = this;
      self.adapter.loadJSON(CCConstants.ENDPOINT_UPDATE_PROFILE, self.id(),inputParams,
        //success callback 
        function(data) {
          self.editShippingAddress(null);
          self.resetPassword();
          self.resetDynamicPropertiesValueIsModified();
          $.Topic(pubSub.topicNames.USER_PROFILE_UPDATE_SUCCESSFUL).publish(data);
        },
        //error callback
        function(data) {
          var currentLocale = ccRestClient.getStoredValue(CCConstants.LOCAL_STORAGE_USER_CONTENT_LOCALE);
          //In case of update profile failure, revert back the changes in locale of viewmodel with the value in local storage
          if(currentLocale) {
        	  var oldLocale = JSON.parse(currentLocale)[0].name;
            if(self.locale() !== oldLocale) {
              self.locale(oldLocale);
            }
          } else {
            self.locale(self.contextData.global.locale);
          }
          self.resetPassword();
          $.Topic(pubSub.topicNames.USER_PROFILE_UPDATE_FAILURE).publish(data);
        }
      );
    };
   
    /**
     * Change the Profile Password.
     * 
     * @function
     * @name UserViewModel#updateExpiredPassword
     */
    UserViewModel.prototype.updateExpiredPassword = function() {
      var self = this;
      var inputParams = {};
      inputParams[CCConstants.PROFILE_LOGIN] = self.login();
      inputParams[CCConstants.PROFILE_OLD_PASSWORD] = self.oldPassword();
      inputParams[CCConstants.PROFILE_NEW_PASSWORD] = self.newPassword();
      inputParams[CCConstants.PROFILE_CONFIRM_PASSWORD] = self.confirmPassword();
      self.adapter.persistCreate(CCConstants.ENDPOINT_UPDATE_EXPIRED_PASSWORD, 'id', inputParams,
        //success callback 
        function(data) {
          $.Topic(pubSub.topicNames.USER_PROFILE_PASSWORD_UPDATE_SUCCESSFUL).publish(data);
          var currentPassword = self.newPassword();
          self.resetPassword();
          self.password(currentPassword);
        },
        //error callback
        function(data) {
          self.resetPassword();
          $.Topic(pubSub.topicNames.USER_PROFILE_PASSWORD_UPDATE_FAILURE).publish(data);
        }
      );
    };
    
    /**
     * Validates the token provided and makes sure that the token
     * is valid before updating a forgotten/expired password.
     * This is not a mandatory step but is recommended to make
     * sure that extra steps are not performed if the token
     * itself is not valid.
     * 
     * @function
     * @name UserViewModel#validateTokenForPasswordUpdate
     * @param {string} token The token to validate.
     * @param {function} success The success function. This will be of the form success(data).
     *                   The data would only contain success as the key and true as a value.
     *                   e.g. {"success" : true}
     * @param {function} error The error function. This will be of the form error(data).
     *                   The data contains the error information.
     *                   e.g. {"code": "83037", "message": "Resetting this account''s password is not allowed. Check the link in your email and try again."}
     *                   The typical errors would be:
     *                   83037: If there was a problem while validating the token.
     */
    UserViewModel.prototype.validateTokenForPasswordUpdate = function(token, success, error) {
      var self = this;
      // The token is double encoded to make is proper for emails. Need to double
      // decode it.
      token = decodeURIComponent(decodeURIComponent(token));
      // Set it on the viewmodel
      self.token = token;
      // Get the input data necessary. This should just be token and operation.
      var inputParams = {};
      inputParams[CCConstants.OP] = CCConstants.VALIDATE_TOKEN_OPERATION;
      inputParams[CCConstants.TOKEN] = token;
      self.adapter.persistCreate(CCConstants.ENDPOINT_UPDATE_EXPIRED_PASSWORD, 'id', inputParams,
        // success callback
        function(data) {
          // Check the success callback and allow the implementation to proceed.
          if (typeof success === "function") {
            success(data);
          }
        },
        // error callback
        function(data) {
          self.token = "";
          // Check the error callback and allow the implementation to proceed.
          if (typeof error === "function") {
            error(data);
          }
        }
      );
    };
    
    /**
     * Updates the expired/forgotten password provided the token,
     * login, password, confirm password. This method should be used in case token
     * is used to update the password.
     * 
     * @function
     * @name UserViewModel#updateExpiredPasswordUsingToken
     * @param {string} token The token to validate
     * @param {string} login The login id of the user
     * @param {string} password The password of the user
     * @param {string} confirmPassword The confirm password field to validate the password against
     * @param {function} success The success function. his will be of the form success(data).
     *                   The data would only contain success as the key and true as a value.
     *                   e.g. {"success" : true}
     * @param {function} error The error function. This will be of the form error(data).
     *                   The data contains the error information.
     *                   e.g. {"code": "83037", "message": "Resetting this account''s password is not allowed. Check the link in your email and try again."}
     *                   The typical errors would be:
     *                   83037: If there was a problem while validating the token.
     *                   23026: If the user input is null or empty.
     *                   83039: If the password and confirm password do not match.
     *                   83032: If there is an error while updating the password.
     */
    UserViewModel.prototype.updateExpiredPasswordUsingToken = function(token, login, password, confirmPassword, success, error) {
      var self = this;
      var inputParams = {};
      inputParams[CCConstants.TOKEN] = token;
      inputParams[CCConstants.PROFILE_LOGIN] = login;
      inputParams[CCConstants.PROFILE_PASSWORD] = password;
      inputParams[CCConstants.PROFILE_PASSWORD_CONFIRM] = confirmPassword;
      self.adapter.persistCreate(CCConstants.ENDPOINT_UPDATE_EXPIRED_PASSWORD, 'id', inputParams,
        //success callback 
        function(data) {
          // Sending the pubsub for backward compatibility
          $.Topic(pubSub.topicNames.USER_PROFILE_PASSWORD_UPDATE_SUCCESSFUL).publish(data);
          // Clearing the details
          self.resetPassword();
          self.token = "";
          // Check the success callback and allow the implementation to proceed.
          if (typeof success === "function") {
            success(data);
          } 
        },
        //error callback
        function(data) {
          // Clearing the details
          self.resetPassword();
          // Clear the email
          self.emailAddressForForgottenPwd('');
          // Sending the pubsub for backward compatibility
          $.Topic(pubSub.topicNames.USER_PROFILE_PASSWORD_UPDATE_FAILURE).publish(data);
          // Check the error callback and allow the implementation to proceed.
          if (typeof error === "function") {
            error(data);
          }
        }
      );
    };

    /**
     * Create a new product back in stock notification for the user
     *
     * @param obj JSON representing notification object to create
     */
    UserViewModel.prototype.createProductNotification = function(obj){
      var self = this;

      self.adapter.persistCreate(CCConstants.ENDPOINT_PRODUCT_NOTIFY, 'id', obj,
        //success
        function(data){
          $.Topic(pubSub.topicNames.USER_PRODUCT_NOTIFICATION_SUCCESS).publish(data);
        },
        //error
        function(data){
          $.Topic(pubSub.topicNames.USER_PRODUCT_NOTIFICATION_FAILED).publish(data);
        });
    };

    /**
     * Return the singleton global instance of UserViewModel. Creates it if it doesn't already exist.
     * 
     * @function
     * @name UserViewModel.getInstance
     * @param {RestAdapter} pAdapter REST Adapter.
     * @param {Object} pUserData Aditional user data.
     * @param {Object} pParams Some additional params (server data).
     * @returns {UserViewModel} The user view model.
     */
    UserViewModel.getInstance = function(pAdapter, pUserData, pParams) {
      if (!UserViewModel.singleInstance) {
        UserViewModel.singleInstance = new UserViewModel(pAdapter, pUserData);
      } else {
        if ((!UserViewModel.singleInstance.loggedInUserName()) &&  pParams &&  pParams.global &&
                pParams.global.user && pParams.global.user.firstName) {
          UserViewModel.singleInstance.loggedInUserName(pParams.global.user.firstName);
        }
      }
      
      // To always populate the view model with latest data from server.
      if (pParams) {
        UserViewModel.singleInstance.setContext(pParams);
      }
      
      return UserViewModel.singleInstance;
    };
    
    /**
     * Get access token to redirect to store
     * @function 
     * @param pSuccessCallback 
     * @param pFailureCallback
     */
    UserViewModel.prototype.getOnBehlafOfToken = function(pSuccessCallback, pFailureCallback){
    	var self = this;
    	var inputData = {};
        inputData.shopperProfileId = self.id();
        ccRestClient.request(CCConstants.ON_BEHALF_OF, inputData, pSuccessCallback, pFailureCallback);
    };
    
    /**
     * Get site specific properties for user
     */
    UserViewModel.prototype.getSiteSpecificProperties = function(pActiveSites, pSuccessCallback, pFailureCallback){
      var self = this;
      var successCallback = function(pData){
        var flag = false;
        var exists = true;
        for(var j=0; j<pActiveSites.length; j++){
          var activeSite = pActiveSites[j];
          var found = false;
          for (var i=0; i<pData.items.length; i++) {
      	    if(activeSite.id == pData.items[i].site.id) {
      	      found = true;
      	      break;
      	    }
      	  }
          if(!found){
            exists = false;
            break
          }
        }
    	if(exists) {
    	  for(var i=0; i<pData.items.length; i++) {
    	    if(pData.items[i].properties.receiveEmail != "yes") {
    	      flag = false;
    	      break;
    	    }
    	    flag = true;
    	  }
    	  if(flag) {
    	    self.receiveEmailGlobally(true);
    	  } else {
            if(self.receiveEmailGlobally() != null || self.receiveEmailGlobally())
    	       self.receiveEmailGlobally(false);
    	    }
    	} else {
    	  if(self.receiveEmailGlobally() != null || self.receiveEmailGlobally())
    	    self.receiveEmailGlobally(false);
    	}
    	self.originalReceiveEmailGlobally = self.receiveEmailGlobally();
    	if(pSuccessCallback){
    	  pSuccessCallback();
    	}
      };
      var failureCallback = function(){
        if(pFailureCallback){
          pFailureCallback();
        }
      };
      ccRestClient.request(CCConstants.ENDPOINT_GET_SITE_SPECIFIC_PROFILE_PROPS,
              null, successCallback, failureCallback, self.id());
    };
    
    /**
     * update site global email preferences for customer      
     * 			
     */
    UserViewModel.prototype.updateSiteSpecificProperites = function(pActiveSites, pSuccessCallback, pErrorCallback) { 
      var self = this;
      var inputParams = {};
  	  var siteSpecific = [];
  	  var receieveEmailGlobal = "no";
  	  if(self.receiveEmailGlobally()){
  		  receieveEmailGlobal ="yes";
  	  }     
  	  for(var i = 0 ; i < pActiveSites.length ; i++ ){
  		  var  data = {};
  		  var sitedata = {};
  		  sitedata["id"] = pActiveSites[i].id;
  		  data["site"] = sitedata;
  		  var properties  = {};
  		  properties["receiveEmail"] = receieveEmailGlobal;
  		  data["properties"] = properties;
  		  siteSpecific.push(data);
  	  }
  	  inputParams["siteProperties"] = siteSpecific;
      ccRestClient.request(CCConstants.ENDPOINT_UPDATE_SITE_SPECIFIC_PROFILE_PROPS,
      inputParams, pSuccessCallback,
      pErrorCallback, self.id());
    };

    /**
     * Handles resending VerificationCode for the user login verification. Invoked on receiving a RESEND_VERIFICATION_CODE pubsub event.
     *
     * @private
     * @function
     * @param pSuccessCallback
     * @param pFailureCallback
     * @name UserViewModel#resendVerificationCode
     */
    UserViewModel.prototype.resendVerificationCode = function(pSuccessCallback,pFailureCallback) {
      var inputParams = {};
      inputParams[CCConstants.VALIDATION_TOKEN] =  storageApi.getInstance().getItem(CCConstants.VALIDATION_TOKEN);
      ccRestClient.request(CCConstants.ENDPOINT_RESEND_VERIFICATION_CODE,inputParams, pSuccessCallback, pFailureCallback);
    };

    return UserViewModel;
  }
  
);

