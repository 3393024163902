define(
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  'viewModels/inStorePayment',
  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  ['knockout', 'ccConstants', 'CCi18n', 'pageLayout/site', 'koMapping'],
  //-------------------------------------------------------------------
  // MODULE DEFINITION
  //-------------------------------------------------------------------
  function (ko, ccConstants, CCi18n, site, koMapping) {
    'use strict';
    
    function InStorePayment() {
      var self = this;
      
      self.paymentMethodType = ccConstants.IN_STORE_PAYMENT_TYPE;
      self.type = ccConstants.IN_STORE_PAYMENT_TYPE;
      self.amount = ko.observable();

      self.seqNum = null;
      self.isEdit = ko.observable(false);
      self.message = ko.observable();

      /**
       * Method to create additional properties for the inStore payment
       * @param {Array} PropertyKeys - An array of strings, containing the keys for additional properties
       */
      self.createCustomProperties = function(PropertyKeys) {
        var extData = {};
        for(var key in PropertyKeys) {
          if(typeof PropertyKeys[key] === "string") {
            extData[PropertyKeys[key]] = "";
          }
        }
        self.customProperties = ko.mapping.fromJS(extData);
      };
      
      /**
       * Convert an observable object into a plain javascript object, and remove fields
       * that aren't relevant for payment processing API request
       *
       * @returns {Object} New Object containing inStore payment data.
       */
      self.toJSON = function() {
        var oldOptions = koMapping.defaultOptions().ignore;
        koMapping.defaultOptions().ignore = ["triggerValidations"];
        var copy = koMapping.toJS(self);
        koMapping.defaultOptions().ignore = oldOptions;
        return copy;
      };

      /**
       * @function
       * @name resetPaymentDetails
       * @class inStorePayment
       * Clears the data and errors associated with this object.
       */
      self.resetPaymentDetails = function() {
        // There is no inStore payment specific fields that can be reset
      };

      /**
       * Force all relevant member observables to perform their
       * validation now & display the errors (if any)
       */
      self.validatePaymentData = function() {
        return true;
      };

    };
    
    return InStorePayment;

});
