/**
 * @fileoverview PromotionUpsellContainer
 *
 */

/*global define */
define(
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  'viewModels/promotionUpsellContainer',

  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  ['knockout', 'ccRestClient', 'ccConstants'],

  //-------------------------------------------------------------------
  // MODULE DEFINITION
  //-------------------------------------------------------------------
  function (ko, ccRestClient, ccConstants) {

    'use strict';

    //------------------------------------------------------------------
    // Class definition & member variables
    //------------------------------------------------------------------
    /**
     * Creates a PromotionUpsellContainer.
     *
     * @class Represents a container that contains the promotion upsell message list.
     * @name PromotionUpsellContainer
     *
     * @param {Array} promotionUpsellMessages List of promotion messages.
     * @param {boolean} isNonQualifiedMessagesRetrievalInProgress Indicates if an endpoint call is currently under progress to fetch the non qualified promotions.
     *                  If set to true, it will not initiate similar calls.
     * @param {boolean} isNonQualifiedMessagesSubscribedToQuantity Indicates if the endpoint calls have been subscribed to changes in cart quantity.
     *                  This variable is introduced to SKIP nonQualifiedPromotionMessage endpoint call from multiple widget instances across the site.
     *
     */
    function PromotionUpsellContainer() {
      var self = this;
      if (PromotionUpsellContainer.singleInstance) {
        throw new Error ("Cannot instantiate more than one PromotionUpsellContainer. Use getInstance() method");
      }
      self.promotionUpsellMessages = ko.observableArray();
      self.isNonQualifiedMessagesRetrievalInProgress = false;
      self.isNonQualifiedMessagesSubscribedToQuantity = false;
      return self;
    };

    /**
     * Method to get the instance of PromotionUpsellContainer.
     *
     * @name PromotionUpsellContainer#getInstance
     * @function
     * @return PromotionUpsellContainer Returns an instance of PromotionUpsellContainer.
     */
    PromotionUpsellContainer.getInstance = function () {
      if (!PromotionUpsellContainer.singleInstance) {
        PromotionUpsellContainer.singleInstance = new PromotionUpsellContainer();
      }
      return PromotionUpsellContainer.singleInstance;
    };

    /**
     * Success Callback of getUpsellMessages endpoint call, used to populate the non-qualified promotion upsell messages to the PromotionUpsellContainer.
     *
     * @name PromotionUpsellContainer#successFunc
     * @function
     * @param {Object} pResult Response object for the priceOrder endpoint call, which contains the non-qualified promotion upsell messages.
     */
    PromotionUpsellContainer.prototype.successFunc = function(pResult) {
      var self = this;
      if (pResult && pResult.promotionUpsellMessages) {
        self.populatePromotionUpsellMessages(pResult.promotionUpsellMessages);
      }
      self.isNonQualifiedMessagesRetrievalInProgress = false;
    }

    /**
     * Error Callback of getUpsellMessages endpoint call, which logs the error to the console.
     *
     * @name PromotionUpsellContainer#errorFunc
     * @function
     * @param {Object} pResult Response object for the priceOrder endpoint call, which contains the non-qualified promotion upsell messages.
     */
    PromotionUpsellContainer.prototype.errorFunc = function(pResult) {
      var self = this;
      console.error("Unable to retrieve non-qualifier messages.");
      self.isNonQualifiedMessagesRetrievalInProgress = false;
    }

    /**
     * Method to remove all the promotionUpsellMessages from PromotionUpsellContainer.
     *
     * @name PromotionUpsellContainer#clearPromotionUpsellMessages
     * @function
     */
    PromotionUpsellContainer.prototype.clearPromotionUpsellMessages = function() {
      var self = this;
      if(null != self.promotionUpsellMessages && self.promotionUpsellMessages().length > 0) {
        self.promotionUpsellMessages.removeAll();
      }
    }

    /**
     * Method to make an endpoint call to getUpsellMessages endpoint to get the list of non-qualified upsell promotion messages
     * and sets them in the PromotionUpsellContainer.
     *
     * @name PromotionUpsellContainer#getNonQualifiedMessages
     * @function
     */
    PromotionUpsellContainer.prototype.getNonQualifiedMessages = function() {
      var self = this;
      if(!self.isNonQualifiedMessagesRetrievalInProgress) {
        self.clearPromotionUpsellMessages();
        var url = ccConstants.GET_UPSELL_MESSAGES;
        self.isNonQualifiedMessagesRetrievalInProgress = true;
        var inputObj = new Object();
        ccRestClient.request(url, inputObj, self.successFunc.bind(this), self.errorFunc.bind(this));
      }
    }
    /**
     * Method to populate the list of promotion upsell messages in the PromotionUpsellContainer, after removing the previous messages.
     *
     * @name PromotionUpsellContainer#populatePromotionUpsellMessages
     * @function
     * @param {Array} serverPromotionUpsellMessageList List of promotion upsell messages that are returned by the server and are applicable to the current cart.
     */
    PromotionUpsellContainer.prototype.populatePromotionUpsellMessages = function (serverPromotionUpsellMessageList) {
      var self = this;
      self.clearPromotionUpsellMessages();
      if (null == serverPromotionUpsellMessageList) {
        return;
      }
      if (serverPromotionUpsellMessageList.constructor === Array) {
        for (var i=0; i < serverPromotionUpsellMessageList.length; i++) {
          self.promotionUpsellMessages.push(serverPromotionUpsellMessageList[i]);
        }
      }
    };

    return PromotionUpsellContainer;
});

