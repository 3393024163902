/*<ORACLECOPYRIGHT>
 *Copyright (C) 1994, 2022, Oracle and/or its affiliates. All rights reserved.
 * Oracle and Java are registered trademarks of Oracle and/or its affiliates.
 * Other names may be trademarks of their respective owners.
 * UNIX is a registered trademark of The Open Group.
 *
 * This software and related documentation are provided under a license agreement
 * containing restrictions on use and disclosure and are protected by intellectual property laws.
 * Except as expressly permitted in your license agreement or allowed by law, you may not use, copy,
 * reproduce, translate, broadcast, modify, license, transmit, distribute, exhibit, perform, publish,
 * or display any part, in any form, or by any means. Reverse engineering, disassembly,
 * or decompilation of this software, unless required by law for interoperability, is prohibited.
 *
 * The information contained herein is subject to change without notice and is not warranted to be error-free.
 * If you find any errors, please report them to us in writing.
 *
 * U.S. GOVERNMENT RIGHTS Programs, software, databases, and related documentation and technical data delivered to U.S.
 * Government customers are "commercial computer software" or "commercial technical data" pursuant to the applicable
 * Federal Acquisition Regulation and agency-specific supplemental regulations.
 * As such, the use, duplication, disclosure, modification, and adaptation shall be subject to the restrictions and
 * license terms set forth in the applicable Government contract, and, to the extent applicable by the terms of the
 * Government contract, the additional rights set forth in FAR 52.227-19, Commercial Computer Software License
 * (December 2007). Oracle America, Inc., 500 Oracle Parkway, Redwood City, CA 94065.
 *
 * This software or hardware is developed for general use in a variety of information management applications.
 * It is not developed or intended for use in any inherently dangerous applications, including applications that
 * may create a risk of personal injury. If you use this software or hardware in dangerous applications,
 * then you shall be responsible to take all appropriate fail-safe, backup, redundancy,
 * and other measures to ensure its safe use. Oracle Corporation and its affiliates disclaim any liability for any
 * damages caused by use of this software or hardware in dangerous applications.
 *
 * This software or hardware and documentation may provide access to or information on content,
 * products, and services from third parties. Oracle Corporation and its affiliates are not responsible for and
 * expressly disclaim all warranties of any kind with respect to third-party content, products, and services.
 * Oracle Corporation and its affiliates will not be responsible for any loss, costs,
 * or damages incurred due to your access to or use of third-party content, products, or services.
 </ORACLECOPYRIGHT>*/
/**
 * @fileoverview Controlling class that maintains the processing and
 * flow of a page. Defines the LayoutContainer view model.
 */

/*global $ */
/*global require */
define(
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  'pageLayout/layout-container',

  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  [ 'knockout',
    'pageLayout/data',
    'pageLayout/layout',
    'pageLayout/region',
    'pageLayout/widget',
    'pageLayout/cart',
    'pageLayout/order',
    'pageLayout/layout-mapping',
    'pageLayout/simple-cache',
    'pageLayout/context-handler',
    'pageLayout/view-model-builder',
    'pageLayout/search',
    'pageLayout/shippingmethods',
    'pageLayout/payment-auth-response',
    'pageLayout/user',
    'pageLayout/product',
    'pageLayout/space',
    'pageViewTracker',
    'viewModels/paymentDetails',
    'pageLayout/infinity',
    'pubsub',
    'CCi18n',
    'ccConstants',
    'ccLogger',
    'jquery',
    'notifier',
    'pageLayout/site',
    'sfExceptionHandler',
    'ccStoreServerLogger',
    'viewportHelper',
    'navigation',
    'storageApi',
    'ccRestClient',
    'ccStoreConfiguration',
    'spinner',
    'viewModels/orderDetailsViewModel',
    "pageLayout/css-loader",
    'ccResourceLoader',
    'ociApm'
  ],

  //-------------------------------------------------------------------
  // MODULE DEFINTIION
  //-------------------------------------------------------------------
  function (ko, ServerData, LayoutViewModel, RegionViewModel, WidgetViewModel,
   CartViewModel, OrderViewModel, LayoutMapping, Cache, ContextHandler,
   ViewModelBuilder, SearchViewModel, ShippingMethodsViewModel, PaymentAuthResponseViewModel,
   UserViewModel, ProductViewModel, SpaceViewModel, pageViewTracker, PaymentDetails, InfinityViewModel, PubSub, CCi18n, CCConstants,
   ccLogger, $, notifier, SiteViewModel, ExceptionHandler, StoreServerLogger, viewportHelper, navigation, storageApi, ccRestClient,
   ccStoreConfiguration, spinner, OrderDetailsViewModel, cssLoader, ccResourceLoader, ociApm
   ) {

    "use strict";

    // These are the additional JS life-cycle methods introduced when prioritized loading feature is enabled
    var METHOD_AFTER_APP_LOAD = "afterApplicationLoad";
    var METHOD_AFTER_LOAD = "afterLoad";
    var METHOD_BEFORE_DISAPPEAR = "beforeDisappear";
    var METHOD_AFTER_PAGE_APPEAR = "afterPageAppear";


    if (!String.prototype.endsWith) {
      String.prototype.endsWith = function (search, this_len) {
        if (this_len === undefined || this_len > this.length) {
          this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
      };
    }

    window.previewNavigation = function (targetURL, siteId, viewportId, viewport, audience, minify, date, account, role) {

      var url = "/"               + targetURL  +
                "?occsite="       + siteId     +
                "&ccvp="          + viewportId +
                "&audience="      + audience   +
                "&disableMinify=" + !minify    +
                "&date="          + date;

      ccRestClient.setStoredValue(CCConstants.LOCAL_STORAGE_SITE_ID, siteId);
      ccRestClient.setStoredValue("disableMinify", !minify);
      ccRestClient.setStoredValue("audience", audience);
      viewportHelper.setViewport(viewport);
      ccRestClient.setStoredValue("viewport", viewportId);

      ccRestClient.setStoredValue("date", date);
      ccRestClient.setStoredValue(CCConstants.LOCAL_STORAGE_ORGANIZATION_ID,  ko.toJSON(account));

      console.log("Preview moving to: " + url);
      window.location.href = url;

    };


    //-----------------------------------------------------------------
    //Class definition & member variables (the constructor)
    //-----------------------------------------------------------------
    /**
     * Creates an LayoutContainer object. LayoutContainer controls the flow of a page including how to build
     * a {@link LayoutViewModel|layout}, how to load data, and managing contextual data. All
     * {@link WidgetViewModel|widgets} are constructed via the LayoutContainer.
     *
     * @param {Object} adapter The current context of the page.
     * @param {String} basePath The URL path to this widget.
     *
     * @private
     * @class
     * @name LayoutContainer
     * @property {LayoutMapping} layoutMapping The mapping used to map a layout.
     * @property {WidgetEditorViewModel} editor The widget editor responsible for editing widgets.
     * @property {SimpleCache} cache The Cache used to store retrieved records.
     * @property {ContextHandler} contextHandler The context handler used to manage contextual data.
     * @property {ViewModelBuilder} viewModelBuilder The view model builder used to convert json to view models.
     * @property {ccStoreConfiguration} storeConfiguration An instance of the cc-store-configuration containing store-configuration data.
     */
    function LayoutContainer(adapter, basePath) {
      var self = this;

      this.isPreview;
      this.layoutMapping = new LayoutMapping(this);
      this.masterViewModel= ko.observable();
      this.basePath = basePath;
      this.adapter = adapter;
      this.cache = new Cache();
      this.widgetCache = new Cache();
      this.contextHandler = new ContextHandler();
      this.viewModelBuilder = ViewModelBuilder;
      this.contextDataSet = $.Deferred();
      this.contextDataSetSubscriber = this.contextDataSet.promise();
      this.networkErrorMessage;
      this.networkErrorReloadText;
      self.storeServerLog = StoreServerLogger.getInstance();
      this.storeConfiguration = ccStoreConfiguration.getInstance();
      this.queue=new WidgetQueue();
      this.widgets={};
      this.displayQueue={};
      this.pageResponse;
      this.widgetCount=0;
      this.appLoadedDeferred=$.Deferred();
      this.deferLoadLargeCartInPromptRefresh = false;

      this.currentPage = null;
      // Widgets that are currently being initialized (if any)
      this.pendingWidgets = ko.observableArray().extend({ deferred: true });
      $.Topic(PubSub.topicNames.PAGE_VIEW_CHANGED).subscribe(self.pageViewChanged.bind(self));
      $.Topic(PubSub.topicNames.PAGE_CONTEXT_CHANGED).subscribe(self.pageContextChanged.bind(self));
      $.Topic(PubSub.topicNames.PAGE_PARAMETERS_CHANGED).subscribe(self.pageParametersChanged.bind(self));
      $.Topic(PubSub.topicNames.PAGE_READY).subscribe(self.pageReady.bind(self));
      $.Topic(PubSub.topicNames.RECORD_PAGINATION_PAGE_CHANGE).subscribe(self.pageReady.bind(self));
      $.Topic(PubSub.topicNames.PAGE_PAGINATION_CHANGE).subscribe(self.paginationChange.bind(self));
      $.Topic(PubSub.topicNames.USER_NETWORK_ERROR).subscribe(self.networkError.bind(self));

      // Exception handing
      var clientDebugMode = true;
      if (clientDebugMode) {
        this.exceptionHandler = new ExceptionHandler();
        if (this.exceptionHandler.subscribe) {
          this.exceptionHandler.subscribe();
        }
      }

      /**
       * App level js modules can implement afterApplicationLoad if they want, so lets call those when it's time.
       */
      $.when(self.appLoadedDeferred).then(function(widget) {
        ccResourceLoader.callAppLevelJsMethod(METHOD_AFTER_APP_LOAD, [self.currentPage, self.pageResponse]);
    	self.callMethodInAllWidgets(METHOD_AFTER_APP_LOAD);
      });

      return (this);
    }

    /**
     * A widget queue for running widget initialization in sequence.
     * @public
     * @function WidgetQueue
     */
    function WidgetQueue() {
      var head = null;
      function next() {
        var next = $.Deferred();
        $.when(head).always(function() {
          next.resolve();
        });
        return next.promise();
      }

      return {
        display : function(widgetId,layoutContainer) {
          var methodDeferred = $.Deferred(), nextDeferred = next();
          nextDeferred.done(function() {
            //Check if widgets are already initialized and cached
            if(layoutContainer.displayQueue[widgetId]) {
        	    if(layoutContainer.widgets[widgetId].initialized()){
                  layoutContainer.widgets[widgetId].occPrioritizedDisplay(true);
                  layoutContainer.widgetCount--;
                  checkWidgetCount();
                  methodDeferred.resolve();
                }else{
                  //If the widget is not yet initialized, wait till its initialized
                  layoutContainer.displayQueue[widgetId].done(function(widget) {
                    widget.occPrioritizedDisplay(true);
                    layoutContainer.widgetCount--;
                    checkWidgetCount();
                    methodDeferred.resolve();
                  });
                }
            }
          });
          head = methodDeferred.promise();

          function checkWidgetCount() {
        	//If all the widgets are rendered, call afterPageAppeared in all the widgets
        	if(layoutContainer.widgetCount === 0){
              layoutContainer.callMethodInAllWidgets(METHOD_AFTER_PAGE_APPEAR);
              //Resolve promise for notifier to be displayed on UI
              layoutContainer.UserViewModel.getInstance().pageAppeared.resolve();
              layoutContainer.appLoadedDeferred.resolve();
            }
          }
        },
        resetHead : function() {
          head = null;
        }
      }
    }

    //------------------------------------------------------------------
    // Class functions
    //------------------------------------------------------------------

    /**
     * Returns a view model builder for the passed in type, or creates a default builder if none found.
     *
     * @function
     * @name LayoutContainer#getViewModelBuilder
     * @param {string} type The type of object for which to return a builder.
     * @returns {ViewModelBuilder} View model builder for `type'.
     */
    LayoutContainer.prototype.getViewModelBuilder = function(type) {
      var builder = this.viewModelBuilder[type];

      if(builder == null) {
        builder = {
          scope: 'page',
          cacheable: false,
          load: true,
          create: true
        };
      }
      return builder;
    };

  /**
   * Walk the properties in "global" and "page" scope.
   * For each property add it to the context handler so that
   * it can be available for widgets to enjoy.
   *
   * @private
   * @function
   * @name LayoutContainer#setContextData
   * @param {Object} serverData
   */
  LayoutContainer.prototype.setContextData = function(serverData) {
    //var serverData = layoutViewMapping.data,
    var topLevelProps, propsIdx = 0,
      property;
    //if (!serverData) { return; }
    var self = this;
    var processServerData = function() {
      topLevelProps = ['global', 'page'];
      for (propsIdx = 0; propsIdx < topLevelProps.length; propsIdx++) {
        for (property in serverData[topLevelProps[propsIdx]]) {
          if (serverData[topLevelProps[propsIdx]].hasOwnProperty(property) && property !== "__ko_mapping__") {
            self.loadCurrentFromJSON(property,
              serverData[topLevelProps[propsIdx]][property], serverData);
          }
        }
      }

      // Initialize infinity viewmodel only if infinityTag is present
      if(self.contextHandler.get("site","global")().extensionSiteSettings.InfinityTag !== undefined &&
          self.contextHandler.get("site","global")().extensionSiteSettings.InfinityTag.enabled == true ){
        InfinityViewModel.initialize(self.contextHandler.get("user","global"), self.contextHandler.get("cart","global"),
            self.contextHandler.get("confirmation","page"), self.contextHandler.get("site","global"));
      }


      // Initialize OCI APM viewmodel only if ociAPM is enabled
      if(self.contextHandler.get("site","global")().extensionSiteSettings.ociApm !== undefined &&
         self.contextHandler.get("site","global")().extensionSiteSettings.ociApm.enabled == true ){

         //Update APM RUM settings
         ociApm.initialize(self.contextHandler.get("site","global"));
      }

      // Context data all set. Dependencies can execute now
      self.contextDataSet.resolve();

      // Reset the deferred stuff as it seems to not quite work on IE if you dont...
      self.contextDataSet = $.Deferred();
      self.contextDataSetSubscriber = self.contextDataSet.promise();

    };
     // Setup locale before building any view models
    var localeFromServer = serverData['global']['locale'];
    if (localeFromServer && localeFromServer !== null) {
      CCi18n.setLocaleOnce(localeFromServer,processServerData);
    } else {
      // we don't have a locale, keep on going
      processServerData();
    }
  };

  /**
   * Listens for PAGE_CONTEXT_CHANGED events and
   * requests only data from the server. When data is
   * return it sets up context variables according to the
   * returned data.
   *
   * @private
   * @function
   * @name LayoutContainer#pageContextChanged
   * @param {string} data.contextId New context id.
   * @param {string} data.pageId URL to request.
   */
   LayoutContainer.prototype.pageContextChanged = function (data) {
    var self = this;
    self.currentPage = data;

    var requestURL = data.pageId;
    var params = {};
    params[CCConstants.PAGE_PARAM] = data.contextId;
    params[CCConstants.DATA_ONLY] = true;
    this.load('layout', requestURL, params,
      // Callback for load result
      function (result) {
        // Update context variables
        self.setContextData(result.data);
        self.updatePageEventData(result.data, data);
        if (result.data && result.data.global && result.data.global.site) {
          if (result.data.global.site.tenantId) {
            pageViewTracker.tenantId(result.data.global.site.tenantId);
          }
        }
        // Do we need to publish here? Sure...
       $.Topic(PubSub.topicNames.PAGE_METADATA_CHANGED).publish(result);
       $.Topic(PubSub.topicNames.PAGE_READY).publish(data);
      },
      self.handleServerError
      );
   };

   /**
    * Adds the page repository id from the server context data to the page event data being
    * passed around.
    *
    * @private
    * @function
    * @name LayoutContainer#updatePageEventData
    * @param {Object} pServerData
    * @param {Object} pPageEventData
    */
   LayoutContainer.prototype.updatePageEventData = function(pServerData, pPageEventData) {

     if (pServerData.page && pServerData.page.repositoryId) {
       pPageEventData.pageRepositoryId = pServerData.page.repositoryId;
     }
   };

   /**
    * Callback function invoked if an error is returned after a request.
    *
    * @private
    * @function
    * @name LayoutContainer#handleServerError
    * @param {Object} result Result object returned from server.
    */
   LayoutContainer.prototype.handleServerError  = function (result) {
     this.networkError();
   };

   /**
    * Listens for PAGE_PARAMETERS_CHANGED events. When data is returned it
    * pushes a PubSub PAGE_PARAMETERS event for transferring the data to
    * appropriate location for handling the data
    * e.g. when the view is "searchresults"
    * the data is sent to searchViewModel for creation of a
    * new search with the data provided
    *
    * @private
    * @function
    * @name LayoutContainer#pageParametersChanged
    * @param {Object} pageEventData The parameter string part of the URL.
    */
   LayoutContainer.prototype.pageParametersChanged = function(pageEventData) {
     var parameters = this.getParameterData(pageEventData.parameters);
     $.Topic(PubSub.topicNames.PAGE_PARAMETERS).publishWith(
       {
         pageId: pageEventData.pageId,
         seoslug: pageEventData.seoslug,
         parameters: parameters
       },[{message:"success"}]);
   };

    /**
     * Listens for PAGE_VIEW_CHANGED events and updates the
     * layout according to the new view id.
     *
     * @private
     * @function
     * @name LayoutContainer#pageViewChanged
     * @param {Object} pageEventData Arguments passed in via the page view event.
     */
    LayoutContainer.prototype.pageViewChanged = function (pageEventData) {

      var self = this;

      self.shouldPageBeRefreshed(pageEventData.path);

      self.currentPage = pageEventData;
      // default location if no pageId is provided
      var requestURL = 'home/';
      if (ccRestClient.profileType === "agentUI") {
        requestURL = 'agentHome/';
      }
      var params = null;
      if (pageEventData.pageId) {
        // use the provided page ID
        requestURL = pageEventData.pageId;
      }
      if (pageEventData.contextId) {
        // If we have context params add them
        requestURL = pageEventData.pageId;
        params = {};
        params[CCConstants.PAGE_PARAM] = pageEventData.contextId;
        params[CCConstants.DATA_ONLY] = false;
      }

      // Make sure we ignore freemarker template paths.
      if (pageEventData.path && !/.ftl$/.test(pageEventData.path)) {
        requestURL = pageEventData.path;
      }
      // This is neeaded for agent application
      requestURL = navigation.getPathWithoutApplicationContext(requestURL);

      var siteBasePath = window.siteBaseURLPath;
      if (siteBasePath && siteBasePath !== '/') {
        requestURL = navigation.getPathWithoutSiteBasePath(requestURL);

        // If left with nothing, default to home
        if (requestURL === '') {
          requestURL = 'home';
          if (ccRestClient.profileType === "agentUI") {
            requestURL = 'agentHome/';
          }
        }
      }

      // Need any query params from the window if we are in preview.
      var uriSplit = window.location.href.split("?"),
      queryParams =  null,
      parameterString = undefined;

      if (uriSplit.length > 1) {
        parameterString = uriSplit[1];
        queryParams = uriSplit[1].split("&");
      }

      if (queryParams) {

        if (params == null) {
          params = {};
        }

        for (var index in queryParams) {
          var queryParam = queryParams[index].split("=");

          // When redirected to a 404 page, strip out the usePageId and usePreviewData parameters
          if (pageEventData.path && pageEventData.path === '404'
            && (!pageEventData.parameters || pageEventData.parameters === '')) {
            if (queryParam[0] !== 'usePageId' && queryParam[0] !== 'usePreviewData') {
              params[queryParam[0]] = queryParam[1];
            }
          }
          else {
            if (queryParam[0] !== 'occsite') {
              params[queryParam[0]] = queryParam[1];
            }
          }
        }
      }

      if (params == null) {
        params = {};
      }
      if (!params.dataOnly) {
        params.dataOnly = false;
      }
      this.load('layout', requestURL, params,
      // Callback for load result
      function (result) {
        self.isPreview = result.isPreview();
        self.pageResponse = result;
        if (result.data.page.pageId) {
          pageEventData.pageId = result.data.page.pageId;
        }

        if (result.data.page.contextId) {
          pageEventData.contextId = result.data.page.contextId;
        }
        //Form widget array and display widgets in order, when initialized.
        if(self.storeConfiguration.enablePrioritizedLoading){
          self.formWidgetQueue(result);
          self.displayWidgets();
        }
        var subscription;
        // Update context variables
        self.setContextData(result.data);
        self.updatePageEventData(result.data, pageEventData);
        if (result.data && result.data.global && result.data.global.site) {
          if (result.data.global.site.tenantId) {
            pageViewTracker.tenantId(result.data.global.site.tenantId);
          }
        }

        // Send the parameters
        if (pageEventData.parameters) {

          $.Topic(PubSub.topicNames.PAGE_PARAMETERS).publishWith({
            pageId: pageEventData.pageId,
            seoslug: pageEventData.seoslug,
            parameters: self.getParameterData(pageEventData.parameters)
          },[{message:"success"}]);

        }

        $.Topic(PubSub.topicNames.PAGE_CHANGED).publish(
          pageEventData
        );

        // Update page regions
        // TODO: Fire event here rather than
        // direct link to the view model
      //  masterViewModel.regions(result.regions());
       $.Topic(PubSub.topicNames.PAGE_LAYOUT_LOADED).publish(result, pageEventData);
       var param = {"pageId" : pageEventData.pageId};
       self.storeServerLog.logInfo("getPage",self.storeServerLog.getMessage("getPage", param));
       // if we have pending widgets, delay this event till they load
       if (self.pendingWidgets().length === 0)
         $.Topic(PubSub.topicNames.PAGE_READY).publish(pageEventData);
       else {
         // wait for pending widget count to drop to zero
         subscription = self.pendingWidgets.subscribe(function(watchedPendingWidgets) {
         if (watchedPendingWidgets.length === 0) {
           $.Topic(PubSub.topicNames.PAGE_READY).publish(pageEventData);
           subscription.dispose(); // stop listening
         }
        });
       }

      },
      // Error Callback. Server is likely down.
      self.handleServerError.bind(self)
      );
    };

    /**
     * Request to persist the creation of a new object.
     *
     * @private
     * @function
     * @name LayoutContainer#create
     * @param {String} type The type of data being created.
     * @param {String|String[]} id The id of the object being created using either a simple id (string) or
     *   complex id (an array of values).
     * @param {Object} model The viewModel to persist the creation of.
     * @param {Object} params Additional parameters used for the request.
     * @param {function} success A success function callback. Passes paramters (value, caller). value is the result of the create,
     *   caller is used to reference the calling context.
     * @param {function} error An error function callback invoked if there was an error with creation of the object.
     * @param {Object} caller The caller to be passed to the success and error callbacks.
     */
    LayoutContainer.prototype.create = function(type, id, model, params, success, error, caller) {
      var self = this,
          builder,
          createFunc,
          json;

      if($.isFunction(params)) {
        if($.isFunction(success)) {
          caller = error;
          error = success;
        } else {
          caller = success;
        }
        success = params;
        params = null;
      } else if(!$.isFunction(error)) {
        caller = error;
        error = null;
      }

      //Get the viewModelBuilder for this type of object. If no builder, we
      //cannot load an object of this type.
      builder = this.getViewModelBuilder(type);
      if(!builder || !builder.create) {
        return;
      }

      //Add the item to the cache if it is cachable
      if(builder.cachable) {
        var cacheKey = self.idAndParamsToCacheKey(id, params);
        self.cache.set(type, cacheKey, model);
      }

      //Get the builder's load function into this context.
      createFunc = builder.create;

      if(!createFunc) {
        throw "Loading of resource type: " + type + " forbidden";
      }

      if(createFunc === true) {
        json = model;
      } else {
        json = createFunc(model, params, caller);
      }

      //Ask the adapter to save the json
      this.adapter.persistCreate(type, id, json, params,
        function(data) {
          if(success) {
            success(data, caller);
          }
        },
        function(data) {
          if(error) {
            error(data, caller);
          }
        });
    };

    /**
     * Request to persist an update of an object.
     *
     * @private
     * @function
     * @name LayoutContainer#update
     * @param {string} type The type of data being updated.
     * @param {string|string[]} id The id of the object being updated using either a simple id (string) or
     * complex id (an array of values).
     * @param {Object} model The viewModel to update.
     * @param {Object} params Additional parameters used for the request.
     * @param {function} success A success function callback. Passes paramters (value, caller). value is the result of the update,
     * caller is used to reference the calling context.
     * @param {function} error An error function callback invoked if there was an error with updating the data.
     * @param {Object} caller The caller to be passed to the success and error callbacks.
     */
    LayoutContainer.prototype.update = function(type, id, model, params, success, error, caller) {
      var self = this,
          builder,
          updateFunc,
          json,
          errorCallback,
          successCallback;

      if($.isFunction(params)) {
        if($.isFunction(success)) {
          caller = error;
          error = success;
        } else {
          caller = success;
        }
        success = params;
        params = null;
      } else if(!$.isFunction(error)) {
        caller = error;
        error = null;
      }

      //Get the viewModelBuilder for this type of object. If no builder, we
      //cannot load an object of this type.
      builder = this.getViewModelBuilder(type);
      if(!builder || !builder.update) {
        return;
      }

      //Get the builder's load function into this context.
      updateFunc = builder.update;

      if(!updateFunc) {
        throw "Updating of resource type: " + type + " forbidden";
      }

      if(updateFunc === true) {
        json = model;
      } else {
        json = updateFunc(model, params, caller);
      }

      //Ask the adapter to save the json
      this.adapter.persistUpdate(type, id, json, params,
        function(data) {
          if(success) {
            success(data, caller);
          }
        },
        function(data) {
          if(error) {
            error(data, caller);
          }
        });
    };


    /**
     * Request to permanently delete a new object.
     *
     * @private
     * @function
     * @name LayoutContainer#remove
     * @param {string} type The type of data being deleted.
     * @param {string|string[]} id The id of the object being deleted using either a simple id (string) or
     * complex id (an array of values).
     * @param {Object} params Additional parameters used for the request.
     * @param {function} success A success function callback. Passes paramters (value, caller). value is the result of the deletion,
     * caller is used to reference the calling context.
     * @param {function} error An error function callback invoked if there was an error with deletion of the object.
     * @param {Object} caller The caller to be passed to the success and error callbacks.
     */
    LayoutContainer.prototype.remove = function(type, id, params, success, error, caller) {
      var self = this,
          builder,
          removeFunc;

      if($.isFunction(params)) {
        if($.isFunction(success)) {
          caller = error;
          error = success;
        } else {
          caller = success;
        }
        success = params;
        params = null;
      } else if(!$.isFunction(error)) {
        caller = error;
        error = null;
      }

      //Get the viewModelBuilder for this type of object. If no builder, we
      //cannot load an object of this type.
      builder = this.getViewModelBuilder(type);
      if(!builder || !builder.remove) {
        return;
      }

      //If the object is cachable check the cache and result if there is a cache hit.
      if(builder.cachable) {
        var cacheKey = self.idAndParamsToCacheKey(id, params);
        this.cache.set(type, cacheKey, null);
      }

      //Get the builder's load function into this context.
      removeFunc = builder.remove;

      if(!removeFunc) {
        throw "Deleting of resource type: " + type + " forbidden";
      }

      //Ask the adapter to retreive the object.
      this.adapter.persistDelete(type, id, params,
        function(data) {
          //Perform the callback
          if(success) {
            success(data, caller);
          }
        },
        function(data) {
          if(error) {
            error(data, caller);
          }
        });
    };

    /**
     * Utility function to redirect to error pages based on status
     * @param result response for layout
     */
    function redirectOnError(result){
      if(ccRestClient.profileType === CCConstants.PROFILE_TYPE_AGENT){
        if(result.status == CCConstants.HTTP_NOT_FOUND){
          navigation.goTo("/agentError", true, true);
        }else if(result.status == CCConstants.BAD_REQUEST){
          // Not using navigation.goTo because url params may not be correct
          // for which we got 400 bad request, if we use same params again then we get
          // 400 for agentError page
          var url = window.location.origin + window.applicationContextPath + "/agentError";
          window.location.assign(url);
        }
      }else {
        if (result.status == CCConstants.HTTP_NOT_FOUND) {
          // Passing in true for noHistory param (2nd param), we don't want the url to change on 404 pages.
          navigation.goTo("/404", true, true);
        }
      }
    };

    /**
     * Request to load a piece of external data based on url, id and params.
     *
     * @public
     * @function
     * @name LayoutContainer#loadRequestForLayout
     * @param {string} pUrl The Url of the endpoint.
     * @param {Object} pData Additional parameters used for the request.
     * @param {string|string[]} pParam1 The id of the object(s) being loaded using either a simple id (string) or
     * complex id (an array of values).
     */
    LayoutContainer.prototype.loadRequestForLayout = function(pUrl, pData, pParam1, pParam2, pParam3, pParam4, pBeforeSendCallback) {
        var deferred = $.Deferred();
        var self = this;
        ccRestClient.request(pUrl, pData,
          function (result) {
            self.shouldPageBeRefreshed(pParam1);
            if(self.deferLoadLargeCartInPromptRefresh == true){
              deferred.reject(result);
           }else{
             deferred.resolve(result);
           }
          },
          function (result) {
            deferred.reject(result);
            redirectOnError(result);
          }, pParam1, pParam2, pParam3, pParam4, pBeforeSendCallback);
        return deferred;
    };

    function regionsDiffer(regionA,regionB) {
      if (!regionA || !regionB) return true;

      if(regionA.stackType && regionA.stackType() == CCConstants.STACK_TYPE_POPUP && regionB.stackType == CCConstants.STACK_TYPE_POPUP) {
        if (regionA.regions().length && regionA.regions()[0].widgets().length && regionA.regions()[0].widgets()[0].WIDGET_ID == CCConstants.WIDGET_ID_PRODUCT_LISTING){
          //if in a popup stack, and the product listing widget is changing to search, set as different
          if(regionA.regions()[0].widgets()[0].listType() != regionB.regions[0].widgets[0].listType) {
            return true;
          }
        }
      }

      if (regionA.similarRegions && (Object.keys(regionA.similarRegions).length > 0) &&
          regionA.similarRegions.hasOwnProperty(regionB.id)) {
        return false;
      }

      if (regionB.similarRegions && (Object.keys(regionB.similarRegions).length > 0) &&
          regionB.similarRegions.hasOwnProperty(regionA.id)) {
        return false;
      }

    	var unWrappedRegionAwidgets = ko.isObservable(regionA.widgets) ? regionA.widgets() : regionA.widgets;
      var unWrappedRegionAlength = unWrappedRegionAwidgets ? unWrappedRegionAwidgets.length : 0 ;

      if (unWrappedRegionAwidgets && unWrappedRegionAlength > 0) {

    	if(regionB.widgets && (regionB.widgets.length > 0)) {

          if((unWrappedRegionAlength !== regionB.widgets.length)) {
            return true;
          }

          var unWrappedRegionAwidth =  ko.isObservable(regionA.width) ? regionA.width() : regionA.width;
          if(unWrappedRegionAwidth !== regionB.width) {
            return true;
          }

          for(var i = 0; i < unWrappedRegionAlength; i++) {
        	var regionAwidgetId = ko.isObservable(unWrappedRegionAwidgets[i].id) ? unWrappedRegionAwidgets[i].id() : unWrappedRegionAwidgets[i].id;
            if(regionAwidgetId !== regionB.widgets[i].id) {
              return true;
            }
          }

          // If both regions have metadata, then compare region ids as this region must belong to experiments
          // and does not contain any widgets.
          if (regionA.metadata() && regionB.metadata && (regionA.id() != regionB.id)) {
            return true;
          }

          return false;
        }
        return true;
      }

      if(regionB.widgets) {
        return true;
      }

      return true;
    }

    /**
     * Returns the index of the given region in the given list of regions
     */
    function findRegion(region,regionsList) {
      var result = -1;
      for (var i = 0; i < regionsList.length; i++) {
        if (!regionsDiffer(region,regionsList[i])) {
          result = i;
          break;
        }
      }
      return result;
    };

    ko.observableArray.fn.setAt = function(index, value) {
      this.valueWillMutate();
      this()[index] = value;
      this.valueHasMutated();
    };

    /**
     * Request to load page endpoint data. The data is loaded and converted
     * to a view model before being passed to the success callback.
     *
     * @public
     * @function
     * @name LayoutContainer#loadDataForLayout
     * @param {Object} input Additional parameters used for the request.
     * @param {string|string[]} id The id of the object(s) being loaded using either a simple id (string) or
     * complex id (an array of values).
     * @param {function} success A success function callback. Passes paramters (viewModel, caller). viewModel is the result of the load,
     * caller is used to reference the calling context.
     * @param {function} error An error function callback invoked if there was an error with loading the data.
     * @param {Object} builder The view model builder object fot type 'layout'
     * @param {Object} caller The caller to be passed to the success and error callbacks.
     */
    LayoutContainer.prototype.loadDataForLayout = function(input, id, success, error, builder, caller) {
      var self = this, params = input, loadFunc = builder.load;
      var layoutOnlyRequest = $.extend({}, input);
      // 'dataOnly' param is not required for layout call.
      if (layoutOnlyRequest.hasOwnProperty(CCConstants.DATA_ONLY)) {
        delete layoutOnlyRequest[CCConstants.DATA_ONLY];
      }

      var vp = viewportHelper.viewportDesignation();
      if (self.storeConfiguration.enableLayoutsRenderedForLayout === true ) {
        var layoutIdsRendered = self.storeConfiguration.getLayoutIdsRendered();
        if (layoutIdsRendered.length > 0) {
          layoutOnlyRequest[CCConstants.LAYOUTS_RENDERED] = layoutIdsRendered.toString();
        }
      }
      if (vp) {
        layoutOnlyRequest['ccvp'] = vp;
      }

      var cacheableDataOnlyRequest = $.extend({}, input, {cacheableDataOnly:true});
      //Do not cache the page endpoint in case of shopper context, as the pricelist groups are determined dynamically.
      if(this.storageApi.getItem(CCConstants.LOCAL_STORAGE_CURRENT_CONTEXT)){
    	cacheableDataOnlyRequest.cacheableDataOnly = false;
      }
      var productTypesRequired = false;
      if (self.contextHandler.get(CCConstants.PRODUCT_TYPES, 'page')() === undefined && !self.storeConfiguration.skipLoadingProductTypes) {
        productTypesRequired = true;
      }
      cacheableDataOnlyRequest['productTypesRequired'] = productTypesRequired;

      var contextObj = {};
      contextObj[CCConstants.ENDPOINT_KEY] = CCConstants.ENDPOINT_PAGES_GET_PAGE;
      contextObj[CCConstants.PAGE_KEY] = id;
      contextObj[CCConstants.IDENTIFIER_KEY] = "layoutOnly";
      var filterKeyForLayoutData = self.storeConfiguration.getFilterToUse(contextObj);
      if (filterKeyForLayoutData) {
        layoutOnlyRequest[CCConstants.FILTER_KEY] = filterKeyForLayoutData;
      }
      // Check if we need to pass the disable minify javascript param, in preview mode only
      if (ccRestClient.previewMode) {
        layoutOnlyRequest[CCConstants.DISABLE_MINIFY] = ccRestClient.getStoredValue(CCConstants.DISABLE_MINIFY);
      }

      contextObj[CCConstants.IDENTIFIER_KEY] = "cachableData";
      var filterKeyForCachableData = self.storeConfiguration.getFilterToUse(contextObj);
      if (filterKeyForCachableData) {
        cacheableDataOnlyRequest[CCConstants.FILTER_KEY] = filterKeyForCachableData;
      }

      var currentDataParams = {currentDataOnly:true};
      contextObj[CCConstants.IDENTIFIER_KEY] = "currentData";
      var filterKeyForCurrentData = self.storeConfiguration.getFilterToUse(contextObj);
      if (filterKeyForCurrentData) {
        currentDataParams[CCConstants.FILTER_KEY] = filterKeyForCurrentData;
      }
      if (self.storeConfiguration.enableSpinnerOnPageLoad) {
        self.pageLoading();
      }

      // Only enable product types when required for currentDataOnly request
      var currentDataOnlyRequest = $.extend({}, input, {currentDataOnly:true});
      currentDataOnlyRequest['productTypesRequired'] = productTypesRequired;


      var cssOnlyRequest = $.extend({}, input);
      // 'dataOnly' param is not required for css call.
      if (cssOnlyRequest.hasOwnProperty(CCConstants.DATA_ONLY)) {
        delete cssOnlyRequest[CCConstants.DATA_ONLY];
      }

      $.when (
        this.loadRequestForLayout(CCConstants.ENDPOINT_GET_LAYOUT, layoutOnlyRequest, id),
        this.loadRequestForLayout(CCConstants.ENDPOINT_PAGES_GET_PAGE, cacheableDataOnlyRequest, id),
        this.loadRequestForLayout(CCConstants.ENDPOINT_PAGES_GET_PAGE, currentDataOnlyRequest, id),
        cssLoader.loadCssForLayout(id, cssOnlyRequest)
      ).done (
        function (pLayoutResult, pCacheableDataResult, pCurrentDataResult) {
          if (success) {

            //Set the user pricelist group into site to be backward compatible.
            if(pCurrentDataResult.data.global.user.parentOrganization){
              pCacheableDataResult.data.global.site.priceListGroup.defaultPriceListGroup = pCurrentDataResult.data.global.user.priceListGroup;
              pCacheableDataResult.data.global.site.priceListGroup.activePriceListGroups = [pCurrentDataResult.data.global.user.priceListGroup];
              pCacheableDataResult.data.global.site.currency = pCurrentDataResult.data.global.user.priceListGroup.currency;
            }

            // This will merge the 3 results into 1, meaning the response will look the same to the caller, regardless of the
            // request being split into multiple calls.
            var data = $.extend(true, pLayoutResult, pCacheableDataResult, pCurrentDataResult);
            var result;
            params['pageId'] = id;

            // To ensure backward compatibility, add productTypes from contextHandler to data.
            if (data.data.page && (data.data.page.pageId === "product" || data.data.page.pageId === "category") &&
                data.data.page.hasOwnProperty(CCConstants.PRODUCT_TYPES) == false &&
                self.contextHandler.get(CCConstants.PRODUCT_TYPES, "page")() != undefined) {
              data.data.page[CCConstants.PRODUCT_TYPES] = self.contextHandler.get(CCConstants.PRODUCT_TYPES, "page")();
            }

            // Store the layout ids rendered.
            self.storeConfiguration.storeLayoutIdsRendered(pLayoutResult);

            //Set the multiFactorAuthenticationEnabled flag in the store configuration.
            if(pCurrentDataResult && pCurrentDataResult.data && pCurrentDataResult.data.global) {
                self.storeConfiguration.multiFactorAuthenticationEnabled = pCurrentDataResult.data.global.multiFactorAuthenticationEnabled;
            }

            //Perform the load function to transform the JSON. If
            //load is true, then just pass through the data. Otherwise
            //set result to the transformed data.
            if(loadFunc === true) {
              result = data;
            } else {
              result = loadFunc(data, self, params);

              var currentRegions = self.masterViewModel().regions(),
              newRegions = data.regions,
              i = 0;
              // make them the same length
              currentRegions.length = newRegions.length;
              var pendingRegions = new Array(currentRegions.length);
              self.masterViewModel().regions.valueWillMutate();
              for (i = 0 ; i < currentRegions.length ; i++ ) {
                var differ = regionsDiffer(currentRegions[i],newRegions[i]);
                if (differ) {

                  // check if the current region can be re-used again
                  // if so we'll save it for later in pendingRegions
                  var idx = findRegion(currentRegions[i],newRegions);
                  if (idx != -1 ) {
                    // Save away our pending move
                    pendingRegions[idx] = currentRegions[i];
                  }
                  if(! pendingRegions[i]){
                    var regionBuilder = self.getViewModelBuilder("region");
                    var newRegion = regionBuilder.load(newRegions[i], self, params);
                    // Update the current region to match the new list
                    currentRegions[i] = newRegion;
                  }
                } else {
                  // Associate the new region's id to current region for re-use.
                  currentRegions[i].similarRegions[newRegions[i].id] = {};
                }
              }

              // Make pending changes
              for (i = 0; i < pendingRegions.length ; i++) {
                // if its not undefined, set it on the final array
                if (pendingRegions[i] !== undefined) {
                  self.masterViewModel().regions()[i] = pendingRegions[i];
                }
              }

              //In case of prioritized loading, reset the display queue of the widgets.
              if(self.storeConfiguration.enablePrioritizedLoading){
                self.widgetCount=0;
                for (i=0;i<self.masterViewModel().regions().length;i++) {
                //Some layouts may have regions within regions, so iterate and reset all the widgets.
                  self.iterateRegionsAndResetDisplay(self.masterViewModel().regions()[i]);
                }
              }
              self.masterViewModel().regions.valueHasMutated();
              self.masterViewModel().buildRows();
            }


            self.masterViewModel().title(result.title());
            self.masterViewModel().keywords(result.keywords());
            self.masterViewModel().description(result.description());
            self.masterViewModel().metaTags(result.metaTags());
            self.masterViewModel().isPreview(result.isPreview());
            self.masterViewModel().noindex(result.noindex());
            viewportHelper.layoutViewports(result.viewports ? result.viewports() : "");
            //Set whether its a page navigation or a page refresh
            if(self.storeConfiguration.isFreshPageLoad === undefined ){
             self.storeConfiguration.isFreshPageLoad = true;
            }
            else if(self.storeConfiguration.isFreshPageLoad === true){
            self.storeConfiguration.isFreshPageLoad = false;
            }
            // Notify subscribers that the layout has been updated. Publish the page data and event data that caused the update.
            $.Topic(PubSub.topicNames.PAGE_LAYOUT_UPDATED).publish(data, params);

            //update the page change message
            $.Topic(PubSub.topicNames.LOCALE_READY).subscribe(self.updatePageChangeMessage.bind(self,data));
            if (self.masterViewModel().pageChangeMessage() === "") {
              self.masterViewModel().dataForPageChangeMessage(data.data);
            }

            if (self.storeConfiguration.enableSpinnerOnPageLoad) {
              self.pageLoadingComplete();
            }

            //Add the item to the cache if it is cachable
            if(builder.cachable) {
              var cacheKey = self.idAndParamsToCacheKey(id, params);
              self.cache.set(type, cacheKey, result);
            }

            //Perform the callback
            success (result, caller);
          } else if (result.status == CCConstants.HTTP_NOT_FOUND  || result.status == CCConstants.BAD_REQUEST) {
            redirectOnError(result);
          } else if (error && pResult) {
            error(pResult, caller);
          }
        }
      );
    };

    /**
     * Reset display of all widgets in current layout.
     * @public
     * @function
     * @name LayoutContainer#iterateRegionsAndResetDisplay
     * @param {Object} region Region to be iterated
     */
    LayoutContainer.prototype.iterateRegionsAndResetDisplay = function(region) {
      var self = this;
      if(region.regions().length>0){
        for(var i=0;i<region.regions().length;i++) {
          self.iterateRegionsAndResetDisplay(region.regions()[i]);
        }
      }else{
        for (var i=0;i<region.widgets().length;i++) {
          var widgetId = region.widgets()[i].id();
          if (widgetId in self.widgets) {
            delete self.widgets[widgetId];
          }
          region.widgets()[i].occPrioritizedDisplay(false);
          self.widgetCount++;
        }
      }
    };

    /**
     * Whenever a page change happens, updating the page changed message according to the page that is loaded.
     *
     * Moved the code from loadDataForLayout to a new function, which will be called after the LOCALE_READY event is published.
     * @public
     * @function
     * @name LayoutContainer#updatePageChangeMessage
     * @param {Object} data. Message to be updated.
     */
    LayoutContainer.prototype.updatePageChangeMessage = function(data){
    	var self = this;
    	if (data.data.page.category) {
            self.masterViewModel().pageChangeMessage(CCi18n.t(
              'ns.common:resources.categoryPageLoadedText',
              {
                 collection: data.data.page.category.displayName
               }));
          } else if (data.data.page.product) {
            self.masterViewModel().pageChangeMessage(CCi18n.t(
                'ns.common:resources.productPageLoadedText',
                {
                  product: data.data.page.product.displayName
                }));
          } else if (data.data.page.repositoryId === "userSpacesPage") {
            self.masterViewModel().pageChangeMessage(CCi18n.t('ns.common:resources.wishlistPageLoadedText'));
          }  else if (data.data.page.repositoryId != "searchResultsPage") {
            self.masterViewModel().pageChangeMessage(CCi18n.t(
                'ns.common:resources.pageLoadedText',
                {
                  page: data.data.page.repositoryId
                }));
          }
        $.Topic(PubSub.topicNames.LOCALE_READY).unsubscribe(self.updatePageChangeMessage);
    }
    /**
     * Request to load a piece of external data based on type, id, and params. The data is loaded and converted
     * to a view model before being passed to the success callback.
     *
     * @public
     * @function
     * @name LayoutContainer#load
     * @param {string} type The type of data being requested.
     * @param {string|string[]} id The id of the object(s) being loaded using either a simple id (string) or
     * complex id (an array of values).
     * @param {Object} params Additional parameters used for the request.
     * @param {function} success A success function callback. Passes paramters (viewModel, caller). viewModel is the result of the load,
     * caller is used to reference the calling context.
     * @param {function} error An error function callback invoked if there was an error with loading the data.
     * @param {Object} caller The caller to be passed to the success and error callbacks.
     */
    LayoutContainer.prototype.load = function(type, id, params, success, error, caller) {
      var self = this,
          builder,
          loadFunc,
          cacheResult;

      if($.isFunction(params)) {
        if($.isFunction(success)) {
          caller = error;
          error = success;
        } else {
          caller = success;
        }
        success = params;
        params = null;
      } else if(!$.isFunction(error)) {
        caller = error;
        error = null;
      }

      //Get the viewModelBuilder for this type of object. If no builder, we
      //cannot load an object of this type.
      builder = this.getViewModelBuilder(type);

            // If it was not in the cache, need to
      // call the load function
      if(!builder || !builder.load) {
        return;
      }

      //If the object is cachable check the cache and result if there is a cache hit.
      if(builder.cachable) {
        var cacheKey = self.idAndParamsToCacheKey(id, params);
        cacheResult = this.cache.get(type, cacheKey);
        if(cacheResult.hit === true) {
          if(success) {
            success(cacheResult.result, caller);
          }
          return;
        }
      }

      //Get the builder's load function into this context.
      loadFunc = builder.load;

      if(!loadFunc) {
        throw "Loading of resource type: " + type + " forbidden";
      }

      var input = params ? params : {}
      if (type == 'layout' && input.dataOnly === false) {
        this.loadDataForLayout(input, id, success, error, builder, caller)
      } else {
        //Ask the adapter to retreive the object.
        this.adapter.loadJSON(type, id, params,
          //success callback
          function(data) {
            var result;

            //Perform the load function to transform the JSON. If
            //load is true, then just pass through the data. Otherwise
            //set result to the transformed data.
            if(loadFunc === true) {
              result = data;
            } else {
              result = loadFunc(data, self, params);
            }

            //Add the item to the cache if it is cachable
            if(builder.cachable) {
              var cacheKey = self.idAndParamsToCacheKey(id, params);
              self.cache.set(type, cacheKey, result);
            }

            //Perform the callback
            if(success) {
              success(result, caller);
            }
          },
          //error callback
          function(data) {
            if(error) {
              error(data, caller);
            }
          });
        }
    };
    /**
     * Build a view model from the provided json for the given
     * item type then sets that item as the current item for the given type.
     * If the item is cachable and an id is provided the cache will be updated.
     *
     * @private
     * @function
     * @name LayoutContainer#loadCurrentFromJSON
     * @param {String} type The type of item to load and set as current.
     * @param {Object} json The json object to transform into a view model
     * @param {Object} params Additional parameters for the load.
     * @param {string|string[]} id The id for the item as either a simple string id
     *   or complex array id. Required to cache the viewModel. The viewModel
     *   won't be cached if omitted
     */
    LayoutContainer.prototype.loadCurrentFromJSON = function(type, json, params, id) {
      var scope, builder, viewModel, cacheResult;

      builder = this.getViewModelBuilder(type);
      if(!builder) {
        return null;
      }

      if(builder.scope) {
        scope = builder.scope;
      } else {
        scope = 'page';
      }

      if(builder.load) {
        if(builder.load === true) {
          this.contextHandler.set(type, json, scope);

          if(id && builder.cachable) {
            var cacheKey = self.idAndParamsToCacheKey(id, params);
            this.cache.set(type, cacheKey, viewModel);
          }

          return json;
        }

        viewModel = builder.load(json, this, params);
        this.contextHandler.set(type, viewModel, scope);

        if(id && builder.cachable) {
          var cacheKey = self.idAndParamsToCacheKey(id, params);
          this.cache.set(type, cacheKey, viewModel);
        }

        return viewModel;
      }
      return null;
    };

    /**
     * Instantiate a widget from a definition. Effectively copies all properties from
     * the passed widget to a new {@link WidgetViewModel} then initializes the widget. The returned
     * widget is a new WidgetViewModel with all of the same properties & values as the old
     * widget, but with distinct observables.
     *
     * @private
     * @function
     * @name LayoutContainer#instantiateWidget
     * @param {WidgetViewModel} widget The widget definition to instantiate.
     * @returns {WidgetViewModel} The instantiated widget.
     * @see LayoutContainer#initializeWidget
     */
    LayoutContainer.prototype.instantiateWidget = function (widget) {
      var newWidget = new this.WidgetViewModel(this.basePath),
          key,
          ii;

      //Copy all properties from the widget definition to the widget instance
      for(key in widget) {
        if(widget.hasOwnProperty(key)) {
          //If copying an observable, create a new observable.
          if(ko.isObservable(widget[key]) && !ko.isComputed(widget[key])) {
            //If remove exists the observable is an array
            if(widget[key].remove) {
              newWidget[key] = ko.observableArray(widget[key]());
            } else {
              newWidget[key] = ko.observable(widget[key]());
            }
          } else if(!ko.isComputed(widget[key])){
            //If not an observable just copy the value
            newWidget[key] = widget[key];
          }
        }
      }

      //Initialize the new widget
      this.initializeWidget(newWidget, true);

      return newWidget;
    };


    /**
     * Run Widget Initialization after context data has been set from server.
     *
     * @private
     * @function
     * @name LayoutContainer#initializeWidget
     * @param {WidgetViewModel} widget Widget to initialize
     * @param {boolean} load=false Whether to run 'onLoad' function and resolve special properties.
     * @param {function} javascriptLoadedCallback Function/script to run after initializing widget.
     */
    LayoutContainer.prototype.initializeWidget = function (widget, load, javascriptLoadedCallback) {
      var self = this;

      self.contextDataSetSubscriber.done(function(){
        self.runWidgetInitialization(widget, load, javascriptLoadedCallback);
      });
    };

    /**
     * Initializes the widget by loading its custom javascript, resolving any special properties,
     * and running the onLoad function. Marks the widget as initialized once the processing has completed.
     *
     * @private
     * @function
     * @name LayoutContainer#runWidgetInitialization
     * @param {WidgetViewModel} widget The widget to initialize
     * @param {boolean} load Whether or not to run the onLoad function and resolve its
     *   special properties. True will cause the onLoad function to run, default is false.
     * @param {function} javascriptLoadedCallback Function/script to run after initializing widget.
     */
    LayoutContainer.prototype.runWidgetInitialization = function (widget, load, javascriptLoadedCallback) {
      var importKey, imports, scope, ii, layoutContainer = this;

      layoutContainer.pendingWidgets.push(widget);

      if(load) {
        //Load any things pointed to as part of 'current'
        if(widget.imports) {
          imports = ko.utils.unwrapObservable(widget.imports);
          for(ii = imports.length - 1; ii >= 0; ii -= 1) {
            importKey = imports[ii];
            scope = this.getViewModelBuilder(importKey);
            if(scope) {
              scope = scope.scope || 'page';
            }
            widget[importKey] = this.contextHandler.get(importKey, scope);
          }
        }
      }

      //If the widget has javascript load it.
      if(ko.utils.unwrapObservable(widget.javascript)) {
        // Use require to load the javascript.
        // Check asset mappings to make sure we are loading
        // the correct version
        var mappingBase = widget.jsPath() + '/';
        var jsPath = widget.javascript();

        if (widget.assetMappings) {
          var mappingKey = '/js/' + widget.javascript() + '.js';
          var mappingValue = widget.assetMappings[mappingKey];

          if (mappingValue) {
            var idx = mappingValue().lastIndexOf('/');
            mappingBase = mappingValue().substring(0,idx);
            jsPath = mappingValue();
          }
        }
        // While loading the module thru require([],fn{}), require creates a module
        //map with the url to load the module from, dependencies, and call back after
        //loading the module.  During the migration from require 2.0.6 to require.js 2.1.10,
        //it is observed that there is a delay of the 4 milliseconds added by require to load
        //the dependencies as part of context.nextTick.and prepare the module map after
        //that delay. This was creating the problem while loading the widgets as the delay
        //overrides the base url for all the widgets. So we have to override the nextTick
        //implementation to execute load immediately.
        requirejs.s.contexts._.nextTick = function(fn){fn();}

        var mapping = requirejs.s.contexts._.config.map ? requirejs.s.contexts._.config.map : {};

        mapping[jsPath] = {};

        // For widgets that contain multiple js files, we need to provide a mapping so that require knows to load it from the url specified in asset mappings

        // load js extension if available
        var extensionHasBeforeAppear = false;
        var extJsDeferred = $.Deferred();
        if (widget.javascriptExtension() !== null) {
          var extjsMapping = widget.assetMappings["/js/ext/" + widget.javascriptExtension()];
          var extjsPath = ko.unwrap(extjsMapping);
          if (extjsPath) {
            require([extjsPath], function( extjs ) {

              if (extjs && extjs.beforeAppear && typeof extjs.beforeAppear === 'function'){
                extensionHasBeforeAppear = true;
              }

              if (extjs && extjs.onLoad && typeof extjs.onLoad === 'function') {
                extjs.onLoad.call(widget,widget);
              }
              widget.__cc__extjs = extjs;
              // notify deferred that we are ready. this is used to make sure
              // extensionjs beforeAppear is called after the parent widgets beforeAppear
              extJsDeferred.resolve(extjs);
            });
          }
        }

        for (var asset in widget.assetMappings) {
          // Just map js files that aren't the main widget js file
          if (asset.indexOf('/js/') === 0 && asset.indexOf(widget.javascript() + '.js') === -1) {

            // if we're using minified js files, we should favour minified assets when mapping modules to widget dependencies.
            var minified = (widget.javascript().endsWith('.min'));
            var favouredExtension = minified ? '.min.js' : '.js';

            var extPos = asset.indexOf(favouredExtension);

            if (extPos == -1) extPos = asset.indexOf('.js');

            var url = widget.assetMappings[asset]();
            var moduleName = asset.substring(1, extPos);

            // if we're using minified files and we already have the moduleName mapped to one, don't overwrite it again.
            if (!mapping[jsPath][moduleName] || (minified && !mapping[jsPath][moduleName].endsWith('.min.js'))) {
              mapping[jsPath][moduleName] = url;
            }
          }
        }

        require({baseUrl: mappingBase, map : mapping}, [jsPath], function(extensions) {
          if(typeof extensions === 'function') {
            extensions = extensions().bind(ko);
          }
          var key;
          //For each property in the returned object copy it to the widget.
          for(key in extensions) {
            if(extensions.hasOwnProperty(key)) {
              //If copying an observable, create a new observable.
              if(ko.isObservable(extensions[key]) && !ko.isComputed(extensions[key])) {
                if(extensions[key].remove) {
                  widget[key] = ko.observableArray(extensions[key]());
                } else {
                  widget[key] = ko.observable(extensions[key]());
                }
              } else {
                widget[key] = extensions[key];
              }
            }
          }
          // Let the widget know that all its properties have been set
          widget.allPropertiesSet(widget);
          if (!extensions) {
            ccLogger.warn("Failed to execute javascript for widget: " + widget.javascript());
          }
          //Run the onLoad function for the extended javascript
          if(load && extensions && extensions.onLoad) {
            var onLoadReturn = extensions.onLoad(widget);
          }
          if(layoutContainer.storeConfiguration.enablePrioritizedLoading){
        	//Once the widget is loaded, call its afterLoad method
            $.when(onLoadReturn).always(function() {
        	  layoutContainer.callWidgetMethodIfApplicable(METHOD_AFTER_LOAD, widget);
            });
          }
          // Some things need i18n resources loaded first
          if(load && extensions && extensions.resourcesLoaded) {
            if(widget.resources()) {
              extensions.resourcesLoaded(widget);
            }

            widget.resources.subscribe(function(resources) {
              if(resources) {
                // resource bundle has been loaded.
                extensions.resourcesLoaded(widget);
              }
            });
          }

          // Mark as initialized
          widget.initialized(true);
          //Once widget is initialized, resolve its promise
          if(layoutContainer.storeConfiguration.enablePrioritizedLoading && layoutContainer.displayQueue[widget.id()] != undefined){
            layoutContainer.displayQueue[widget.id()].resolve(widget);
          }
          if (javascriptLoadedCallback)
            javascriptLoadedCallback.call(this,widget);
          // remove us from pending list
          layoutContainer.pendingWidgets.remove(widget);
          // Used to notify widget that it is about to be displayed on a page
          if (widget.hasBeforeAppear() || extensionHasBeforeAppear) {
             $.Topic(PubSub.topicNames.PAGE_READY).subscribe(
              widget.maybeFireBeforeAppearExtJSDeferred(extJsDeferred).bind(widget)
            );
          }
        });
        //Reset the overridden behaviour so that loading behaviuour of other modules is not affected
        requirejs.s.contexts._.nextTick = requirejs.nextTick;
      } else {
        layoutContainer.pendingWidgets.remove(widget);
        // Mark as initialized
        widget.initialized(true);
        //Once widget is initialized, resolve its promise
        if(layoutContainer.storeConfiguration.enablePrioritizedLoading  && layoutContainer.displayQueue[widget.id()] != undefined)
          layoutContainer.displayQueue[widget.id()].resolve(widget);
      }
    };

      /**
       * Convert an id and params map into an array key for caching.
       *
       * @private
       * @function
       * @name LayoutContainer#idAndParamsToCacheKey
       * @param {string} pId ID
       * @param {Object} pParams Parameter object
       * @returns pId if pParams is null, otherwise returns an Array containing pId and the values of param
       *   keys PAGE_PARAM and DATA_ONLY if those keys exist in pParams.
       */
      LayoutContainer.prototype.idAndParamsToCacheKey = function(pId, pParams) {
        if(pParams == null)
          return pId;
        var keyArray = new Array();
        keyArray.push(pId);
        if(pParams[CCConstants.PAGE_PARAM])
          keyArray.push(pParams[CCConstants.PAGE_PARAM]);
        if(pParams[CCConstants.DATA_ONLY])
          keyArray.push(pParams[CCConstants.DATA_ONLY]);
        return keyArray;
      };

      /**
       * This method is executed when page is ready. This records the page view
       * count and sends to the server and then calls the Visitor Id service if
       * a visitorId doesn't exist.
       *
       * @private
       * @function
       * @name LayoutContainer#pageReady
       */
      LayoutContainer.prototype.pageReady = function() {
        var self = this;
        if (!self.isServerSideProcess()) {
          if (!self.isPreview) {
            var pageViewEvent = pageViewTracker.createPageViewEvent(1);
            pageViewTracker.recordPageChange(pageViewEvent);
          }
        }
      };

      /**
       * This method is executed when pagination changes.
       *
       * @private
       * @function
       * @name LayoutContainer#paginationChange
       */
      LayoutContainer.prototype.paginationChange = function(data) {
        if (data.paginationOnly) {
          this.pageReady();
        }
      };

      /**
       * Check whether a server side process (SEO Scheduler) generated the pages.
       *
       * @function
       * @name LayoutContainer#isServerSideProcess
       * @returns {boolean} true if page was generated by a server side process, otherwise false.
       */
      LayoutContainer.prototype.isServerSideProcess = function() {
        var isServerSide = false;
        var url = window.location.href;
        if (url.indexOf(CCConstants.SERVERSIDE_PROCESS_STRING) != -1) {
          isServerSide = true;
        }
        return isServerSide;
      };

      /**
       * Convert parameter data from the URL into an key/value object.
       *
       * @private
       * @function
       * @name LayoutContainer#getParameterData
       * @param {string} args String representation of URL parameters
       * @returns {Object} Object where each URL parameter is converted to a key:value.
       */
      LayoutContainer.prototype.getParameterData = function(args) {
        var parameters = {};
        if(args) {
            var param = args.split("&");
            for (var i = 0; i < param.length; i++) {
              var tempParam = param[i].split("=");
              parameters[tempParam[0]] = tempParam[1];
            }
        }
        return parameters;
      };

      /**
       * Checkout for network errors and notify the user. E.g. This method is executed when user is
       * not connected to internet.
       *
       * @private
       * @function
       * @name LayoutContainer#networkError
       */
      LayoutContainer.prototype.networkError = function() {
        var self = this;
        self.networkErrorMessage = CCi18n.t(
                'ns.common:resources.networkConnectivityError', {}
              );
        self.networkErrorReloadText = CCi18n.t(
                'ns.common:resources.reloadPage', {}
              );
        $(window).scrollTop('0');
        //To hide modals if any
        $('.modal').modal('hide');
        //To hide spinner if any
        $('#loadingModal').hide();
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        notifier.sendTemplateInfo(CCConstants.LAYOUT_CONTAIER_ID, self, 'notificationsNetworkError');
      };

      /**
       * Form the queues with the widgets and their display promises with the data received from the server.
       *
       * @private
       * @function
       * @name LayoutContainer#formWidgetQueue
       * @param {LayoutViewModel} pPageResponse The current page response that rendered the page.
       */
      LayoutContainer.prototype.formWidgetQueue = function (pPageResponse) {
        var self = this;
        var regions = pPageResponse.regions();
        self.displayQueue={};
        // Call beforeDisappear method, for any widgets in current widgets which are about to go inactive.
        for (var widgetId in self.widgets) {
          self.callWidgetMethodIfApplicable(METHOD_BEFORE_DISAPPEAR, self.widgets[widgetId]);
        }
        self.widgets = {};
        self.queue.resetHead();
        for (var i=0;i<regions.length;i++) {
        //Set promises for all the widgets on the page
          self.iterateRegionsAndFormQueue(regions[i]);
        }
      };

      /**
       * Form display queue with widgets in current layout.
       * @public
       * @function
       * @name LayoutContainer#iterateRegionsAndFormQueue
       * @param {Object} region Region to be iterated
       */
      LayoutContainer.prototype.iterateRegionsAndFormQueue = function(region) {
        var self = this;
        if(region.regions().length>0){
          for(var i=0;i<region.regions().length;i++) {
            self.iterateRegionsAndFormQueue(region.regions()[i]);
          }
        }else{
          for (var i=0;i<region.widgets().length;i++) {
            var widget = region.widgets()[i];
            var widgetId = ko.unwrap(widget.id);
            self.widgets[widgetId] = widget;
            self.displayQueue[widgetId]=$.Deferred();
          }
        }
      };
      /**
       * Display widgets in order once they are initialized.
       *
       * @private
       * @function
       * @name LayoutContainer#displayWidgets
       */
      LayoutContainer.prototype.displayWidgets = function () {
      //Set display for widgets in a sequence maintained in a queue
        for (var widgetId in this.widgets) {
      	this.queue.display(widgetId,this);
        }
      };

      /**
       * Checks for a method in all the widgets of the current page and
       * calls if applicable
       *
       * @public
       * @function
       * @name LayoutContainer#callMethodInAllWidgets
       * @param {String} pMethodName The method name to call when applicable
       */
      LayoutContainer.prototype.callMethodInAllWidgets = function(pMethodName) {
        for (var widgetId in this.widgets) {
          this.callWidgetMethodIfApplicable(pMethodName, this.widgets[widgetId]);
        }
      };

      /**
       * Checks if a method exists on a particular widget
       * and calls it with the passed in arguments if it does.
       * @public
       * @name callWidgetMethodIfApplicable
       * @param {String} pMethodName The method name to call when applicable
       * @param {WidgetViewModel} pWidget The widget to process
       */
      LayoutContainer.prototype.callWidgetMethodIfApplicable = function(pMethodName, pWidget) {
        if (pMethodName in pWidget && typeof pWidget[pMethodName] === 'function') {
          try {
            pWidget[pMethodName].call(pWidget, this.currentPage, this.pageResponse);
          }
          catch (e) {
            ccLogger.error("Error in " + pMethodName + " of widget : " + pWidget.displayName(), e);
          }
        }
      };
      LayoutContainer.prototype.pageLoading = function() {
        var indicatorOptions = {
          parent : '#loadingModal',
          posTop : '0',
          posLeft : '50%'
        };
        var loadingText = CCi18n.t('ns.common:resources.loadingText');
        $('#loadingModal').removeClass('hide');
        $('#loadingModal').show();
        indicatorOptions.loadingText = loadingText;
        spinner.create(indicatorOptions);
      };

      LayoutContainer.prototype.pageLoadingComplete = function() {
        $('#loadingModal').addClass('hide');
        spinner.destroy();
      };

     /** Catches all the run time errors
     * supported on browsers : Chrome 13+
     * Firefox 6.0 Internet Explorer 5.5+
     * Opera 11.60+ Safari 5.1+
     *@param {String} errorMessage is the error message
      @param {String} errorUrl where error was raised
      @param {Number} errorLineNumber number where error was raised
     */
    window.onerror = function(errorMessage, errorUrl, errorLineNumber, errorColNumber, errorObj) {
      $.Topic(PubSub.topicNames.ONERROR_EXCEPTION_HANDLER).publish(errorMessage,
        errorUrl, errorLineNumber, errorColNumber, errorObj);
    }

    //--------------------------------------------------------------------
    // Static member variables
    //--------------------------------------------------------------------

    /**
     * The LayoutViewModel constructor to used to create layouts.
     * @private
     * @type {LayoutViewModel}
     * @name LayoutContainer.LayoutViewModel
     */
    LayoutContainer.prototype.LayoutViewModel = LayoutViewModel;

    /**
     * The RegionViewModel constructor used to create regions.
     * @private
     * @type {RegionViewModel}
     * @name LayoutContainer.RegionViewModel
     */
    LayoutContainer.prototype.RegionViewModel = RegionViewModel;

    /**
     * The WidgetViewModel constructor used to create widgets.
     * @private
     * @type {WidgetViewModel}
     * @name LayoutContainer.WidgetViewModel
     */
    LayoutContainer.prototype.WidgetViewModel = WidgetViewModel;

    /**
     * The CartViewModel constructor used to create the cart.
     * @private
     * @type {CartViewModel}
     * @name LayoutContainer.CartViewModel
     */
    LayoutContainer.prototype.CartViewModel = CartViewModel;

    /**
     * The OrderViewModel constructor used to create orders.
     * @private
     * @type {OrderViewModel}
     * @name LayoutContainer.OrderViewModel
     */
    LayoutContainer.prototype.OrderViewModel = OrderViewModel;

    /**
     * The SearchViewModel constructor used to create searches.
     * @private
     * @type {SearchViewModel}
     * @name LayoutContainer.SearchViewModel
     */
    LayoutContainer.prototype.SearchViewModel = SearchViewModel;

    /**
     * The ShippingMethodsViewModel constructor used to create a list of
     * shipping methods.
     * @private
     * @type {ShippingMethodsViewModel}
     * @name LayoutContainer.ShippingMethodsViewModel
     */
    LayoutContainer.prototype.ShippingMethodsViewModel = ShippingMethodsViewModel;

    /**
     * The PaymentDetails constructor used to create PaymentDetails
     * view model.
     * @private
     * @type {PaymentDetails}
     * @name LayoutContainer.PaymentDetails
     */
    LayoutContainer.prototype.PaymentDetails = PaymentDetails;

    /**
     * The PaymentAuthResponseViewModel constructor used to create
     * the payment authorization view model.
     * @private
     * @type {PaymentAuthResponseViewModel}
     * @name LayoutContainer.PaymentAuthResponseViewModel
     */
    LayoutContainer.prototype.PaymentAuthResponseViewModel = PaymentAuthResponseViewModel;

    /**
     * The UserViewModel constructor used to create
     * the user registration and login view model.
     * @private
     * @type {UserViewModel}
     * @name LayoutContainer.UserViewModel
     */
    LayoutContainer.prototype.UserViewModel = UserViewModel;

    /**
     * The ProductViewModel constructor used to represent product.
     * @private
     * @type {ProductViewModel}
     * @name LayoutContainer.ProductViewModel
     */
    LayoutContainer.prototype.ProductViewModel = ProductViewModel;


    /**
     * The OrderDetailsViewModel constructor used create.
     * @private
     * @type {OrderDetailsViewModel}
     * @name LayoutContainer.OrderDetailsViewModel
     */
    LayoutContainer.prototype.OrderDetailsViewModel = OrderDetailsViewModel;

    /**
     * The SpaceViewModel constructor used to represent your current Space.
     * @private
     * @type {SpaceViewModel}
     * @name LayoutContainer.SpaceViewModel
     */
    LayoutContainer.prototype.SpaceViewModel = SpaceViewModel;

    LayoutContainer.prototype.ServerData = ServerData;

    /**
     * The SiteViewModel constructor used to represent price list groups.
     * @private
     * @type {SiteViewModel}
     * @name LayoutContainer.SiteViewModel
     */
    LayoutContainer.prototype.SiteViewModel = SiteViewModel;

    /**
     * Refresh page on layout change and if publish has happened.
     */
    LayoutContainer.prototype.shouldPageBeRefreshed = function(page) {
      if (ccRestClient.isRefreshRequired && page !== 'payment') {
        if (!this.storeConfiguration.largeCart) {
          window.location.reload();
        }else {
          if (!this.deferLoadLargeCartInPromptRefresh) {
            this.deferLoadLargeCartInPromptRefresh = true;
            $.Topic(PubSub.topicNames.SAVE_CART_BEFORE_REFRESH).publish(self);
           }else {
             // event already published and page still not refreshed
            return;
           }
        }
      }
    };

    /**
     * The Storage API reference to access local storage.
     */
    LayoutContainer.prototype.storageApi = storageApi.getInstance();

    return LayoutContainer;
  }
);

