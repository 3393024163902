/**
 * @fileoverview CCStoreConfiguration Class
 *
 * It is used to store configurations that will be used in storefront.
 *
 * Usage:
 *
 * 1) Include in the proper require.js main module with the following
 * line:
 *
 * CCStoreConfiguration: '/shared/js/ccLibs/cc-store-configuration-1.0'
 *
 * 2) include in the module as follows:
 *
 * define(
 *   [... 'ccStoreConfiguration' ...]
 *   function( ... CCStoreConfiguration ...)
 * )
 *
 * 3) Create a singleton instance for the library
 *    storeConfiguration = CCStoreConfiguration.getInstance();
 *
 * 4) invoke as follows:
 *  var filterKey = storeConfiguration.getFilterToUse(contextObject);
 *
 */

define (
  //----------------------
  // PACKAGE NAME
  //----------------------
  'ccStoreConfiguration',

  //-----------------------
  // DEPENDENCIES
  //-----------------------
  ['knockout'],

  //------------------------
  // MODULE DEFINITION
  //------------------------
  function (ko) {

    "use strict";

    /**
     * Creates a StoreConfiguration object.
     * @name CCStoreConfiguration
     * @class CCStoreConfiguration
     */
    function CCStoreConfiguration() {

      if (CCStoreConfiguration.prototype.singleInstance) {
        throw new Error ("Cannot instantiate more than one CCStoreConfiguration. Use getInstance() method");
      }

      var self = this;
      
      /**
       * function to fetch endpoint configuration
       * @param {Object} pContext - Object that has context data, corresponding to which
       *        endpoint configuration should be be returned
       * @return {Object} - Returns endpoint configuration object based on the context provided
       */
      self.getEndpointConfiguration = function(pContextData){
      	var self = this
      	if(pContextData !== undefined && pContextData !== null){
      		var key = pContextData["endpoint"] ;
      		var endpointConfiguration = self.endpointConfiguarationMap[key]
      		if(endpointConfiguration !== null){ 
      		   var identifier = pContextData["identifier"] ;
          	   var context = endpointConfiguration[identifier];
          	   return context;
      		}    		
      	}
      };
      
    }

    /**
     * Method to set custom maximum lengths for the
     * properties (alias, firstName, lastName, address1,
     * address2, address3, city) of Address view model.
     *
     * @name CCStoreConfiguration#setAddressMaxLengths
     * @function
     * @param {number} alias The maximum length to be set for alias property of address.
     * @param {number} firstName The maximum length to be set for firstName property of address.
     * @param {number} lastName The maximum length to be set for lastName property of address.
     * @param {number} address1 The maximum length to be set for address1 property of address.
     * @param {number} address2 The maximum length to be set for address2 property of address.
     * @param {number} address3 The maximum length to be set for address3 property of address.
     * @param {number} city The maximum length to be set for city property of address.
     */
    CCStoreConfiguration.setAddressMaxLengths = function(alias, firstName, lastName, address1, address2, address3, city) {
      if(alias && alias > 0 && alias < 254)
        CCStoreConfiguration.ADDRESS_ALIAS_MAXIMUM_LENGTH = alias;
      if(firstName && firstName > 0 && firstName < 254)
        CCStoreConfiguration.ADDRESS_FIRSTNAME_MAXIMUM_LENGTH = firstName;
      if(lastName && lastName > 0 && lastName < 254)
        CCStoreConfiguration.ADDRESS_LASTNAME_MAXIMUM_LENGTH = lastName;
      if(address1 && address1 > 0 && address1 < 254)
        CCStoreConfiguration.ADDRESS_ADDRESS1_MAXIMUM_LENGTH = address1;
      if(address2 && address2 > 0 && address2 < 254)
        CCStoreConfiguration.ADDRESS_ADDRESS2_MAXIMUM_LENGTH = address2;
      if(address3 && address3 > 0 && address3 < 254)
        CCStoreConfiguration.ADDRESS_ADDRESS3_MAXIMUM_LENGTH = address3;
      if(city && city > 0 && city < 254)
        CCStoreConfiguration.ADDRESS_CITY_MAXIMUM_LENGTH = city;
    }

    /**
     * An object containing the filterKeys to be used corresponding to a endpointCall contextData
     * The key of this object is constructed using contextData, whereas the value corresponds to the
     * filterKey that should be returned for the passed contextData
     */
    CCStoreConfiguration.prototype.filterMap = {
        "getCollection":{
          "megaMenuNavigation": {"ccFilterConfigKey": "categoryNavData"},
          "categoryNavigation": {"ccFilterConfigKey": "categoryNavData"}
        },
        "listProducts":{
          "productListingData": {"ccFilterConfigKey": "PLPData"},
          "collectionWidget": {"ccFilterConfigKey": "collectionData"},
          "getProductData": {"ccFilterConfigKey": "productData"},
          "getProductDataAndRedirect": {"ccFilterConfigKey": "productData"},
          "listProductsForAddons":{"ccFilterConfigKey" : "addonProductsData"}
        }
    };
    
    /**
     * An object containing preFilter and to skip exclude query parameter 
     * based on the endpoint and context object. 
     * 
     * When the preFilter is set to true ensure that minimum required fields
     * are specified in the filterKey or fields. For example listSkus endpoint in 
    * Agent requires minimum these fields repositoryId , id , parentProducts.id 
     */
    CCStoreConfiguration.prototype.endpointConfiguarationMap = {
        "listSkus":{
          "skuListing": {"preFilter":false ,"skipExclude" : false } 
        }     
    };
    

   /**
    * function to update endpoint configuration
    * 
    * When the preFilter is set to true ensure that minimum required fields
    * are specified in the filterKey or fields. For example listSkus endpoint in 
    * Agent requires minimum these fields repositoryId , id , parentProducts.id 
    */
   CCStoreConfiguration.prototype.updateEndpointConfiguration= function (pEndpointConfiguarationMap) {
        var self = this;
        if (null != pEndpointConfiguarationMap && pEndpointConfiguarationMap.constructor === Object) {
          var endpointConfigurations = Object.keys(pEndpointConfiguarationMap);
          for (var i = 0; i< endpointConfigurations.length; i ++) {
            self.endpointConfiguarationMap[endpointConfigurations[i]] = pEndpointConfiguarationMap[endpointConfigurations[i]];
          }
        }
    };    
   
    CCStoreConfiguration.prototype.largeCart = undefined;

    /*
     * A boolean flag to indicate whether to submit an order asynchronously or synchronously.
     */
    CCStoreConfiguration.prototype.placeAsyncOrder = false;

    CCStoreConfiguration.prototype.isCVVRequiredForSavedCards = ko.observable(true);
    CCStoreConfiguration.prototype.allowSavingCards = ko.observable(false);

    /**
     * An array containing the priority of data, that will be used to search the filterKey using context object.
     */
    CCStoreConfiguration.prototype.priorityList = ["endpoint","page","identifier"];

    /**
     * A boolean flag to enable or disable the filter usage for endpoint responses
     */
    CCStoreConfiguration.prototype.filterEnabled = false;
    
    /**
     * A boolean flag to enable or disable the prioritized loading feature in store front
     */
    CCStoreConfiguration.prototype.enablePrioritizedLoading = (window.clientConfigData && window.clientConfigData.prioritizedLoading && window.clientConfigData.prioritizedLoading == "true")?true:false;

    CCStoreConfiguration.prototype.batchSizeForProdAndSkuData = 200;

    
    CCStoreConfiguration.prototype.threshholdSizeForStockStatusData = 200;
    /**
     * Configuration object which consists of configurable properties,which needed to configured while implementing large cart.
     * @public
     * @name largeCartConfig  
     * @property {observable<boolean>} suppressClientCartValidations If marked true ,system will suppress client validations 
     * except while navigating Checkout.Get product data and stock calls  will be invoked only in operations where it is needed for getting required properties 
        to display UI
     * @property {observable<boolean>} suppressClientCartValidationsInCheckout If marked true, system will suppress all the cart level validations
     *  while navigating to checkout page.
     * @property {observable<boolean>} suppressPageChangeValidations If marked true ,system will suppress the validation triggered on each page 
     * change
     * @property {observable<boolean>} enableOptimizedUpdatesofItems If marked true,system ensures quick update of allItems array sending only one knockout notification per array updates.
       However,this requires changes in shopping cart widget or any widget where detailedItemPriceInfo is binded inside SGR.Recommended to use 
       only during large cart cases
     */
    CCStoreConfiguration.prototype.largeCartConfig = {
  
      /*This will suppress client validations except during Checkout
        Get product data will be invoked only in operations where it is needed for getting required properties 
        to display UI and while adding new line items 
        However,it is recommended to be not used with configurable items
        If used with configurable items handleSuppressValidations need to be overridden appropriately*/
      suppressClientCartValidations : false,
      
      
      /*This will suppress all the cart level validations while going to checkout page
       * (when end user clicks in checkout page)
       * Flag is introduced to give greater flexibility to merchant */
       suppressClientCartValidationsInCheckout : false,
      
      
      /*this will suppress the validation triggered on each page change
       * Validation comprises of Get order ,products  call and stock call*/
       
      suppressPageChangeValidations : false,
      
      
        /*Background :Updating allitems observable array with cart items observable array happens iteratively ,consuming lot of time
         as in each update the respective binding gets recomputed.Situation becomes worse in Shopping cart where even the 
         DOM gets recalculated in every page change.
         This flag ensures quick update of allItems array sending only one knockout notification per array updates.
         However,this requires changes in shopping cart widget or any widget where detailedItemPriceInfo is binded 
         inside SGR*/
      enableOptimizedUpdatesofItems:false  
    };
    
    

    /**
     * A boolean flag to enable or disable the functionality of resetting shipping group relationship on cart update
     */
    CCStoreConfiguration.prototype.resetShippingGroupRelationships = true;

    /**
     * A boolean flag to allow site switching on production
     */
    CCStoreConfiguration.prototype.allowSiteSwitchingOnProduction = ko.observable(false);

    /**
    * A boolean flag to give option to skip loading product types on PLP and getting it on PDP
    */
      
    CCStoreConfiguration.prototype.skipLoadingProductTypes = (window.clientConfigData && window.clientConfigData.skipLoadingProductTypes && window.clientConfigData.skipLoadingProductTypes == "true")?true:false;
    
    /**
    * A boolean flag to give option to skip loading product types on PLP and getting it on PDP
    */
       
    CCStoreConfiguration.prototype.allowVirtualShippingGroup = (window.clientConfigData && window.clientConfigData.allowVirtualShippingGroup && window.clientConfigData.allowVirtualShippingGroup == "true")?true:false;
    
     /**   
     * A boolean flag to enable or disable lazy loading of images in store front
     */
    CCStoreConfiguration.prototype.lazilyLoadImages = (window.clientConfigData && window.clientConfigData.loadImagesLazily && window.clientConfigData.loadImagesLazily == "true")?true:false;

     /**
      * A boolean flag to enable or disable the multiFactorAuthentication feature in storefront
      */
      CCStoreConfiguration.prototype.multiFactorAuthenticationEnabled = false;

    /**
     * The delay in seconds before loading out-of-focus images on the page
     */
    CCStoreConfiguration.prototype.delayBeforeLoadingOutOfFocusImages =
      (window.clientConfigData && window.clientConfigData.delayBeforeLoadingOutOfFocusImages)
        ? Number(window.clientConfigData.delayBeforeLoadingOutOfFocusImages) : -1;

    /**
     * This flag to enables cart to be transient and not to be persisted on server
     */
    CCStoreConfiguration.prototype.transientCartEnabled = ko.observable(false);

     /* Method to get the filter key based on the context passed as a parameter.
     * The context is expected to have "endpoint","currentPage" and an "identifier".
     * The unique combination endpoint+currentPage+identifier will help to locate
     * the filterKey that will be returned from this function.
     * An additional parameter overrideFilterUsage that can be used to override
     * the enable flag for this function call
     * @param {Object} pContext - Object that has context data, corresponding to which
     *        filterKey will be returned
     * @return filterKey {String} The key to be used for the passed context object.
     */
    CCStoreConfiguration.prototype.getFilterToUse = function (pContext) {
      var self = this;
      if (self.filterEnabled) {
        if (null != pContext && pContext.constructor === Object) {
          var contextRemaining = Object.keys(pContext).length;
          var parentData = self.filterMap;
          var key="";
          var keyData = null;
          for (var i = 0; i < self.priorityList.length; i++) {
            key = self.priorityList[i];
            if (pContext[key]) {
              contextRemaining--;
              keyData = parentData[pContext[key]];
              // If all the keys of contextData has been used, and we have a key, return the key
              if (null != keyData && keyData.constructor === Object && keyData["ccFilterConfigKey"]
              && contextRemaining == 0) {
                return keyData["ccFilterConfigKey"];
              } else if (null != keyData && keyData.constructor === Object) {
                parentData = keyData;
              } else if (null == keyData && parentData["default"]) {
                // If keyData is null, but we have default at parentLevel, go to default node.
                keyData = parentData["default"];
                // If all the keys of contextData has been used, and we have a key, return the key
                if (keyData.constructor === Object && keyData["ccFilterConfigKey"] && contextRemaining == 0) {
                  return keyData["ccFilterConfigKey"];
                } else if (keyData.constructor === Object) {
                  parentData = keyData;
                } else if ((null == keyData || contextRemaining == 0) && parentData["ccFilterConfigKey"]) {
                  // If the list has been exhausted, and we have a key at parentLevel, return the key.
                  return parentData["ccFilterConfigKey"];
                } else {
                  // If contextData couldn't point to a key, return null
                  return null;
                }
              } else if ((null == keyData || contextRemaining == 0) && parentData["ccFilterConfigKey"]) {
                // If the list has been exhausted, and we have a key at parentLevel, return the key.
                return parentData["ccFilterConfigKey"];
              } else {
                // If contextData couldn't point to a key, return null
                return null;
              }
            }
          }
          // If the priorityList has ended, and no key is returned. Return the filterKey at this level, if exists.
          if (null != keyData && keyData.constructor === Object && keyData["ccFilterConfigKey"]) {
            return keyData["ccFilterConfigKey"];
          }
        }
      }
      return null;
    };

    /**
     * Method to enable the filter usage
     */
    CCStoreConfiguration.prototype.enableFilter = function () {
      this.filterEnabled = true;
    };

    /**
     * Method to disable the filter usage
     */
    CCStoreConfiguration.prototype.disableFilter = function () {
      this.filterEnabled = false;
    };

    /**
     * Method to add or update the filterKey corresponding to a contextKey
     * contextKey = endpoint+currentPage+identifier
     * @param {Object} pFilterMap - Object that will have the contextKey and its corresponding filterKey
     */
    CCStoreConfiguration.prototype.updateFiltersToUse = function (pFilterMap) {
      var self = this;
      if (null != pFilterMap && pFilterMap.constructor === Object) {
        var filterIdentifiers = Object.keys(pFilterMap);
        for (var i = 0; i< filterIdentifiers.length; i ++) {
          self.filterMap[filterIdentifiers[i]] = pFilterMap[filterIdentifiers[i]];
        }
      }
    };

    /**
     * Method to replace all the filterKeys, with the passed contextKey to
     * filterKey mapped object.
     * @param {Object} pFilterMap - Object that has contextKey mapped to its corresponding filterKey
     */
    CCStoreConfiguration.prototype.replaceAllFiltersToUse = function (pFilterMap) {
      var self = this;
      if (null != pFilterMap && pFilterMap.constructor === Object) {
        self.filterMap = pFilterMap;
      }
    };

    /**
     * Method to add new data to the priorityList
     * @param {Array} pList - Data that needs to be appended to the priorityList
     */
    CCStoreConfiguration.prototype.addDataToPriorityList = function (pList) {
      var self = this;
      if (null != pList && pList.constructor === Array) {
        for(var i = 0; i < pList.length; i++) {
          self.priorityList.push(pList[i]);
        }
      }
    };

    /**
     * Method to replace data of the priorityList
     * @param {Array} pList - Data that will replace the priorityList data
     */
    CCStoreConfiguration.prototype.replacePriorityList = function (pList) {
      var self = this;
      if (null != pList && pList.constructor === Array) {
        self.priorityList = pList;
      }
    };

    /**
     * Array to store the layout ids rendered on the browser.
     */
    CCStoreConfiguration.prototype.layoutIdsRendered = [];

    /**
     * Array size for layoutIdsRendered.
     */
    CCStoreConfiguration.prototype.layoutsRenderedArraySize = 15;
    
    /**
     * Time in seconds to not to refresh the page even though there is change
     * in publish time.
     */
    
    CCStoreConfiguration.prototype.refreshAfter = ko.observable(600);

    /**
     * To check whether its a page naviagation or refresh  
     */
    CCStoreConfiguration.prototype.isFreshPageLoad = undefined;
    
    /**
     * Used to enable or disable passing layoutsRendered query paramater in the
     * layout call. Enabling this will make the layout call response return from
     * server instead of JSONStoreCache/Akamai cache. But by passing this
     * parameter, the content size of the response significantly reduces.
     */
    CCStoreConfiguration.prototype.enableLayoutsRenderedForLayout = false;

    /**
     * Returns the layoutIdsRendered array.
     */
    CCStoreConfiguration.prototype.getLayoutIdsRendered = function () {
      return this.layoutIdsRendered;
    }

    /**
     * Pushes all the layouts visited by shopper in the layoutIdsRendered array.
     * Currently we are limiting the array size to 'layoutsRenderedArraySize'
     * so that the layout endpoint request is not too long.
     * @param {Object} pLayout
     *   Object that holds the layout data.
     */
    CCStoreConfiguration.prototype.storeLayoutIdsRendered = function (pLayout) {
      var self = this;
      if (pLayout && pLayout.layout && pLayout.layout != null &&
          self.layoutIdsRendered.indexOf(pLayout.layout) == -1) {
        if (self.layoutIdsRendered.length == self.layoutsRenderedArraySize) {
          self.layoutIdsRendered.shift();
        }
        self.layoutIdsRendered.push(pLayout.layout);
      }
    }
    
    /**
     * Used to enable or disable spinner on page load. If enabled, on every page
     * change a spinner initiated with the spinner class is shown on the page and
     * the complete page is made read-only.
     */
    CCStoreConfiguration.prototype.enableSpinnerOnPageLoad = false;

    /**
     * Used to enable or disable whether to discard endpoint calls responses,
     * called from previous pages and are not relevant for the current page.
     */
    CCStoreConfiguration.prototype.discardPageScopedCallResponses = false;
    
    /**
     * The list of page scoped calls. These are the endpoint calls that would be
     * redundant once the current page changes. Some examples would be layout/page
     * calls and collection/search calls for listing pages.
     */
    CCStoreConfiguration.prototype.pageScopedCalls = ["getlayout", "getPage", "getCollection", "search"];
    
    /**
     * Gets the list of all the page scoped calls. It might be better to use this
     * rather than the variable call since this will guarantee no changes directly. 
     */
    CCStoreConfiguration.prototype.getPageScopedCalls = function() {
      return this.pageScopedCalls;
    };
    
    /**
     * Adds an array of endpoint calls that are supposed to page scoped.
     * The input should be an array of string, each representing a call.
     * If the call is not present in the list already, it will be added to
     * the list.
     */
    CCStoreConfiguration.prototype.addPageScopedCalls = function(calls) {
      for (var i = 0; i < calls.length; i++) {
        var call = calls[i];
        // Check if the call already exists. If not, push it.
        if (this.pageScopedCalls.indexOf(call) == -1) {
          this.pageScopedCalls.push(call);
        }
      }
    };
    
    /**
     * Removes an array of calls that should not be in the scoped call list.
     * The input should be an array of string, each representing a call.
     * if the call is present in the list, it will be removed, otherwise not.
     */
    CCStoreConfiguration.prototype.removePageScopedCalls = function(calls) {
      for (var i = 0; i < calls.length; i++) {
        var call = calls[i];
        var index = this.pageScopedCalls.indexOf(call);
        if (index > -1) {
          this.pageScopedCalls.splice(index, 1);
        }
      }
    };

    /**
     * Used to enable or disable whether to queue simultaneous calls
     * that do not wait for response of the previous calls.
     */
    CCStoreConfiguration.prototype.enableQueueingSimultaneousCalls = false;
    
    /**
     * Using addQueueableCalls, add the endpoint ids in this array, 
     * for which you want the queuing to be enabled. 
     * Example: ["updateCurrentProfileOrder", "priceOrder"]
     */
    CCStoreConfiguration.prototype.queueableCalls = [];
    
    /**
     * Gets the list of all the queueable calls. It might be better to use this
     * rather than the variable call since this will guarantee no changes directly. 
     */
    CCStoreConfiguration.prototype.getQueueableCalls = function() {
      return this.queueableCalls;
    };
    
    /**
     * Adds an array of endpoint calls that are supposed to queueable.
     * The input should be an array of string, each representing a call.
     * If the call is not present in the list already, it will be added to
     * the list.
     */
    CCStoreConfiguration.prototype.addQueueableCalls = function(calls) {
      for (var i = 0; i < calls.length; i++) {
        var call = calls[i];
        // Check if the call already exists. If not, push it.
        if (this.queueableCalls.indexOf(call) == -1) {
          this.queueableCalls.push(call);
        }
      }
    };
    
    /**
     * Removes an array of calls that should not be in the queueable call list.
     * The input should be an array of string, each representing a call.
     * if the call is present in the list, it will be removed, otherwise not.
     */
    CCStoreConfiguration.prototype.removeQueueableCalls = function(calls) {
      for (var i = 0; i < calls.length; i++) {
        var call = calls[i];
        var index = this.queueableCalls.indexOf(call);
        if (index > -1) {
          this.queueableCalls.splice(index, 1);
        }
      }
    };
    /**
     * To check whether largeCart  is switched on
     */
    CCStoreConfiguration.prototype.isLargeCart =function(data){
      var self =this;
      return self.largeCart;
    }

    /**
     * To decide whether to place the order asynchronously.
     */
    CCStoreConfiguration.prototype.isPlaceAsyncOrder = function(orderViewModel){
      return this.placeAsyncOrder;
    }
    
    /**
     * To determine if the item needs to be assigned virtual shipping group or not
     */
    CCStoreConfiguration.prototype.isVirtualShippingGroup = function(){
      var self =this;
      return self.assetable === true || self.shippable === false;
    }
    
    /**
     * Method to return an instance of library.
     * If the instance is not available, create a new instance
     */
    CCStoreConfiguration.getInstance = function () {
      if (!CCStoreConfiguration.singleInstance) {
        CCStoreConfiguration.singleInstance = new CCStoreConfiguration();
      }
      return CCStoreConfiguration.singleInstance;
    };

    return CCStoreConfiguration;
  }
);

