/**
 * @fileoverview css-loader library that takes care of loading layout specific css into the DOM.
 * 
 * library will be given a path e.g. /pretty-shirt/product/xprod10001 and will send a request to the
 * server for the correct CSS for that path. Server will respond with a 302 to the actual CSS file for the layout e.g. /file/v12345/pl100001.css
 * 
 * The response will be inlined into the DOM, to ensure it exists prior to the page being rendered from the pages endpoint response.
 */
define(
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  "pageLayout/css-loader",

  //-------------------------------------------------------------------
  // DEPENDENCIES
  //-------------------------------------------------------------------
  ["module", "jquery", "ccConstants", "ccRestClient", "storageApi", "xDomainProxy"],

  //-------------------------------------------------------------------
  // MODULE DEFINITION
  //-------------------------------------------------------------------
  function(module, $, CCConstants, ccRestClient, storageApi, XDomainProxy) {

    var OCC_RESOURCE = ccRestClient.profileType == "agentUI" ? "/ccagentui/v1/pages/css/" : "/ccstoreui/v1/pages/css/",
        STYLE_ELEMENT = "style", 
        CSS_MIME_TYPE = "text/css";
    
    /**
     * function returns a quick hash of the content passed in.
     * 
     * @param {any} pStr the string to hash 
     * @returns the hash code for the content.
     */
    function hashContents(pStr) {
      var hash = 0;

      if (pStr.length == 0) {
        return hash;
      }

      for (var i = 0; i < pStr.length; i++) {
        var char = pStr.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
      }

      return hash;
    }

    /**
     * CSSLoader.
     * 
     * This is the main module returned from this module.
     */
    function CSSLoader() {

      // module.config().optimizingCSS will have been passed down from the server. 
      // This indicates whether or not we should request layout specific CSS.
      this.loadingOptimizedCSS = module.config().optimizingCSS;
    }

    /**
     * function loads CSS for the layout for the path passed in
     * and inserts the returned CSS into a style element in the DOM.
     * 
     * @param {any} pPath the path to load CSS for e.g. /pretty-shirt/product/xprod10001
     * @param {any} pParams the parame
     */
    CSSLoader.prototype.loadCssForLayout = function(pPath, pParams) {
      
      var path = OCC_RESOURCE + pPath;
      var cssDeferred = $.Deferred();
      var data = $.extend({}, pParams);

      var headers = {};

      // don't add auth header if tokenCookieEnabled, since auth will
      // be sent via cookie instead
      if (ccRestClient.tokenSecret && !ccRestClient.tokenCookieEnabled) {
        headers[CCConstants.AUTHORIZATION_HEADER] = CCConstants.BEARER + " " + ccRestClient.tokenSecret;
      }
      
      headers = ccRestClient.updateHeaderWithAgentContext(headers);
      if( headers["X-CCAgentContext"] instanceof Object){
         headers["X-CCAgentContext"] = JSON.stringify(headers["X-CCAgentContext"]);
      }

      // add viewport header for viewport based page rule evaluation.
      headers = ccRestClient.updateHeaderWithViewport(headers);

      // adding Organization, PricelistGroup, ShopperContext, Locale to header
      headers = ccRestClient.updateHeaderWithSelectedOrganization(headers);
      headers = ccRestClient.updateHeaderWithPriceListGroupId(headers);
      headers = ccRestClient.updateHeaderWithShopperContext(headers);
      headers = ccRestClient.updateHeaderWithVisitData(headers);

      if (ccRestClient.profileType == CCConstants.PROFILE_TYPE_AGENT) {
        headers[XDomainProxy.PROFILE_TYPE_HEADER_NAME] = "fileAgent";
      }

      if (window.siteId) {
        data[CCConstants.URL_SITE_PARAM] = window.siteId;
      }

      // If we're requesting layout specifc CSS, lets go get it.
      if (this.loadingOptimizedCSS) {

        $.ajax({
          url: path,
          data: data,
          headers: headers
        })
        .then(function(css, status, xhr) {

          if (xhr.status === 200) {
             // Get a basic hash of the content and use this as the id for the newly created style element.
             // That way we need not repeat ourselves by creating multiple style elements if we get the 
             // same CSS back for another path e.g. another product page.
             var hashCode = hashContents(css);

             if (!document.getElementById(hashCode)) {
               var style = document.createElement(STYLE_ELEMENT);
               style.type = CSS_MIME_TYPE;
               style.id = hashCode;
               if (xhr.getResponseHeader("Content-Security-Policy") && xhr.getResponseHeader("Content-Security-Policy").indexOf("nonce-") > -1) {
                 var regex = new RegExp("nonce-[^' ]+");
                 style.nonce = xhr.getResponseHeader("Content-Security-Policy").match(regex)[0].split("-")[1];
               }
               style.appendChild(document.createTextNode(css));

               document.head.appendChild(style);
             }
          }
         
          cssDeferred.resolve();
        })
        .fail(function() {
          cssDeferred.resolve();
        });
      }
      // If we're not requesting layout specific CSS, just resolve straight away.
      else {
        cssDeferred.resolve();
      }

      return cssDeferred;
    };

    return new CSSLoader();
  }
);

