/*<ORACLECOPYRIGHT>
 *Copyright (C) 1994, 2022, Oracle and/or its affiliates. All rights reserved.
 * Oracle and Java are registered trademarks of Oracle and/or its affiliates.
 * Other names may be trademarks of their respective owners.
 * UNIX is a registered trademark of The Open Group.
 *
 * This software and related documentation are provided under a license agreement
 * containing restrictions on use and disclosure and are protected by intellectual property laws.
 * Except as expressly permitted in your license agreement or allowed by law, you may not use, copy,
 * reproduce, translate, broadcast, modify, license, transmit, distribute, exhibit, perform, publish,
 * or display any part, in any form, or by any means. Reverse engineering, disassembly,
 * or decompilation of this software, unless required by law for interoperability, is prohibited.
 *
 * The information contained herein is subject to change without notice and is not warranted to be error-free.
 * If you find any errors, please report them to us in writing.
 *
 * U.S. GOVERNMENT RIGHTS Programs, software, databases, and related documentation and technical data delivered to U.S.
 * Government customers are "commercial computer software" or "commercial technical data" pursuant to the applicable
 * Federal Acquisition Regulation and agency-specific supplemental regulations.
 * As such, the use, duplication, disclosure, modification, and adaptation shall be subject to the restrictions and
 * license terms set forth in the applicable Government contract, and, to the extent applicable by the terms of the
 * Government contract, the additional rights set forth in FAR 52.227-19, Commercial Computer Software License
 * (December 2007). Oracle America, Inc., 500 Oracle Parkway, Redwood City, CA 94065.
 *
 * This software or hardware is developed for general use in a variety of information management applications.
 * It is not developed or intended for use in any inherently dangerous applications, including applications that
 * may create a risk of personal injury. If you use this software or hardware in dangerous applications,
 * then you shall be responsible to take all appropriate fail-safe, backup, redundancy,
 * and other measures to ensure its safe use. Oracle Corporation and its affiliates disclaim any liability for any
 * damages caused by use of this software or hardware in dangerous applications.
 *
 * This software or hardware and documentation may provide access to or information on content,
 * products, and services from third parties. Oracle Corporation and its affiliates are not responsible for and
 * expressly disclaim all warranties of any kind with respect to third-party content, products, and services.
 * Oracle Corporation and its affiliates will not be responsible for any loss, costs,
 * or damages incurred due to your access to or use of third-party content, products, or services.
 </ORACLECOPYRIGHT>*/
/**
 * @fileoverview CCStoreUtils Class
 *
 * It has utility methods used in storefront
 *
 * Usage:
 *
 * 1) Include in the proper require.js main module with the following
 * line:
 *
 * ccStoreUtils: '/shared/js/ccLibs/cc-store-utils-1.0'
 *
 * 2) include in the module as follows:
 *
 * define(
 *   [... 'ccStoreUtils' ...]
 *   function( ... StoreUtils ...)
 * )
 *
 * 4) invoke as follows:
 *  StoreUtils.fromJS(serverData, parentObject, copyOnly, observeArray, ignoreArray, includeArray);
 *
 */
define (
  //-------------------------------------------------------------------
  // PACKAGE NAME
  //-------------------------------------------------------------------
  'ccStoreUtils',

  //--------------------------------------------------------------------
  // DEPENDENCIES
  //--------------------------------------------------------------------
  ['knockout', 'ccConstants'],

  //--------------------------------------------------------------------
  // MODULE DEFINITION
  //--------------------------------------------------------------------
  function (ko, CCConstants) {
    "use strict";

    /**
     * Creates a storeUtils object.
     * @private
     * @name CCStoreUtils
     * @class CCStoreUtils
     */
    function CCStoreUtils() {
    }

    /**
     * This method maps JSON data to a viewModel
     * @param data -  The data which will be populated in parentObject
     * @param parentObject - Observable properties created will be associated with this object
     * @param copyOnly - A boolean value. If set to true, it will not create any observable.
     * @param observeArray - An array of properties that will be created as observable and associated
     *                         with the parentObject
     * @param ignoreArray - An array of properties that will not be mapped to parentObject
     * @param includeArray - An array of properties that should be a part of JSON Object (while creating
     *                       JSON Object from viewModel, using ko.mapping.toJS) even though they were not
     *                       part of data, that constructed the parentObject.
     */
    CCStoreUtils.prototype.fromJS = function (data, parentObject, copyOnly, observeArray, ignoreArray, includeArray) {
      var copyArray = [];

      if (copyOnly) {
        for (var key in data) {
          copyArray.push(key);
        }
      }

      if (!copyOnly && observeArray && observeArray.length > 0) {
        for (var key in data) {
          if (data[key] instanceof Object && !(data[key] instanceof Array)) {
            copyArray.push(key);
          }
        }
      }

      var mapping = {
        copy: copyArray,
        observe: observeArray,
        ignore: ignoreArray,
        include : includeArray
      }

      ko.mapping.fromJS(data, mapping, parentObject);
    };
    
    /**
     * This method takes configured size and requested entities length, and returns configured size, if configured size is less than entities length, else returns -1.
     * @param configuredSize -  The size which is configured in store-configuration library.
     * @param requestEntitiesLength - The length of entities being requested.
     */
    CCStoreUtils.prototype.getEntityCountLimitInGetRequest = function(configuredSize, requestEntitiesLength) {
      return configuredSize < requestEntitiesLength ? configuredSize : -1;
    };
    
    /**
     * Method to generate the hash code from the given string 
     * @param stringInput -  the string for which hash needs to generated
     */
    CCStoreUtils.prototype.getHashCode = function(stringInput) {
      //Algorithm may need regular updates to reduce collision probability 
      //Current picture :-Given k randomly generated values, where each value is a non-negative integer less than N
      //Probabilty = 1-e^(-k(k-1)/2N)~0.OOO9 (for 2000 items) 
      //However in our case negative values will also be generated 
      
      var hash = 0, i, chr;
      if (typeof(stringInput)!="string" || stringInput.length === 0) {
        return hash;
      }
      for (i = 0; i < stringInput.length; i++) {
        chr   = stringInput.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash
    };

    /**
     * Method to fetch visitor id from OracleUnifiedVisit object
     */
    CCStoreUtils.prototype.getVisitorId = function()  {
      var self = this;
      var visitService = window.OracleUnifiedVisit;

      var visitorId = null;

      // first check the visit service for visitor id
      if (visitService &&  visitService.visitorId) {
        visitorId = visitService.visitorId();
      }

      // if no valid data from visitor service, see if the page has been
      // initialized with visitor  from the storefront server.
      if (!visitorId) {
        if (window.visitorId) {
          visitorId = window.visitorId;
        }
      }

      // if no valid data from visitor service or the storefront server, see
      // if there's a visitor id leftover in local storage
      if (!visitorId) {
        visitorId = window.localStorage.getItem(CCConstants.VISITOR_ID);
      }

      return visitorId;
    }

    /**
     * Method to fetch visit id from OracleUnifiedVisit object
     */
    CCStoreUtils.prototype.getVisitId = function()  {
      var self = this;
      var visitService = window.OracleUnifiedVisit;

      var visitId = null;

      // first check the visit service for visitor/visit ids
      if (visitService && visitService.visitId) {
        visitId = visitService.visitId();
      }

      // if no valid data from visitor service, see if the page has been
      // initialized with visitor and visit info from the storefront server.
      if (!visitId) {
        if (window.visitId) {
          visitId = window.visitId;
        }
      }

      return visitId;
     }

    return new CCStoreUtils();
  }
);

define("shared/ccLibs/cc-store-utils-1.0", function(){});

